const base64FileSize = (base64) => {
    const n = base64.length;

    let y = 1;

    if (base64.substring(-2) === '==') {
        y = 2;
    }

    return (n * (3/4)) - y;
};

export default base64FileSize;