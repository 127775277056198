import React from 'react'
import CpopUp from './CpopUp'
import {css} from 'styled-components'

const AdeleteConfirm = ({
    children,
    onConfirm = f=>f,
    okText="Delete",
    title=null,
    rawCss=``
}) => {

    const [open, setOpen] = React.useState(false)

    return(
        <>
            {React.cloneElement(children, { 
                onClick: e => {
                    e.preventDefault()
                    setOpen(true)
                }
            })}
            <CpopUp
                visible={open}
                disableFooter
                disableHeader
                onCancel={() => setOpen(false)}
                overLayClass="overlay-template"
                rawCss={rawCss}
            >
                <form className="form" onSubmit={e => e.preventDefault()}>
                    <div className="overlay-icon">
                        <img src="img/delete-form.svg" alt="" />
                    </div>
                    <h3>{title}</h3>
                    <div className="overlay-actions">
                        <button type="button" onClick={() => {
                            onConfirm()
                            setOpen(false)
                        }} className="button with-icon-right"><i className="icon-ia-trash"></i>{okText}</button>
                        <button type="button" onClick={() => setOpen(false)} className="button button-link">Cancel</button>
                    </div>
                </form>
            </CpopUp>
        </>
    )
}

export default AdeleteConfirm