export const GET_SESSIONS_PENDING = 'GET_SESSIONS_PENDING';
export const GET_SESSIONS_REJECTED = 'GET_SESSIONS_REJECTED';
export const GET_SESSIONS_FULFILLED = 'GET_SESSIONS_FULFILLED';
export const GET_CATEGORIES_REJECTED = 'GET_CATEGORIES_REJECTED';
export const GET_CATEGORIES_FULFILLED = 'GET_CATEGORIES_FULFILLED';
export const GET_FORMS_REJECTED = 'GET_FORMS_REJECTED';
export const GET_FORMS_FULFILLED = 'GET_FORMS_FULFILLED';
export const GET_AGENTS_REJECTED = 'GET_AGENTS_REJECTED';
export const GET_AGENTS_FULFILLED = 'GET_AGENTS_FULFILLED';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SELECT_CATEGORIES = 'SELECT_CATEGORIES';
export const SELECT_CLIENTS = 'SELECT_CLIENTS';
export const SELECT_FORMS = 'SELECT_FORMS';
export const SELECT_AGENTS = 'SELECT_AGENTS';
export const SELECT_PAGE = 'SELECT_PAGE';
export const SELECT_DATE_FROM = 'SELECT_DATE_FROM';
export const SELECT_DATE_TO = 'SELECT_DATE_TO';
export const SET_SORT = 'SET_SORT';
export const DELETE_SESSION_REJECTED = 'DELETE_SESSION_REJECTED';
export const DELETE_SESSION_FULFILLED = 'DELETE_SESSION_FULFILLED';
export const SET_ACTIVATION = 'SET_ACTIVATION'
export const SET_FINISHED = 'SET_FINISHED'
export const SET_PENDING = 'SET_PENDING'
export const SET_APPROVATION = 'SET_APPROVATION'

export const initialState = {
    loading: false,
    error: null,
    data: [],
    pages: 0,
    categories: [],
    forms: [],
    agents: [],
    clients: [],
    selected_categories: [],
    selected_clients: '',
    selected_forms: [],
    selected_agents: [],
    page: 1,
    date_from: null,
    date_to: null,
    sort: null,
    order: null,
    pending: null,
    activation: [],
    finished: [],
    approvation: []
};

export default (state = initialState, {type, payload}) => {
    switch (type) {

        case GET_SESSIONS_PENDING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_SESSIONS_REJECTED:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case GET_SESSIONS_FULFILLED:
            return {
                ...state,
                loading: false,
                data: payload.data,
                pages: payload.pages
            };

        case GET_CATEGORIES_REJECTED:
            return {
                ...state
            };
        case GET_CATEGORIES_FULFILLED:
            return {
                ...state,
                categories: payload
            };
        case GET_AGENTS_REJECTED:
            return {
                ...state
            };
        case GET_AGENTS_FULFILLED:
            return {
                ...state,
                agents: payload
            };
        case CLEAR_FILTERS:
            return {
                ...state,
                selected_forms: [],
                selected_agents: [],
                selected_categories: [],
                selected_clients: '',
                date_from: null,
                date_to: null,
                activation: [],
                finished: [],
                pending: null,
                approvation: []
            };
        case GET_FORMS_REJECTED:
            return {
                ...state
            };
        case GET_FORMS_FULFILLED:
            return {
                ...state,
                forms: payload
            };

        case SELECT_CATEGORIES:
            return {
                ...state,
                selected_categories: payload
            };

        case SELECT_CLIENTS:
            return {
                ...state,
                selected_clients: payload
            };
        case SELECT_FORMS:
            return {
                ...state,
                selected_forms: payload
            };
        case SELECT_AGENTS:
            return {
                ...state,
                selected_agents: payload
            };
        case SELECT_PAGE:
            return {
                ...state,
                page: payload
            };
        case SELECT_DATE_FROM:
            return {
                ...state,
                date_from: payload
            };
        case SELECT_DATE_TO:
            return {
                ...state,
                date_to: payload
            };

        case SET_SORT:
            return {
                ...state,
                sort: payload.sort,
                order: payload.order
            };
        case DELETE_SESSION_REJECTED:
            return {
                ...state
            };
        case DELETE_SESSION_FULFILLED:
            return {
                ...state,
                data: state.data.filter(session => session._id !== payload)
            };
        case SET_ACTIVATION:
            return {
                ...state,
                activation: payload
            };
        case SET_FINISHED:
            return {
                ...state,
                finished: payload
            };
        case SET_PENDING:
            return {
                ...state,
                pending: payload
            };
        case SET_APPROVATION:
            return {
                ...state,
                approvation: payload
            }
        default:
            return state;
    }
};