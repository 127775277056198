import axios from "axios"
import { getStore } from "../context"

import { notification } from "antd"

const API = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
})

const P_API = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
})

export { P_API }

API.interceptors.request.use(
	async config => {
		// const { auth } = store.getState();
		const {
			auth: [auth],
		} = getStore()

		// if we do not have token
		if (!auth) {
			return config
		}

		// we do have token

		const { expirationTime } = auth
		// if token has not expired
		if (expirationTime - Date.now()) {
			const { access_token: token } = auth
			config.headers.Authorization = `Bearer ${token}`
			config.headers['client-timezone'] = new window.Intl.DateTimeFormat().resolvedOptions().timeZone
			return config
		} else {
			return config
		}
	},
	function (error) {
		return Promise.reject(error)
	}
)

API.interceptors.response.use(
	response => {
		return response // Do something with response data
	},
	error => {
		if (
			error.request.responseType === "blob" &&
			error.response.data instanceof Blob &&
			error.response.data.type &&
			error.response.data.type.toLowerCase().indexOf("json") !== -1
		) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader()
				reader.onload = () => {
					error.response.data = JSON.parse(reader.result)
					resolve(Promise.reject(error))
				}

				reader.onerror = () => {
					reject(error)
				}

				reader.readAsText(error.response.data)
			})
		}

		if (!error.response) {
			error.response = { status: 503 }
			notification.warning({
				message:
					"The server may not be available at the moment, please try again later!",
			})
		} else if (error.response && error.response.status === 503) {
			notification.warning({
				message: "The server is Under Maintenance! We'll be back soon!",
			})
		}

		return Promise.reject(error)
	}
)

export default API
