import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText"
import LsignaturePad from '../../components/LsignaturePad'

export default ({
    element,
    value = {
        first_name: '',
        last_name: '',
        signature: null
    },
    changeValue,
    answers_by_code
}) => {

    const setValue = (value) => {
        changeValue(value)
    }

    const onChange = signature => setValue({ ...value, signature})
    
    const setText = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
    }

    return (
        <div className="fieldset-row">
            {element.title && <h3>{synchronizeText(element.title, answers_by_code)}</h3>}
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <div className="form-grid-row">
                <div className="form-row">
                    <label>Name</label>
                    <input value={value.first_name} name="first_name" onChange={setText} />
                </div>
                <div className="form-row">
                    <label>Surname</label>
                    <input value={value.last_name} name="last_name" onChange={setText} />
                </div>
            </div>
            <LsignaturePad value={value.signature} onChange={onChange} />
        </div>
    )
}