import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom';
import API from '../../api'
import Logo from "../../images/logo.png";
import {getError} from "../../utils/getError";
import {ApplicationContext} from '../../contexts/application'
import {GlobalFontMedias} from "../../styles/medias"

class ResetPassword extends Component {

    state = {
        token: this.props.match.params.token,
        password: "",
        repeatPassword: "",
        loading: false,
        success: false,
        error: null
    };

    renderError = () => {
        switch(this.state.error) {
            case 'invalid_token':
                return 'Token was not found';
            case 'token_expired':
                return 'Cererea a expirat';
            case 'user_not_found':
                return 'Acest utilizator nu exista';
            case 'password_min_length':
                return 'Minim 6 caractere';
            case 'passwords_do_not_match':
                return 'Parolele nu coincid';
            default:
                return 'Something went wrong'
        }
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onClickReset = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, success: false, error: null });
        let { password, repeatPassword, token } = this.state;

        if (password.length < 6) {
            this.setState({ loading: false, error: 'password_min_length' });
            return;
        }

        if (password !== repeatPassword) {
            this.setState({ loading: false, error: 'passwords_do_not_match' });
            return;
        }

        try {
            await API.post('/auth/reset-password', { token, password });
            this.setState({ loading: false, success: true })
        } catch(err) {
            this.setState({ loading: false, error: getError(err.response) })
        }
    };

    render() {
        if (this.state.success)
            return <Redirect to={'/'}/>;

        return (
            <div className="wrapper login-page">

                <GlobalFontMedias/>

                <div className="table">
                    <div className="table-cell">
                        <form className="form login-form ">
                            <ApplicationContext.Consumer>
                                {({logo}) => (
                                    <div className="box-logo"><img src={logo || Logo} alt=""/></div>
                                )}
                            </ApplicationContext.Consumer>

                            { this.state.error !== null &&
                            <div className="error-msg">{this.renderError()}</div>
                            }
                            <fieldset>
                                <div className="form-row">
                                    <input id="form-name" type="password" name="password" placeholder="Parola noua" onChange={this.onChange} value={this.state.password}/>
                                </div>

                                <div className="form-row">
                                    <input id="form-name" type="password" name="repeatPassword" placeholder="Repeta parola" onChange={this.onChange} value={this.state.repeatPassword}/>
                                </div>

                                <br/>

                                <div className="login-form-action">
                                    <button className="button blue" onClick={this.onClickReset}>SALVEAZA</button>
                                </div>

                                <div style={{ marginTop: 70}}>
                                    <Link to="/login" style={{color: 'white'}}>Intra in cont</Link>
                                </div>
                            </fieldset>

                        </form>
                    </div>
                </div>

            </div>
        );
    }
}

export default ResetPassword