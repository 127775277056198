import React from "react"
import {useLocation} from 'react-router-dom'
import { Redirect } from "react-router-dom"
import API from "../../api"
import { getError } from "../../utils/getError"
import Loading from "../../components/Loading"
import VerifySMS from "./Login.VerifySMS"
import ForgotPassword from "./Login.ForgotPassword"
import ChangePassword from './Login.ChangePassword'
import {useAppContext} from '../../context'
import styled from 'styled-components'
import {LoginWithGoogle} from './Login.Google'


const Login = styled(({className=""}) => {
	const queryParams = new URLSearchParams(useLocation().search)
	const passwordResetToken = queryParams.get('passwordResetToken');
	const [state, setState] = React.useState(
		{
			loading: false,
			show_code: false,
			code_sent: false,
			success: false,
			error: null,
			username: "",
			password: "",
			code: "",
			tab: passwordResetToken ? 'CHANGE_PASSWORD' : 'LOGIN',
			errors: {},
		},
		"login"
	)

	const {auth:[auth]} = useAppContext()

	const setTab = tab => setState(state => ({ ...state, tab }))
	const setUsername = username => setState(state => ({ ...state, username }))
	const setPassword = password => setState(state => ({ ...state, password }))

	const loginRequest = async () => {
		const errors = {}
		if (state.username === "") errors.username = "Invalid username"
		if (state.password === "") errors.password = "Invalid password"
		setState(state => ({ ...state, errors }))
		if (Object.keys(errors).length > 0) return

		try {
			setState(state => ({
				...state,
				loading: true,
				show_code: false,
				errors: {},
			}))

			const { username, password } = state

			await API.post(
				`/auth/login`,
				{ username, password },
				{
					headers: {
						Authorization: `Basic ${window.btoa(
							`${process.env.REACT_APP_API_KEY}:${process.env.REACT_APP_API_SECRET}`
						)}`,
					},
				}
			)

			setState(state => ({
				...state,
				loading: false,
				show_code: true,
				tab: "SMS",
			}))
		} catch (err) {
			const errors = {}

			errors.other =
				{
					incorrect_credentials: "Invalid credentials",
					invalid_request: "Utilizatorul si parola sunt obligatorii",
					access_denied: "Access Denied",
					not_agent: "Doar agentii pot accesa platforma",
					no_phone_number_provided:
						"You have not provided a phone number in your account",
					invalid_phone_number: "The phone number is not valid",
				}[getError(err.response)] || "Something went wrong"

			setState(state => ({
				...state,
				loading: false,
				errors,
			}))
		}
	}



	if (!!auth) return <Redirect to="/" />

	return (
		<>
			<div
				className={`${className} wrapper login-page ${state.tab === "SMS" ? "view-sms-pass" : ""} ${state.tab === "FORGOT_PASSWORD" ? "view-forgot-pass" : ""} ${state.tab === "FORGOT_PASSWORD_MESSAGE" ? "view-forgot-pass view-forgot-pass-msg" : ""} ${state.tab === 'CHANGE_PASSWORD' ? 'view-change-pass' : ''} ${state.tab === 'CHANGE_PASSWORD_MESSAGE' ? 'view-change-pass view-change-pass-msg' : ''}`}
			>
				<div className="table-cell">
					<div className="logo-box">
						<img src='AlchemistClient/img/Alchemist_Identity_Final-08.png' alt="" />
					</div>

					<div className="login-box">
						{state.loading && <Loading />}
						{!state.loading && (
							<div className="login-tabs-content">
								<div className="login-tab-content">
									<div className="scrollbar">
										<div className="table">
											<div className="table-cell">
												<form
													className={`form login-form ${
														Object.keys(state.errors).length > 0 ? "error-anim" : ""
													}`}
													onSubmit={e => {
														e.preventDefault()
														loginRequest()
													}}
												>
													<div className="form-row">
														<LoginWithGoogle />
													</div>
													<span className="or">- or -</span>
													<h3>Login</h3>
													<div
														className={`form-row ${state.errors.username ? "error" : ""}`}
													>
														<input
															id="form-name"
															type="text"
															autoCapitalize="off"
															placeholder="Username/Email"
															onChange={e => setUsername(e.target.value)}
															value={state.username}
														/>
													</div>
													<div
														className={`form-row ${state.errors.password ? "error" : ""}`}
													>
														<input
															id="form-name"
															type="password"
															autoCapitalize="off"
															placeholder="Password"
															onChange={e => setPassword(e.target.value)}
															value={state.password}
														/>
													</div>
													<p className="forgot-pass-link">
														<i className="icon-lock"></i>
														<a
															href="#"
															onClick={e => {
																e.preventDefault()
																setTab("FORGOT_PASSWORD")
															}}
														>
															Forgot password?
														</a>
													</p>
													{(state.errors.username || state.errors.password) && (
														<div className="error-msg">Invalid credentials</div>
													)}
													{state.errors.other && (
														<div className="error-msg">{state.errors.other}</div>
													)}
													<div className="form-row last">
														<button className="button with-icon-right">
															<i className="icon-ia-arrow-right"></i>login
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>

					<ForgotPassword setTab={setTab} />

					<ChangePassword setTab={setTab} />

					<VerifySMS setTab={setTab} loginStore={[state, setState]} />
				</div>
			</div>
		</>
	)
})`
	.form-row.last> button {
		width: auto;
	}

	.login-form {
		.error-msg {
			margin-top: 0;
		}
	}
`

export default Login
