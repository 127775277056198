import React from "react"
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'

export const FilePreview = () => {

    const queryParams = new URLSearchParams(useLocation().search)
    const fileLink = queryParams.get('link');

    const fileType = fileLink.split('.').reverse()[0]

    console.log(queryParams, fileType)

    const config = {
        doc: 'live',
        docx: 'live',
        xlsx: 'live',
        pdf: 'google',
        csv: 'google'
    }

	return (
		<>
            {
                config[fileType] === 'google' && <GoogleViewer fileLink={fileLink}/> 
                || 
                config[fileType] === 'live' && <LiveViewer fileLink={fileLink}/>
                || 
                <GoogleViewer fileLink={fileLink}/> 
            }
		</>
	)
}


const LiveViewer = ({fileLink}) => {
    return (
        <StyledLiveViewerWrapper>
            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileLink}`} frameBorder="no" />
        </StyledLiveViewerWrapper>
    )
}
const StyledLiveViewerWrapper = styled.div`
    height: 100vh;
    iframe {
        height: 100%;
        width: 100%;
    }
`


const GoogleViewer = ({fileLink}) => {
    return (
        <StyledGoogleViewerWrapper>
            <iframe src={`https://docs.google.com/a/${process.env.REACT_APP_DOMAIN}/viewer?url=${fileLink}&embedded=true`} frameBorder="no" />
        </StyledGoogleViewerWrapper>
    )
}
const StyledGoogleViewerWrapper = styled.div`
    height: 100vh;
    iframe {
        height: 100%;
        width: 100%;
    }
`
