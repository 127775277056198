import bytes from "bytes"
import Isemail from 'isemail'
import moment from 'moment'

import {elements} from '../constants/elements'
import {VALIDATION} from '../constants/validations'
import base64FileSize from '../utils/base64FileSize'
import {phoneValidator} from "./phoneValidator"
import {validateVin} from "./vinValidator"
import {postalCodeValidator} from "./postalCodeValidator"
import {ibanValidator} from "./ibanValidator"
import {plateValidator} from "./plateValidator"
import API from '../api'

export const getFormErrors = async ({elements: form_elements, values, formId, sessionId}) => {
 
    const validation_errors = {}

    for (let i = 0; i < form_elements.length; i++) {

        const element = form_elements[i]

        const value_obj = {
            element_id: element._id,
            value: values.find(value => value.element_id === element._id)?.value
        }
        
        switch (element.type) {
            case elements.SHORT_ANSWER:
                const textBoxErrors = await textBoxValidation({element, value_obj, formId, sessionId})
                if(Object.keys(textBoxErrors).length > 0) {
                    validation_errors[element._id] = textBoxErrors
                }
                break;
            case elements.DROPDOWN:
                const dropDownErrors = await dropDownValidation({element, value_obj})
                if(Object.keys(dropDownErrors).length > 0) {
                    validation_errors[element._id] = dropDownErrors
                }
                break;
            case elements.LONG_ANSWER:
                const textAreaErrors = await textAreaValidation({element, value_obj})
                if(textAreaErrors) {
                    validation_errors[element._id] = textAreaErrors
                }
                break;
            case elements.FILE_UPLOAD:
                const fileUploadErrors = fileUploadValidation({element, value: value_obj?.value}) 
                if(fileUploadErrors) {
                    validation_errors[element._id] = fileUploadErrors
                }
                break;
            case elements.DATE:
                const dateErrors = await dateValidation({element, value_obj});
                if(dateErrors) {
                    validation_errors[element._id] = dateErrors
                }
                break;
            case elements.TIME:
                const timeErrors = await timeValidation({element, value_obj})
                if(timeErrors) {
                    validation_errors[element._id] = timeErrors
                }
                break;
            case elements.CHECKBOX_GROUP:
                const checkboxGroupErrors = await checkboxGroupValidation({element, value_obj})
                if(checkboxGroupErrors) {
                    validation_errors[element._id] = checkboxGroupErrors
                }
                break;
            case elements.RADIO_GROUP:
                const radioGroupErrors = await radioGroupValidation({element, value_obj})
                if(radioGroupErrors) {
                    validation_errors[element._id] = radioGroupErrors
                }
                break;
            case elements.IMAGE_UPLOAD:
                const cameraErrors = await cameraValidation({element, value_obj})
                if(cameraErrors) {
                    validation_errors[element._id] = cameraErrors
                }
                break;
            case elements.SIGNATURE:
                const signatureErrors = await signatureValidation({element, value_obj})
                if(signatureErrors) {
                    validation_errors[element._id] = signatureErrors
                }
                break;
            case elements.STANDARD_ADDRESS:
                const standarddAdressErrors = await standardAddressValidation({value_obj})
                if(standarddAdressErrors) {
                    validation_errors[element._id] = standarddAdressErrors
                }
                break;
            case elements.PRIVACY_AGREEMENT:
                const privacyGdprAgreementErrors = await privacyGdprAgreementValidation({value_obj})
                if(privacyGdprAgreementErrors) {
                    validation_errors[element._id] = privacyGdprAgreementErrors
                }
                break;
            case elements.CONTRACT_COMMUNICATION_OPTIONS:
                const contractCommunicationOptionsErrors = await contractCommunicationOptionsValidation({element, value_obj})
                if(contractCommunicationOptionsErrors) {
                    validation_errors[element._id] = contractCommunicationOptionsErrors
                }
                break;
            case elements.CUSTOMER_SATISFACTION:
                const customerSatisfactionErrors = await customerSatisfactionValidation({element, value_obj})
                if(customerSatisfactionErrors) {
                    validation_errors[element._id] = customerSatisfactionErrors
                }
                break;
            case elements.ID_CARD_EXTRACTOR:
                const idCardExtractorErrors = await idCArdExtractorValidation({element, value_obj})
                if(idCardExtractorErrors) {
                    validation_errors[element._id] = idCardExtractorErrors
                }
                break;
            case elements.COMMUNICATION_OPTIONS:
                const marketingCommunicationOptionsErrors = await marketingCommunicationOptionsValidation({element, value_obj})
                if(marketingCommunicationOptionsErrors) {
                    validation_errors[element._id] = marketingCommunicationOptionsErrors
                }
                break;
            case elements.ITEMS_SELECT_LIST_WITH_IMAGES:
                const itemsSelectListErrors = await itemsSelectListValidation({element, value_obj})
                if(itemsSelectListErrors) {
                    validation_errors[element._id] = itemsSelectListErrors
                }
                break;
            case elements.VIN:
                const vinErrors = await vinValidation({element, value_obj, formId, sessionId})
                if(vinErrors) {
                    validation_errors[element._id] = vinErrors
                }
                break;
            case elements.REGISTRATION_NUMBER:
                const registrationNumberErrors = await registrationNumberValidation({element, value_obj, formId, sessionId})
                if(registrationNumberErrors) {
                    validation_errors[element._id] = registrationNumberErrors
                }
                break;
            case elements.IBAN:
                const ibanErrors = await ibanValidation({element, value_obj, formId, sessionId})
                if(ibanErrors) {
                    validation_errors[element._id] = ibanErrors
                }
                break;
        }
    }

    return validation_errors;
};

const isInt = (x) => {
    const y = parseInt(x, 10);
    return !isNaN(y) && x == y && x.toString() == y.toString();
};

const fileUploadValidation = ({element, value=[]}) => {

    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value || (Array.isArray(value) && value.length === 0)) {
            return ['required']
        }
    }

    if(value.length === 0) return

    // Max file size
    let max_file_size = bytes('70MB');
    const maxFileSizeValidation = validations.find(validation => validation.validation === VALIDATION.MAX_FILE_SIZE)
    if(maxFileSizeValidation) {
        max_file_size = bytes(maxFileSizeValidation.value)
    }
    for (let i = 0; i < value.length; i++) {
        if (value[i].size > max_file_size) {
            return [`max file size`];
        }
    }

    // Min number of files
    let min_no_files = 0;
    const minNumberOfFilesValidation = validations.find(validation => validation.validation === VALIDATION.MIN_NUMBER_OF_FILES)
    if(minNumberOfFilesValidation) {
        min_no_files = parseInt(minNumberOfFilesValidation.value)
    }
    if (value.length !== 0 && value.length < min_no_files) {
        return [`min number of files`]
    }

    // Max number of files
    let max_no_files = 10;
    const maxNrOfFilesValidation = validations.find(validation => validation.validation === VALIDATION.MAX_NUMBER_OF_FILES)
    if(maxNrOfFilesValidation) {
        max_no_files = parseInt(maxNrOfFilesValidation.value)
    }
    if (value.length > max_no_files) {
        return [`max number of files`]
    }    
}

const textBoxValidation = async ({element, value_obj, formId, sessionId}) => {

    const validations = element.validations || []

    const values = value_obj?.value || [""]

    const errors = await Promise.all(
        values.map(async (value, valueIndex) => {

            // Required
            const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
            if(requiredValidation) {
                if (!value) {
                    return ['required']
                }
            }

            // has no value and is not required
            if(!value) return

            // Phone valiatioin
            const phoneValidatioin = validations.find(validation => validation.validation === VALIDATION.PHONE)
            if(phoneValidatioin) {
                if (value && phoneValidator(value) === false) {
                    return [VALIDATION.PHONE]
                }
            }

            // Email
            const emailValidation = validations.find(validation => validation.validation === VALIDATION.EMAIL)
            if(emailValidation) {
                if (value && !Isemail.validate(value)) {
                    return [VALIDATION.EMAIL]
                }
            }

            // Number
            const numberValidation = validations.find(validation => validation.validation === VALIDATION.NUMBER)
            if(numberValidation) {
                if (value && !(!isNaN(parseFloat(value)) && isFinite(value))) {
                    return [VALIDATION.NUMBER]
                }
            }

            // Positive number
            const positiveValidation = validations.find(validation => validation.validation === VALIDATION.POSITIVE)
            if(positiveValidation) {
                if (value && Math.sign(value) !== 1) {
                    return [VALIDATION.POSITIVE]
                }
            }

            // Integer number
            const integerValidation = validations.find(validation => validation.validation === VALIDATION.INTEGER)
            if(integerValidation) {
                if (value && !isInt(value)) {
                    return [VALIDATION.INTEGER]
                }
            }

            // Min length
            const minLengthValidation = validations.find(validation => validation.validation === VALIDATION.MIN_LENGTH)
            if(minLengthValidation) {
                if (value && value.length < minLengthValidation.value) {
                    return [`${VALIDATION.MIN_LENGTH}|${minLengthValidation.value}`]
                }
            }

            // Max length
            const maxLengthValidation = validations.find(validation => validation.validation === VALIDATION.MAX_LENGTH)
            if(maxLengthValidation) {
                if (value && value.length > maxLengthValidation.value) {
                    return [`${VALIDATION.MAX_LENGTH}|${maxLengthValidation.value}`]
                }
            }

            // Min value
            const minValueValidation = validations.find(validation => validation.validation === VALIDATION.MIN_VALUE)
            if(minValueValidation) {
                if (value && parseFloat(value) < parseFloat(minValueValidation.value)) {
                    return [`${VALIDATION.MIN_VALUE}|${minValueValidation.value}`]
                }
            }

            // Max value
            const maxValueValidation = validations.find(validation => validation.validation === VALIDATION.MAX_VALUE)
            if(maxValueValidation) {
                if (value && parseFloat(value) > parseFloat(maxValueValidation.value)) {
                    return [`${VALIDATION.MAX_VALUE}|${maxValueValidation.value}`]
                }
            }

            // Unique
            const uniqueValidation = validations.find(validation => validation.validation === VALIDATION.UNIQUE)
            if(uniqueValidation) {
                try {
                    await API.get(`/sessions/form/${formId}/unique?type=${element.type}&value=${value}${sessionId ? `&sessionId=${sessionId}` : ""}`)
                } catch(err) {
                    if(err.response.status === 403) {
                        return [VALIDATION.UNIQUE]
                    }
                }
            }

        })
    )

    return errors.reduce(
        (acc, error, errorIndex) =>
            error
                ? {...acc, [errorIndex]: error}
                : acc,
        {}
    )
}

const dropDownValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    const values = value_obj?.value || [""]

    const errors = await Promise.all(
        values.map(async (value, valueIndex) => {

            // Required
            const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
            if(requiredValidation) {
                if (!value) {
                    return ['required']
                }
            }

        })
    )

    return errors.reduce(
        (acc, error, errorIndex) =>
            error
                ? {...acc, [errorIndex]: error}
                : acc,
        {}
    )

}

const textAreaValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value) {
            return ['required']
        }
    }

    if(!value_obj.value) return

    // Min length
    const minLengthValidation = validations.find(validation => validation.validation === VALIDATION.MIN_LENGTH)
    if(minLengthValidation) {
        if (value_obj.value && value_obj.value.length < minLengthValidation.value) {
            return [`${VALIDATION.MIN_LENGTH}|${minLengthValidation.value}`]
        }
    }

    // Max length
    const maxLengthValidation = validations.find(validation => validation.validation === VALIDATION.MAX_LENGTH)
    if(maxLengthValidation) {
        if (value_obj.value && value_obj.value.length > maxLengthValidation.value) {
            return [`${VALIDATION.MAX_LENGTH}|${maxLengthValidation.value}`]
        }
    }
}

const dateValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value) {
            return ['required']
        }
    }

    if(!value_obj.value) return

    // No future date
    const noFutureDateValidation = validations.find(validation => validation.validation === VALIDATION.NO_FUTURE_DATE)
    if(noFutureDateValidation) {
        if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").startOf('day') > moment(noFutureDateValidation.value ? noFutureDateValidation.value : "", "DD/MM/YYYY").endOf('day')) {
            return [VALIDATION.NO_FUTURE_DATE]
        }
    }

    // No past date
    const noPastDateValidation = validations.find(validation => validation.validation === VALIDATION.NO_PAST_DATE)
    if(noPastDateValidation) {
        if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").endOf('day') < moment(noPastDateValidation.value ? noPastDateValidation.value : "", "DD/MM/YYYY").startOf('day')) {
            return [VALIDATION.NO_PAST_DATE]
        }
    }

    // No after session date
    const noAfterSessionDateValidation = validations.find(validation => validation.validation === VALIDATION.NO_AFTER_SESSION_DATE)
    if(noAfterSessionDateValidation) {
        if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").startOf('day') > moment().endOf('day')) {
            return [VALIDATION.NO_AFTER_SESSION_DATE]
        }
    }

    // No before session date
    const noABeforeSessionDateValidation = validations.find(validation => validation.validation === VALIDATION.NO_BEFORE_SESSION_DATE)
    if(noABeforeSessionDateValidation) {
        if (value_obj.value && moment(value_obj.value, "DD/MM/YYYY").endOf('day') < moment().startOf('day')) {
            return [VALIDATION.NO_BEFORE_SESSION_DATE]
        }
    }
}

const timeValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value) {
            return ['required']
        }
    }

}

const checkboxGroupValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value || !Array.isArray(value_obj.value)) {
            return ['required']
        }
    }
}

const radioGroupValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value) {
            return ['required']
        }
    }

}

const cameraValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value) {
            return ['required']
        }
    }

    if(!value_obj.value) return

    // Max file size
    const maxFileSizeValidation = validations.find(validation => validation.validation === VALIDATION.MAX_FILE_SIZE)
    if(maxFileSizeValidation) {
        if (value_obj.value.find(image => base64FileSize(image.split('base64,')[1]) > bytes(maxFileSizeValidation.value))) {
            return [`${VALIDATION.MAX_FILE_SIZE}|${bytes(maxFileSizeValidation.value)}`]
        }
    }

    // Multiple images
    const multipleImagesValidation = validations.find(validation => validation.validation === VALIDATION.MULTIPLE_IMAGES)
    if(multipleImagesValidation) {

        if (value_obj.value.length > parseInt(multipleImagesValidation.value)) {
            return [`max number of files|${multipleImagesValidation.value}`]
        }
    }
}

const signatureValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if (!value_obj.value) {
            return ['required']
        }
    }

}

const standardAddressValidation = async ({value_obj}) => {
    
    if(value_obj.value) {
        const current_value_keys = Object.keys(value_obj.value);

        for (let i = 0; i < current_value_keys.length; i++) {
            if (current_value_keys[i] === 'postal_code') {
                if (!postalCodeValidator(value_obj.value[current_value_keys[i]])) {
                    return ['not_valid_postal_code']
                }
            }
        }
    }
}

const privacyGdprAgreementValidation = async ({value_obj}) => {
    return
}

const contractCommunicationOptionsValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {

        if(!value_obj.value) {
            return ['required']
        }
       
        if (value_obj.value.communication === 'post'
            && !value_obj.value.first_name
            && !value_obj.value.last_name
            && !value_obj.value.country
            && !value_obj.value.locality
            && !value_obj.value.street
            && !value_obj.value.nr
            && !value_obj.value.block_value
            && !value_obj.value.entry
            && !value_obj.value.floor
            && !value_obj.value.apartment
        ) {
            return ['post_data_required'];
        } 

        else if (
            value_obj.value.communication === 'sms' 
            && !value_obj.value.sms_number
        ) {
            return ['sms_number_required'];
        } 
        
        else if (
            value_obj.value.communication === 'email' 
            && !value_obj.value.email
        ) {
            return ['email_required'];
        }
        
    }

    if(!value_obj.value) return 

    const current_value_keys = Object.keys(value_obj.value);
    for (let i = 0; i < current_value_keys.length; i++) {
        switch (current_value_keys[i]) {
            case 'email':
                if (
                    value_obj.value[current_value_keys[i]] !== '' 
                    && !Isemail.validate(value_obj.value[current_value_keys[i]])
                ) {
                    return ['not_valid_email'];
                }
                break;
            case 'sms_number':
                if (
                    value_obj.value[current_value_keys[i]] !== '' 
                    && phoneValidator(value_obj.value[current_value_keys[i]]) === false
                ) {
                    return ['not_valid_sms_number'];
                }
                break;
            case 'postal_code':
                if (
                    value_obj.value[current_value_keys[i]] !== "" 
                    && !postalCodeValidator(value_obj.value[current_value_keys[i]])
                ) {
                    return ['not_valid_postal_code'];
                }
                break;
        }
    }

}

const customerSatisfactionValidation = async ({}) => {
    return 
}

const idCArdExtractorValidation = async ({element, value_obj}) => {
    if(!value_obj.value) return 
    
    const current_value_keys = Object.keys(value_obj.value);

    for (let i = 0; i < current_value_keys.length; i++) {
        switch (current_value_keys[i]) {
            case 'first_name':
                if (value_obj.value[current_value_keys[i]] !== '' && value_obj.value[current_value_keys[i]].length > 50) {
                    return ['max_length_first_name']
                }
                break;
            case 'last_name':
                if (value_obj.value[current_value_keys[i]] !== '' && value_obj.value[current_value_keys[i]].length > 50) {
                    return ['max_length_last_name']
                }
                break;
            case 'email':
                if (value_obj.value[current_value_keys[i]] !== '' && !Isemail.validate(value_obj.value[current_value_keys[i]])) {
                    return ['not_valid_email']
                }
                break;
            case 'phone':
                if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                    return ['not_valid_phone_number']
                }
                break;
            case 'series_ci':
                if (value_obj.value[current_value_keys[i]] !== '' && value_obj.value[current_value_keys[i]].length !== 2) {
                    return ['not_valid_serie_ci']
                }
                break;
            case 'nr_ci':
                if (value_obj.value[current_value_keys[i]] !== '' && !/^[0-9]{6}$/.test(value_obj.value[current_value_keys[i]])) {
                    return ['not_valid_nr_ci']
                }
                break;
            case 'date_of_birth':
                if (value_obj.value[current_value_keys[i]] !== '' && moment(value_obj.value[current_value_keys[i]], 'DD/MM/YYYY') > moment().endOf("day")) {
                    return ['not_after_session_date_of_birth']
                }
                break;
            case 'available_from':
                if (value_obj.value[current_value_keys[i]] !== '' && moment(value_obj.value[current_value_keys[i]], 'DD/MM/YYYY') > moment().endOf("day")) {
                    return ['not_after_session_date_available_from']
                }
                break;
            case 'available_to':
                if (value_obj.value[current_value_keys[i]] !== '' && moment(value_obj.value[current_value_keys[i]], 'DD/MM/YYYY').endOf('day') < moment().startOf('day')) {
                    return ['not_before_session_date_available_to']
                }
                break;
        }
    }
}

const marketingCommunicationOptionsValidation = async ({value_obj}) => {
    if(!value_obj.value) return


    const current_value_keys = Object.keys(value_obj.value);
    for (let i = 0; i < current_value_keys.length; i++) {
        switch (current_value_keys[i]) {
            case 'email':
                if (value_obj.value.with_email && value_obj.value[current_value_keys[i]] === '') {
                    return ['email_required']
                }

                if (value_obj.value[current_value_keys[i]] !== '' && !Isemail.validate(value_obj.value[current_value_keys[i]])) {
                    return ['not_valid_email']
                }
                break;
            case 'phone_number':
                if (value_obj.value.with_phone && value_obj.value[current_value_keys[i]] === '') {
                    return ['phone_number_required']
                }
                if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                    return ['not_valid_phone_number']
                }
                break;
            case 'sms_number':
                if (value_obj.value.with_sms && value_obj.value[current_value_keys[i]] === '') {
                    return ['sms_number']
                }
                if (value_obj.value[current_value_keys[i]] !== '' && phoneValidator(value_obj.value[current_value_keys[i]]) === false) {
                    return ['not_valid_sms_number']
                }
                break;
            case 'postal_code':
                if (!postalCodeValidator(value_obj.value[current_value_keys[i]])) {
                    return ['not_valid_postal_code']
                }
                break;
        }
    }

}

const itemsSelectListValidation = async ({element, value_obj}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if(!value_obj?.value || value_obj?.value?.length === 0) {
            return ['required']
        }
    }

}

const vinValidation = async ({element, value_obj, formId, sessionId}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if(!value_obj?.value) {
            return ['required']
        }
    }

    if(!value_obj.value) return

    // Vin format validation
     if (value_obj?.value && !validateVin(value_obj.value)) {
         return ['not_valid_vin']
     }

    // Unique
    const uniqueValidation = validations.find(validation => validation.validation === VALIDATION.UNIQUE)
    if(uniqueValidation) {
        try {
            await API.get(`/sessions/form/${formId}/unique?type=${element.type}&value=${value_obj.value}${sessionId ? `&sessionId=${sessionId}` : ""}`)
        } catch(err) {
            if(err.response.status === 403) {
                return [VALIDATION.UNIQUE]
            }
        }
    }
}

const registrationNumberValidation = async ({element, value_obj, formId, sessionId}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if(!value_obj?.value) {
            return ['required']
        }
    }

    if(!value_obj.value) return

    // Format validation
    if(value_obj.value && !plateValidator(value_obj.value)) {
        return ['not_valid_registration_number']
    }

    // Unique
    const uniqueValidation = validations.find(validation => validation.validation === VALIDATION.UNIQUE)
    if(uniqueValidation) {
        try {
            await API.get(`/sessions/form/${formId}/unique?type=${element.type}&value=${value_obj.value}${sessionId ? `&sessionId=${sessionId}` : ""}`)
        } catch(err) {
            if(err.response.status === 403) {
                return [VALIDATION.UNIQUE]
            }
        }
    }

}

const ibanValidation = async ({element, value_obj, formId, sessionId}) => {
    const validations = element.validations || []

    // Required
    const requiredValidation = validations.find(validation => validation.validation === VALIDATION.REQUIRED)
    if(requiredValidation) {
        if(!value_obj?.value) {
            return ['required']
        }
    }

    if(!value_obj.value) return

    // Format validation
    if (value_obj.value && !ibanValidator(value_obj.value)) {
        return ['iban']
    }

    // Unique
    const uniqueValidation = validations.find(validation => validation.validation === VALIDATION.UNIQUE)
    if(uniqueValidation) {
        try {
            await API.get(`/sessions/form/${formId}/unique?type=${element.type}&value=${value_obj.value}${sessionId ? `&sessionId=${sessionId}` : ""}`)
        } catch(err) {
            if(err.response.status === 403) {
                return [VALIDATION.UNIQUE]
            }
        }
    }
}