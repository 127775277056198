import React from 'react'
import {useHistory} from 'react-router-dom'
import {notification} from 'antd'
import {saveAs} from 'file-saver'

import Loading from '../../components/Loading'
import look from '../NewSession/newSession.look'
import { P_API } from "../../api"
import {css} from 'styled-components'

const cssFixes = css`
   
    padding: 7rem 0 0;
    

    .form-final-msg>.fieldset-row {
        margin: 0 auto 0;

        .files-list {
            grid-template-columns: repeat(3, min-content);
            justify-content: center;
            .file {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        >div{
            margin: 0 auto 0;
            padding: 2.5rem 0;
            >div{
                margin: 0 auto 0;
                >div:nth-child(2) {
                    margin: 0 auto 0;
                }
            }
        }
    }
`


const FinishPage = ({form, newPublicSessionStore}) => {

    const [newPublicSessionState] = newPublicSessionStore
    const [state, setState] = React.useState({
        downloadingDocument: false
    })

    React.useEffect(() => {
        if(form.public_availability.redirect.status && form.public_availability.redirect.url && form.public_availability.redirect.seconds) {
            setTimeout(() => {
                window.location = form.public_availability.redirect.url;
            }, form.public_availability.redirect.seconds * 1000)
        }
    }, [])

    const history = useHistory()

    console.log("public_availability", form.public_availability)

    const downloadGeneratedDocumentPdf = async ({_id: document_id, filename, provider}, sessionId, secret_token) => {
        try {
            setState(state => ({...state, downloadingDocument: true}))
            const {data: pdf} = await P_API.get(`/sessions/${sessionId}/document/${document_id}/secret/${secret_token}`, {responseType: 'blob'})
            saveAs(pdf, sessionId + "_" + filename)
            setState(state => ({...state, downloadingDocument: false}))
        } catch (err) {
            let message = 'Something went wrong with downloading the file';

            if (err.response && err.response.status === 404) {
                message = "The file was not found";
            } else if (err.response && err.response.data && err.response.data.message) {
                if (err.response.data.message === "reauthorize_storage") {
                    message = "There was an error when connecting to the selected default storage, please try again authorizing it."
                } else if (err.response.data.message === "different_storage") {
                    message = `The document is stored on an inaccessible storage platform or account. Please connect directly to your ${{gdrive: "Google Drive", onedrive: "Microsoft OneDrive", dropbox: "Dropbox"}[provider.name]} account to retrieve the file. File name: ${sessionId}_${filename}`
                } else if (err.response.data.message === "different_account") {
                    message = `The account of the storage provider for this document is different from the one authorized now`
                } else if (err.response.data.message === "different_folder") {
                    message = `The folder of the storage provider for this document is different from the one selected now`
                }
            }
            notification.error({
                message: message
            })
            setState(state => ({...state, downloadingDocument: false}))
        }
    }

    return (
        <>
            {state.downloadingDocument && <Loading />}

            {!state.downloadingDocument &&
                <div className="wrapper fixed-elements form-page last-form-msg" css={`${cssFixes}${look}`}>

                    <div className="wrapper-content">
                        <div className="table">
                            <div className="table-cell">
        
                                <div className="form-final-msg">
                                    
                                    <div>
                                        <h1 className="main-title">
                                            {form.public_availability.thankYouText 
                                                ? form.public_availability.thankYouText 
                                                : "Multumim!"
                                            }
                                        </h1>
                                    </div>
        
                                    {form.public_availability.downloadDocuments &&
                                        <div className="fieldset-row">
                                            <div className="form-grid files-list">
                                                {newPublicSessionState.submissionResponse.documents.map(file => (
                                                    <div key={file._id} style={{cursor: 'pointer'}} className="file">
                                                        <div onClick={() => downloadGeneratedDocumentPdf(file, newPublicSessionState.submissionResponse._id, newPublicSessionState.submissionResponse.secret_token)}>
                                                            <img src='AlchemistClient/img/file.svg' alt=""/>
                                                            <strong>{file.filename.replace(/\.[^/.]+$/, "")}</strong>
                                                        </div>
                                                        <div className="button" onClick={() => downloadGeneratedDocumentPdf(file, newPublicSessionState.submissionResponse._id, newPublicSessionState.submissionResponse.secret_token)}>
                                                            Descarca
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
        
                                </div>
        
                            </div>
                        </div>
                    </div>
        
                </div>
            }
        </>
    )
}

export default FinishPage