import React from 'react'
import bytes from 'bytes'
import {css} from 'styled-components'

import getMaxFileSizeFromValidations from '../../utils/getMaxFileSizeFromValidations'
import {synchronizeText} from "../../utils/synchronizeText"
import Loading from '../../components/Loading'
import CdeleteConfirm from '../../components/CdeleteConfirm'

export default ({
    element,
    value = [],
    errors = [],
    changeValue,
    answers_by_code,
    setErrors
}) => {

    const cssFixes = css`
        .images-wrapper-div {
            margin: 2rem 0 2rem 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;

            >div {
                position: relative;
                display: grid;
                align-content: center;
                align-items: center;
                border: 1px dashed gray;

                >img {
                    max-width: 100%;
                    height: auto;
                }

                >div {
                    position: absolute;
                    z-index: 10;
                    right: 0;
                    top: 0;
                    >i {
                        width: 3.6rem;
                        height: 3.6rem;
                        display: inline-block;
                        font-size: 1.8rem;
                        line-height: 3.4rem;
                        border-radius: 0.3rem;
                        border: 1px solid transparent;
                        cursor: pointer;
                        color: black;
                        background: transparent;
                        transition: all 0.3s ease-in-out;
                        text-align: center;
                        :hover {
                            color: white;
                            background: black;
                        }
                    }
                }
            }
        }

        .image-upload-background {
            cursor: pointer;
        }
    `

    const [state, setState] = React.useState({
        loading: false
    })

    let image_upload

    const setImage = async ({target:{files}}) => {

        setState(state => ({...state, loading: true}))
        setErrors(undefined)

        const images = [...files]

        const max_file_size = getMaxFileSizeFromValidations(element, answers_by_code);
        
        if(
            max_file_size &&
            images.find(image => image.size > max_file_size)
        ) {
            setErrors([`max file size|${bytes(element.validations.find(({validation}) => validation === 'max file size').value)}`])
            setState(state => ({...state, loading: false}))
            return
        }

        try {
            const imageDataUrls = await Promise.all(
                images.map(image => new Promise((resolve) => {
                    const render = new FileReader()
                    render.readAsDataURL(image)
                    render.onload = () => resolve(render.result)
                }))
            )

            changeValue([...(multiple ? value : []), ...imageDataUrls])
            setState(state => ({...state, loading: false}))
        } catch(err) {
            setState(state => ({...state, loading: false}))
        }

    }

    const removeImage = imageIndex => changeValue(value.filter((_, i) => i !== imageIndex))
    
    const browseFiles = () => image_upload.click()

    const multiple = !!element.validations.find(({validation}) => validation === "multiple images")
    
    return (
        <div className="fieldset-row" css={cssFixes}>
            {element.text &&
            <h3>{synchronizeText(element.text, answers_by_code)}</h3>
            }

            {state.loading && <Loading />}

            {!state.loading && (
                <>
                    {value.length > 0 &&
                        <>
                            <div className="images-wrapper-div">
                                {value.map((image, imageIndex) => (
                                    <div key={imageIndex}>
                                        <CdeleteConfirm
                                            title="Are you sure you want to delete the image?"
                                            onConfirm={() => removeImage(imageIndex)}
                                            okText="Delete"
                                        >
                                            <div>
                                                <i className="icon-ia-delete"/>
                                            </div>
                                        </CdeleteConfirm>
                                        <img src={image} alt="" />
                                    </div>
                                ))}
                            </div>
                            <span className="add-block-input mar2b" onClick={browseFiles}>{element.title}</span>
                        </>
                    }

                    <input
                        type="file"
                        style={{display: 'none'}}
                        ref={ref => image_upload = ref }
                        accept="image/*"
                        multiple={multiple}
                        onChange={setImage}
                    />

                    {value.length < 1 &&
                        <div className="image-upload-background" onClick={browseFiles}>
                            <img style={{ maxWidth: '100%', maxHeight: '300px' }}  src='img/camera.svg' alt="" />
                        </div>
                    }

                    <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                        {renderErrors(errors)}
                    </div>
                </>
            )}
        </div>
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map((error, i) => {
        const error_array = error.split('|');

        switch (error_array[0]) {
            case 'required':
                return <span key={error_array[0]} className="form-row-error-msg">This field is required</span>;
            case 'max file size':
                return <span key={error_array[0]} className="form-row-error-msg">The image exceeds {error_array[1]}B</span>
            case 'max number of files':
                return <span key={error_array[0]} className="form-row-error-msg">Maximum number of images {error_array[1]}</span>
        }
    })
}