import React from 'react'
import { usePagination } from '@material-ui/lab/Pagination'

 const Pagination = ({pages, onChange, siblingCount, boundaryCount, currentPage, hideNextButton, hidePrevButton}) => {
 
    const { items } = usePagination({
      count: pages,
      page: currentPage,
      onChange: (...[,nr]) => onChange(nr),
      siblingCount, 
      boundaryCount,
      hideNextButton,
      hidePrevButton
    })

  
    return (
      <>

          {items.map(({ page, type, selected, onClick, ...item }, index) => {
      
            let children = null;
  
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = (
                <a 
                    href="#" 
                    onClick={e => e.preventDefault()}
                    css={`
                       &&& {
                            border: none;
                            :hover {
                                background: inherit;
                                color: #666;
                                border: none;
                                cursor: default;
                            }
                       }
                    `} 
                >...</a>
              )
            } 
            
            else if (type === 'page') {
              children = (
                <a 
                    href="#" 
                    onClick={e => {
                        e.preventDefault()
                        onClick(e)
                    }} 
                    className={`${selected ? 'active' : ''}`}  {...item}
                >{page}</a>
              )
            } 

            else if(type === 'previous') {
                children = (
                    <a 
                        href="#" 
                        onClick={e => {
                            e.preventDefault()
                            onClick(e)
                        }} 
                        className={`${selected ? 'active' : ''}`}  {...item}
                    ><i className="icon-ia-arrow-left"/></a>
                )
            } else if(type === 'next') {
                children = (
                    <a 
                        href="#" 
                        onClick={e => {
                            e.preventDefault()
                            onClick(e)
                        }} 
                        className={`${selected ? 'active' : ''}`}  {...item}
                    ><i className="icon-ia-arrow-right"/></a>
                )
            }
  
            return <React.Fragment key={index}>{children}</React.Fragment>
          })}
   
      </>
    )
  }

const Cpagination = ({pages = 0, page = 0, onChange = f => f}) => {

    return (
        <nav className="pagination">
            {pages > 1 &&
                <Pagination 
                    pages={pages}
                    onChange={onChange}
                    siblingCount={0}
                    currentPage={parseInt(page)}
                    hideNextButton={page === pages}
                    hidePrevButton={page ===1 }
                />
            }
        </nav>
    )
}


export default Cpagination