import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"
import LsignaturePad from '../../components/LsignaturePad'

export default ({
    element,
    answers_by_code,
    value = null
}) => {
    
    return (
        <div className="fieldset-row">
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}
            <LsignaturePad value={value}/>
        </div>
    )
}