export const synchronizeText = (text, answers_by_code, formula = false) => {
    // If no data, return template as-is
    if (typeof answers_by_code !== 'object') return text;

    if (formula) {
        const operators = ["+", "-", "*", '/'];
        text = text.replace(/[-+*\/]?{{([^}]+)}}/g, (match) => {
            let operator = "";
            if (operators.includes(match[0])) {
                operator = match[0];
                match = match.slice(1);
            }
            match = match.slice(2, -2);

            // Get the value
            const val = answers_by_code[match]?.[0];

            // Replace
            if (!val || typeof val !== 'string') return '';

            return operator + val;
        });
        if(operators.includes(text[0])){
            text = text.slice(1);
        }
    } else {
        // Replace our curly braces with data
        text = text.replace(/{{([^}]+)}}/g, (match) => {

            match = match.slice(2, -2);

            // Get the value
            const val = answers_by_code[match]?.[0];

            // Replace
            if (!val || typeof val !== 'string') return '';

            return val;
        });
    }

    return text;
};