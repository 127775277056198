export const NEW_SESSION_GET_FORM_STEPS_PENDING = 'NEW_SESSION_GET_FORM_STEPS_PENDING'
export const NEW_SESSION_GET_FORM_STEPS_REJECTED = 'NEW_SESSION_GET_FORM_STEPS_REJECTED'
export const NEW_SESSION_GET_FORM_STEPS_FULFILLED = 'NEW_SESSION_GET_FORM_STEPS_FULFILLED'
export const NEW_SESSION_SET_CURRENT_STEP = 'NEW_SESSION_SET_CURRENT_STEP'
export const NEW_SESSION_SET_VALUE = 'NEW_SESSION_SET_VALUE'
export const NEW_SESSION_SET_VALIDATION_ERRORS = 'NEW_SESSION_SET_VALIDATION_ERRORS'
export const NEW_SESSION_SUBMIT_SESSION_PENDING = 'NEW_SESSION_SUBMIT_SESSION_PENDING'
export const NEW_SESSION_SUBMIT_SESSION_REJECTED = 'NEW_SESSION_SUBMIT_SESSION_REJECTED'
export const NEW_SESSION_SUBMIT_SESSION_FULFILLED = 'NEW_SESSION_SUBMIT_SESSION_FULFILLED'
export const NEW_SESSION_CLEAR_CURRENT_STEP = 'NEW_SESSION_CLEAR_CURRENT_STEP'
export const NEW_SESSION_SET_INITIAL_STATE = 'NEW_SESSION_SET_INITIAL_STATE'
export const NEW_SESSION_SET_LOCATION = 'NEW_SESSION_SET_LOCATION'
export const NEW_SESSION_SET_TWO_STEP_VALIDATIONS = 'NEW_SESSION_SET_TWO_STEP_VALIDATIONS'
export const NEW_SESSION_SET_VALUES = 'NEW_SESSION_SET_VALUES'
export const NEW_SESSION_SET_STATE = 'NEW_SESSION_SET_STATE'
export const NEW_SESSION_SET_HIDDEN_ELEMENT = 'NEW_SESSION_SET_HIDDEN_ELEMENT'

export const initialState = {
    loading: false,
    submitting_session: false,
    submission_error: null,
    submission_success: false,
    validation_errors: {},
    error: null,
    form: {
        data: null,
        loading: false,
        error: null
    },
    current_step: 0,
    answers: [],
    hiddenElements: {},
    location: null,
    twostepvalidations: undefined,
    sessionId: undefined
};

export default (state=initialState, { type, payload }) => {
    switch(type) {
        case NEW_SESSION_SET_STATE: 
            return {
                ...state,
                ...payload
            }
        case NEW_SESSION_SET_HIDDEN_ELEMENT:
            return {
                ...state,
                hiddenElements: {...state.hiddenElements, ...payload}
            }
        case NEW_SESSION_SET_VALUES:
            return{
                ...state,
                answers: payload
            }
        case NEW_SESSION_SET_LOCATION:
            return {
                ...state,
                location: payload
            }
        case NEW_SESSION_SET_TWO_STEP_VALIDATIONS:
            return {
                ...state,
                twostepvalidations: payload
            }
        case NEW_SESSION_GET_FORM_STEPS_PENDING:
            return {
                ...state,
                form: {
                    loading: true,
                    error: null,
                    data: null
                }
            };
        case NEW_SESSION_GET_FORM_STEPS_REJECTED:
            return {
                ...state,
                form: {
                    loading: false,
                    data: null,
                    error: payload
                }
            };
        case NEW_SESSION_GET_FORM_STEPS_FULFILLED:
            return {
                ...state,
                form: {
                    loading: false,
                    data: payload,
                    error: null
                }
            };

        case NEW_SESSION_SET_CURRENT_STEP:
            return {
                ...state,
                current_step: payload
            };
        case NEW_SESSION_SET_VALUE:
            const validation_errors = { ...state.validation_errors };

            delete validation_errors[payload.element_id];

            if (payload.value === null || (Array.isArray(payload.value) && payload.value.length === 0)) {
                return {
                    ...state,
                    answers: state.answers.filter(answer => (answer.element_id !== payload.element_id)),
                    validation_errors
                }
            }

            const elements = [];

            for (let i = 0; i < state.form.data.steps.length; i++) {
                state.form.data.steps[i].elements.forEach(element => elements.push(element));
            }

            let values = [];

            if (state.answers.find(answer => answer.element_id === payload.element_id)) {
                values = state.answers.map(answer => {
                    if (answer.element_id === payload.element_id) {
                        return payload;
                    }

                    return answer;
                })
            } else {
                values = [ ...state.answers, payload ];
                values.sort((a, b) => {
                    const sorting = elements.map(element => element._id);
                    return sorting.indexOf(a.element_id) - sorting.indexOf(b.element_id);
                });
            }

            return {
                ...state,
                answers: values,
                validation_errors
            };

        case NEW_SESSION_SET_VALIDATION_ERRORS:
            return {
                ...state,
                validation_errors: payload
            };

        case NEW_SESSION_SUBMIT_SESSION_PENDING:
            return {
                ...state,
                submitting_session: true
            };
        case NEW_SESSION_SUBMIT_SESSION_REJECTED:
            return {
                ...state,
                submitting_session: false,
                submission_error: payload
            };
        case NEW_SESSION_SUBMIT_SESSION_FULFILLED:
            return {
                ...state,
                submitting_session: false,
                submission_success: true
            };
        case NEW_SESSION_CLEAR_CURRENT_STEP:
            const step_elements_ids = state.form.data.steps[state.current_step - 1].elements.map(element => element._id);

            const new_answers = state.answers.filter(answer => !step_elements_ids.includes(answer.element_id));

            return {
                ...state,
                answers: new_answers,
                validation_errors: {}
            };
        case NEW_SESSION_SET_INITIAL_STATE: 
            return ({ ...initialState });
        default:
            return state;
    }
};