import React from 'react'
import { TimePicker } from 'antd'
import moment from 'moment'
import AdatePicker from '../../components/AdatePicker/AdatePicker'
import {synchronizeText} from "../../utils/synchronizeText";

export default ({
    element,
    value = null,
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const setValue = (_, time) => {
        if (time === '')
            time = null;

        changeValue(time)
    };

    return (
        <div className="fieldset-row">
            <div className={`form-row with-calendar ${(errors.length > 0) ? 'error' : '' }`}>
                {element.text &&
                    <label>{synchronizeText(element.text, answers_by_code)}</label>
                }

                <AdatePicker
                    onChange={date => changeValue(date ? moment(date).format('HH:mm') : date)}
                    value={value ? moment(value, 'HH:mm').toDate() : null}
                    placeholder={element.placeholder}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                />

                {renderErrors(errors)}
                <i class="calendar-btn icon-clock"></i>
            </div>
        </div>
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        if (error === 'required') {
            return <span key={error} className="form-row-error-msg">This field is required</span>;
        }

        return null;
    })
};