import React from 'react'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import {css} from 'styled-components'

import Loading from "../../components/Loading"
import Navbar from '../../components/Navbar/Navbar'

import AdatePicker from '../../components/AdatePicker/AdatePicker'
import Cpagination from '../../components/Cpagination'
import API from '../../api'
import {getError} from "../../utils/getError"
import debounce from '../../utils/debounce'

const cssFixes = css`
    .table-ul.sessions-list ul {
        grid-template-columns: minmax(5rem,1fr) minmax(8rem,1.5fr) minmax(5rem,1fr) 7rem;
    }

    .table-actions {
        display: grid;
        grid-auto-flow: column;
    }

    .tooltip.tooltip-bottom.tooltip-left {
 
        left: -40%;
        ::before {
            left: 80%;
        }
    }

  .content-box-body-fixed-elements .box-body {
    padding: 5rem 0 6rem;
  }
`

const Sessions = () => {

        const history = useHistory()
        const page = new URLSearchParams(history.location.search).get('page')

        const [state, setState] = React.useState({

            filtersTab: false,
            dateFrom: null,
            dateTo: null,
            clientSearch: '',
            
            forms: [],
            formsSearch: '',
            selectedForms: [],
            formsDropDown: false,

            complitions: [{name: 'Finished', value: 'finished'}, {name: 'Pending', value: 'pending'}],
            selectedComplitions: [],
            completionSearch: "",
            completionDropdown: false,

            activations: [{name: 'Activated', value: 'activated'}, {name: 'Pending activation', value: 'pending'}],
            selectedActivations: [],
            activationDropdown: false,
            activationSearch: '',

            approvations: [{name: 'Approved', value: 'approved'}, {name: 'Pending approval', value: 'pending'}, {name: 'Declined', value: 'declined'}],
            selectedApprovations: [],
            approvationDropdown: false,
            approvationSearch: '',
            
            loading: false,
            data: [],
            pages: 0,
            page: page || 1,
            error: null,

            sort: null,
            order: null,
       
   
        }, 'sessions')

        const debounceStore = React.useState()

      

        React.useEffect(() => {
          (async() => {

            await getForms()
            if(history.location.state && history.location.state.sessionFilters) {
                setState(state => ({...state, ...history.location.state.sessionFilters}))
                getSessions({...state, ...history.location.state.sessionFilters})
                history.replace(history.location.pathname, undefined)
            } else {
                getSessions(state)
            }

          })()
   
        }, [])

        const toggleFiltersTab = () => setState(state => ({...state, filtersTab: !state.filtersTab}))

        const setClientSearch = clientSearch => {
            setState(state => ({...state, clientSearch}))
            debounce(() => getSessions({...state, page: 1, clientSearch}), 1000, debounceStore)
        }

        const setDateFrom = dateFrom => {
            setState(state => ({...state, dateFrom}))
            getSessions({...state, page: 1, dateFrom})
        }

        const setDateTo = dateTo => {
            setState(state => ({...state, dateTo}))
            getSessions({...state, page: 1, dateTo})
        }

        const setFormSearch = formsSearch => setState(state => ({...state, formsSearch}))

        const toggleChoseForm = form => {
            let {selectedForms} = state
            selectedForms.includes(form)
                ? selectedForms = selectedForms.filter(f => f !== form)
                : selectedForms = [...selectedForms, form]
            getSessions({...state, page: 1, selectedForms})
            setState(state => ({...state, selectedForms}))
        }

        const setCompletionSearch = completionSearch => setState(state => ({...state, completionSearch}))

        const toggleChoseCompletion = complition => {
            let {selectedComplitions} = state
            selectedComplitions.includes(complition)
                ? selectedComplitions = selectedComplitions.filter(c => c !== complition)
                : selectedComplitions = [...selectedComplitions, complition]
            getSessions({...state, page: 1, selectedComplitions})
            setState(state => ({...state, selectedComplitions}))
        }

        const setActivationSearch = activationSearch => setState(state => ({...state, activationSearch}))

        const toggleChoseActivation = activation => {
            let {selectedActivations} = state
            selectedActivations.includes(activation)
                ? selectedActivations = selectedActivations.filter(a => a !== activation)
                : selectedActivations = [...selectedActivations, activation]
            getSessions({...state, page: 1, selectedActivations})
            setState(state => ({...state, selectedActivations}))
        }

        const setApprovationSearch = approvation => setState(state => ({...state, approvation}))

        const toggleChoseApprovation = approvation => {
            let {selectedApprovations} = state
            selectedApprovations.includes(approvation)
                ? selectedApprovations = selectedApprovations.filter(a => a !== approvation)
                : selectedApprovations = [...selectedApprovations, approvation]
            getSessions({...state, page: 1, selectedApprovations})
            setState(state => ({...state, selectedApprovations}))
        }

        const changePage = page => {
            history.push({
                pathname: history.location.pathname,
                search: `?page=${page}`
            })
            getSessions({...state, page})
        }
        


        const getSessions = async (state=null) => {
            try {
                setState(state => ({...state, loading: true, error: null}))
                let url = '/sessions'
                const filters = []
                if (state) {
                    if (state.clientSearch.length) filters.push(`client_text=${state.clientSearch}`)
                    if (state.selectedForms.length) filters.push(`form_ids=${state.selectedForms.join(',')}`) 
                    if (state.dateFrom) filters.push(`date_from=${state.dateFrom}`) 
                    if (state.dateTo) filters.push(`date_to=${state.dateTo}`)
                    if (state.selectedComplitions.length > 0) filters.push(`finished=${state.selectedComplitions}`)
                    if (state.selectedActivations.length > 0) filters.push(`activation=${state.selectedActivations}`)
                    if (state.selectedApprovations.length > 0) filters.push(`approval=${state.selectedApprovations}`)

                    if (state.page) filters.push(`page=${state.page}`)                  
                    if (state.sort) filters.push(`sort=${state.sort}&order=${state.order}`)                                                 
                }

                if (filters.length) url += '?' + filters.join('&')
                const {data:{data, pages}} = await API.get(url)
                setState(state => ({...state, loading: false, data, pages}))
                if (state && state.page) selectPage(state.page)
            } catch (err) {
                setState(state => ({...state, loading: false, error: getError(err.response)}))
            }
        }

        const getForms = async () => {
            try {
                const response = await API.get('/forms/list/all')
                setState(state => ({...state, forms: response.data}))
            } catch (err) {}
        }

        const selectPage = page => setState(state => ({...state, page}))

        const clearFilters = () => {
            const clearedFilters = {
                search: '',
                clientSearch: '',
                selectedForms: [],
                dateFrom: null,
                dateTo: null,
                selectedActivations: [],
                selectedApprovations: [],
                selectedComplitions: [],

            }
            setState(state => ({...state, ...clearedFilters}))
            getSessions({...state, ...clearedFilters})
        }
    

        const setColumnSorting = (sort, order) => () => {
            setState(state => ({...state, sort, order}))
            getSessions({...state, page: 1, sort, order})
        }
    
        const renderSortIcon = (column) => {
            if (column !== state.sort)
                return <span className="cntrl-box"><i
                    className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
                    className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;
    
            if (state.order === 'desc')
                return <span className="cntrl-box"><i
                    className="cntrl-down icon-arrow-down selected" onClick={setColumnSorting(null, null)}/><i
                    className="cntrl-up icon-arrow-up" onClick={setColumnSorting(column, 'asc')}/></span>;
    
            return <span className="cntrl-box"><i
                className="cntrl-down icon-arrow-down" onClick={setColumnSorting(column, 'desc')}/><i
                className="cntrl-up icon-arrow-up selected" onClick={setColumnSorting(null, null)}/></span>;
        };
    
        const isEmpty = !state.loading && state.data.length === 0;


        return (
            <>
                <div className={`wrapper responses-page ${state.filtersTab ? 'view-filters' : ''}`} css={cssFixes}>
                    <Navbar />

                    <div class="content">
                        <div class="content-box">

                            <div class="content-box-header">
                                <h3>Most recent sessions</h3>
                                <div class="content-box-options">
                                    <span class="button button-outline with-icon-right trigger-filters" onClick={toggleFiltersTab}><i class="icon-ia-arrow"></i>Filters</span>
                                </div>
                            </div>

                            
                            <div class="content-box-subheader filters">
                                <h4>Filters</h4>
                                <ul class="applied-filters">
                                    {(
                                        state.selectedForms.length > 0
                                        || state.clientSearch.length > 0
                                        || state.selectedActivations.length > 0
                                        || state.selectedApprovations.length > 0
                                        || state.selectedComplitions.length > 0
                                        || state.dateFrom
                                        || state.dateTo
                                     ) &&
                                        <>
                                            <li>
                                                <span>Filtre:</span>
                                            </li>
                                            {state.clientSearch &&
                                                <li>
                                                    <i class="delete-filter" onClick={() => setClientSearch('')} />
                                                    <span>{state.clientSearch}</span>
                                                </li>
                                            }
                                            {state.selectedForms.map((formId, formIndex) =>
                                                <li key={`${formIndex}${formId}`}>
                                                    <i className="delete-filter" onClick={() => toggleChoseForm(formId)}/>
                                                    <span>{state.forms.find(({_id}) => _id === formId).name}</span>
                                                </li>
                                            )}
                                            {state.dateFrom &&
                                                <li>
                                                    <i class="delete-filter" onClick={() => setDateFrom(null)}/>
                                                    <span>{state.dateFrom}</span>
                                                </li>
                                            }
                                            {state.dateTo &&
                                                <li>
                                                    <i class="delete-filter" onClick={() => setDateTo(null)} />
                                                    <span>{state.dateTo}</span>
                                                </li>
                                            }
                                            {state.selectedComplitions.map((complitionValue, complitionIndex) =>
                                                <li key={`${complitionIndex}${complitionValue}`}>
                                                    <i className="delete-filter" onClick={() => toggleChoseCompletion(complitionValue)}/>
                                                    <span>{state.complitions.find(({value}) => value === complitionValue).name}</span>
                                                </li>
                                            )}
                                            {state.selectedActivations.map((activationValue, activationIndex) =>
                                                <li key={`${activationIndex}${activationValue}`}>
                                                    <i className="delete-filter" onClick={() => toggleChoseActivation(activationValue)}/>
                                                    <span>{state.activations.find(({value}) => value === activationValue).name}</span>
                                                </li>
                                            )}
                                            {state.selectedApprovations.map((approvationValue, approvationIdex) =>
                                                <li key={`${approvationIdex}${approvationValue}`}>
                                                    <i className="delete-filter" onClick={() => toggleChoseApprovation(approvationValue)}/>
                                                    <span>{state.approvations.find(({value}) => value === approvationValue).name}</span>
                                                </li>
                                            )}
                                            <li class="applied-filters-actions">
                                                <div class="tbl-btn" onClick={clearFilters}><i class="icon-refresh-ccw"></i><div class="tooltip tooltip-top">DELETE FILTERS</div></div>
                                            </li>
                                        </>
                                    }
                                </ul>
                                <div class="form">
                                    {/* <div class="form-row">
                                        <label for="">Clienti</label>
                                        <input type="text" placeholder="Cauta clienti" value={state.clientSearch} onChange={({target:{value}}) => setClientSearch(value)} />
                                    </div> */}
                                    <div 
                                        className={`form-row ${state.formsDropDown ? 'view-dropdown' : ''}`}
                                        onBlur={() => setState(state => ({...state, formsDropDown: false}))}
                                    >
                                        <label for="">FORMS</label>
                                        <input 
                                            type="text" 
                                            value={state.formsSearch} 
                                            onChange={({target:{value}}) => setFormSearch(value)} 
                                            placeholder="Select forms"
                                            onFocus={() => setState(state => ({...state, formsDropDown: true}))}
                                        />
                                        <div class="dropdown">
                                            <div class="scrollbar">
                                                <ul>
                                                    {state.forms.filter(({name}) => state.formsSearch.length > 0 ? name.toLowerCase().includes(state.formsSearch.toLowerCase()) : true)
                                                    .map(form => 
                                                        <li 
                                                            key={form._id} 
                                                            onMouseDown={e => {
                                                                e.preventDefault()
                                                                toggleChoseForm(form._id)
                                                            }}
                                                            className={`${state.selectedForms.includes(form._id) ? 'selected' : ''}`}
                                                        >{form.name}</li>
                                                    )} 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row with-calendar">
                                        <label for="">DATE FROM</label>
                                        <AdatePicker
                                            placeholder="Select date"
                                            value={state.dateFrom ? moment(state.dateFrom, 'DD/MM/YYYY').toDate() : null}
                                            onChange={date => setDateFrom(date ? moment(date).format('DD/MM/YYYY') : date)}
                                            dateFormat={"dd/MM/yyyy"}
                                            maxDate={state.dateTo && moment(state.dateTo, 'DD/MM/YYYY').toDate()}
                                        />
                                        <i class="calendar-btn icon-ia-calendar"></i>
                                    </div>
                                    <div class="form-row with-calendar">
                                        <label for="">DATE TO</label>
                                        <AdatePicker
                                            placeholder="Select date"
                                            value={state.dateTo ? moment(state.dateTo, 'DD/MM/YYYY').toDate() : null}
                                            onChange={date => setDateTo(date ? moment(date).format('DD/MM/YYYY') : date)}
                                            dateFormat={"dd/MM/yyyy"}
                                            minDate={state.dateFrom && moment(state.dateFrom, 'DD/MM/YYYY').toDate()}
                                        />
                                        <i class="calendar-btn icon-ia-calendar"></i>
                                    </div>
                                    <div 
                                        className={`form-row ${state.completionDropdown ? 'view-dropdown' : ''}`}
                                        onBlur={() => setState(state => ({...state, completionDropdown: false}))}
                                    >
                                        <label for="">COMPLETION STATUS</label>
                                        <input 
                                            type="text" 
                                            value={state.completionSearch} 
                                            onChange={({target:{value}}) => setCompletionSearch(value)} 
                                            placeholder="Select completion status"
                                            onFocus={() => setState(state => ({...state, completionDropdown: true}))} 
                                        />
                                        <div class="dropdown">
                                            <div class="scrollbar">
                                                <ul>
                                                    {state.complitions.filter(({name}) => state.completionDropdown.length > 0 ? name.toLowerCase().includes(state.completionSearch.toLowerCase()) : true)
                                                        .map(completition => 
                                                        <li 
                                                            key={completition.name} 
                                                            onMouseDown={e => {
                                                                e.preventDefault()
                                                                toggleChoseCompletion(completition.value)
                                                            }}
                                                            className={`${state.selectedComplitions.includes(completition.value) ? 'selected' : ''}`}
                                                        >{completition.name}</li>
                                                    )} 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-row ${state.activationDropdown ? 'view-dropdown' : ''}`}
                                        onBlur={() => setState(state => ({...state, activationDropdown: false}))}
                                    >
                                        <label>ACTIVATION STATUS</label>
                                        <input 
                                            type="text" 
                                            value={state.activationSearch}
                                            onChange={setActivationSearch}
                                            placeholder="Select activation status"
                                            onFocus={() => setState(state => ({...state, activationDropdown: true}))}  
                                        />
                                        <div class="dropdown">
                                            <div class="scrollbar">
                                                <ul>
                                                    {state.activations.filter(({name}) => state.completionDropdown.length > 0 ? name.toLowerCase().includes(state.completionSearch.toLowerCase()) : true)
                                                        .map(activation => 
                                                        <li 
                                                            key={activation.name} 
                                                            onMouseDown={e => {
                                                                e.preventDefault()
                                                                toggleChoseActivation(activation.value)
                                                            }}
                                                            className={`${state.selectedActivations.includes(activation.value) ? 'selected' : ''}`}
                                                        >{activation.name}</li>
                                                    )} 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`form-row ${state.approvationDropdown ? 'view-dropdown' : ''}`}
                                        onBlur={() => setState(state => ({...state, approvationDropdown: false}))}
                                    >
                                        <label for="">APPROVAL STATUS</label>
                                        <input 
                                            type="text" 
                                            value={state.approvationSearch}
                                            onChange={({target:{value}}) => setApprovationSearch(value)}
                                            placeholder="Select approval status"
                                            onFocus={() => setState(state => ({...state, approvationDropdown: true}))}   
                                        />
                                        <div class="dropdown">
                                            <div class="scrollbar">
                                                <ul>
                                                    {state.approvations.filter(({name}) => state.completionDropdown.length > 0 ? name.toLowerCase().includes(state.completionSearch.toLowerCase()) : true)
                                                        .map(approvation => 
                                                        <li 
                                                            key={approvation.name} 
                                                            onMouseDown={e => {
                                                                e.preventDefault()
                                                                toggleChoseApprovation(approvation.value)
                                                            }}
                                                            className={`${state.selectedApprovations.includes(approvation.value) ? 'selected' : ''}`}
                                                        >{approvation.name}</li>
                                                    )} 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="tbl-btn close-subheader" onClick={toggleFiltersTab}><i class="icon-x"></i></span>
                            </div>

                            {state.loading && <Loading />}
                            {!state.loading &&                            
                                <div class="content-box-body content-box-body-fixed-elements">
                                    <div class="box-body box-body-fixed">

                                        <div class="table-fixed-header">
                                            <ul class="table-ul sessions-list">
                                                <li class="table-ul-header">
                                                    <ul>
                                                        <li className="with-cntrl-box">
                                                            <strong>Category</strong>
                                                            {renderSortIcon('category_name')}
                                                        </li>
                                                        <li className="with-cntrl-box">
                                                            <strong>Form</strong>
                                                            {renderSortIcon('form_name')}
                                                        </li>
        
                                                        {/* <li className="with-cntrl-box">
                                                            <strong>Client</strong>
                                                            {renderSortIcon('client_name')}
                                                        </li> */}
        
                                                        <li className="with-cntrl-box">
                                                            <strong>Date and time</strong>
                                                            {renderSortIcon('updated_at')}
                                                        </li>
                                                        <li><strong>Session details</strong></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="scrollbar">
                                            {isEmpty &&
                                                <div css={`
                                                    position: absolute;
                                                    left: 50%;
                                                    top: 50%;
                                                    transform: translate(-50%, -50%);
                                                    font-size: 1.1rem;
                                                `}>No sessions</div>
                                            }
                                           {!isEmpty &&
                                                <ul class="table-ul sessions-list">
                                                    
                                                    {!state.loading && state.data.map(session => (

                                                        <li key={session._id}>
                                                            <ul>
                                                                <li><strong>{session.category_name}</strong></li>
                                                                <li><strong>{session.form_name}</strong></li>
                                                                {/* <li>
                                                                    {session._client !== null &&
                                                                        <strong>{session._client.name}<span>{session._client.address}</span></strong>
                                                                    }
                                                                </li> */}
                                                                <li>
                                                                    <strong>{moment(session.updated_at).format('D MMM HH:mm')}<span>({moment(session.updated_at).format('YYYY')})</span></strong>
                                                                </li>
                                                                
                                                                <li>
                                                                    <div className="table-actions">
                                                                        <a href="#" class="tbl-btn" onClick={e => {
                                                                            e.preventDefault()

                                                                            history.replace(history.location.pathname, {
                                                                                sessionFilters: {
                                                                                    dateFrom: state.dateFrom,
                                                                                    clientSearch: state.clientSearch,
                                                                                    selectedForms: state.selectedForms,
                                                                                    dateTo: state.dateTo,
                                                                                    selectedComplitions: state.selectedComplitions,
                                                                                    selectedActivations: state.selectedActivations,
                                                                                    selectedApprovations: state.selectedApprovations
                                                                                }
                                                                            })

                                                                            history.push(`/sessions/${session._id}`)
                                                                        }}>
                                                                            <i class="icon-ia-preview-file" /><div class="tooltip tooltip-bottom">View session</div>
                                                                        </a>
                                                                        {session.form_name !== 'Deleted' &&
                                                                            <a className="tbl-btn" href="#" onClick={e => {
                                                                                e.preventDefault()
                                                                                history.push(`/forms/${session.form_id}/sessions/${session._id}/edit`, {sessionId: session._id})
                                                                            }}>
                                                                                <i className="icon-ia-edit"/><div class="tooltip tooltip-bottom tooltip-left">Edit session</div>
                                                                            </a>
                                                                        }  
                                                                    </div>
                                                                </li>

                                                            </ul>
                                                        </li>

                                                    ))}
                                                </ul>
                                           }
                                        </div>

                                        <div class="box-action table-action-fixed with-pagination">
                                            <Cpagination 
                                                page={state.page}
                                                pages={state.pages}
                                                onChange={changePage}
                                            />
                                        </div>

                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </>
        )
    }

export default Sessions