import React from 'react'

export const AgoogleSignIn = ({ clientId, onSuccess=f=>f, onFailure=f=>f, render, scope, loginHint }) => {


    const handleCodemessage = event => {
        if(event?.data?.code === undefined) return
        const {code} = event.data
        onSuccess({code})
        window.removeEventListener('message', handleCodemessage)
    }

    const onClick = () => {
        const redirectUri = `${process.env.REACT_APP_CLIENT_BASE_URL}/invitation/google/redirect`;
        const responseType = 'code';
        let authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;

        if(loginHint) {
            authUrl += `&login_hint=${loginHint}`
        }
        const popupWindow = window.open(authUrl, '_blank', 'width=600,height=800');
        window.addEventListener('message', handleCodemessage)
        if (popupWindow) {
            popupWindow.focus();
        }
    }


    return render({onClick, disabled: false}) || null
};