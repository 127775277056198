import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";

export default ({
    element,
    value = null,
    changeValue,
    answers_by_code,
    errors = [],
    newSessionStore
}) => {

    const [sessionState, setSessionState] = newSessionStore

    React.useEffect(() => {
        if(!!element.defaultValue && !sessionState.defaultValuesSet[element._id]) {
            changeValue(element.defaultValue);
            setSessionState(sessionState => ({
                ...sessionState,
                defaultValuesSet: {...sessionState.defaultValuesSet, [element._id]: true}
            }))
        }
    }, []);

    const setValue = e => {
        changeValue(e.target.value)
    }

    return (
        <div className="fieldset-row">
            {element.text &&
                <h3>{synchronizeText(element.text, answers_by_code)}</h3>
            }

            <div className="form-grid" style={{ width: '100%', display: 'grid', gridTemplateColumns: `repeat(${element.grid_columns || 1}, 1fr`}}>
                {element.items.map(item => (
                    <div key={item} className={`form-row-radio ${errors.length !== 0 ? 'error' : ''}`}>
                        <input id={`radio_${element._id}_${item}`} type="radio" value={item} checked={item === value} onChange={setValue}/>
                        <label htmlFor={`radio_${element._id}_${item}`}>{item}</label>
                    </div>
                ))}
            </div>

            <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                {renderErrors(errors)}
            </div>
        </div>
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        if (error === 'required') {
            return 'This field is required';
        }
    }).map(error => (
        <span key={error} className="form-row-error-msg">{error}</span>
    ))
};