import React from 'react'
import jwt_decode from "jwt-decode"
import API from '../../api'
import { getError } from "../../utils/getError"
import Loading from '../../components/Loading'
import {useAppContext} from '../../context'

const VerifySMS = ({setTab, loginStore}) => {

    const [state, setState] = React.useState({
        code: '',
        loading: false,
        codeSent: false,
        errors: {}
    }, 'login-sms-verification')

    const [loginState, setLoginState] = loginStore

    const {auth:[auth, setAuth]} = useAppContext()

    const setCode = code => setState(state => ({ ...state, code }))

    const verifyPasscodeRequest = async () => {
        const {username} = loginState
        const {code} = state

        const errors = {}
		if(code === '' || code.length !== 6) errors.code = "The code is incorrect"
		setState(state => ({...state, errors}))
		if(Object.keys(errors).length > 0) return

		try {

			setState(state => ({
				...state,
				loading: true,
				codeSent: false,
				errors: {},
			}))

			const response = await API.post(`/auth/verify-passcode`, {username, code}, {
				headers: {
					Authorization: `Basic ${window.btoa(
						`${process.env.REACT_APP_API_KEY}:${process.env.REACT_APP_API_SECRET}`
					)}`,
				},
			})

			const { access_token, refresh_token } = response.data
			const expirationTime = Date.now() + response.data.expires_in * 1000
            const decodedToken = jwt_decode(access_token)
            const refreshExpirationTime = Date.now() + response.data.refresh_token_expires_in * 1000;
            
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken,
            })

			setState(state => ({ ...state, loading: false, success: true }))
		} catch (err) {

            const errors = {}
            const error = getError(err.response)

            if(error === 'invalid_code') errors.code = "The code is incorrect"
            else if(error === 'incorrect_code') errors.code =  "The code is incorrect"
            else if(error === 'code_expired') errors.code = "Codul a expirat"
            else errors.other = "Something went wrong"

			setState(state => ({
				...state,
				loading: false,
				errors
            }))
            
		}
    }

    const resendPasscode = async () => {
		try {
			setState(state => ({
				...state,
				codeSent: false,
				loading: true,
				errors: {},
            }))
            
            const {username, password} = loginState

			await API.post(`/auth/login`, {username, password}, {
				headers: {
					Authorization: `Basic ${window.btoa(
						`${process.env.REACT_APP_API_KEY}:${process.env.REACT_APP_API_SECRET}`
					)}`,
				},
			})

			setState(state => ({ ...state, loading: false, codeSent: true }))
		} catch (err) {

			setState(state => ({
				...state,
				loading: false,
				errors: {
                    other: "Something went wrong"
                },
			}))
		}
    }
    
    const accessAnotherAccount = () => {
        setLoginState(state => ({...state, username: "", password: ""}))
        setTab("LOGIN")
    }
    
    return(
        <div className="sms-pass-box">
            <div className="forgot-pass-header">
                <span className="button button-link with-icon-left hide-forgot-pass" onClick={() => setTab("LOGIN")}>
                    <i className="icon-ia-arrow-bottom"></i>BACK TO LOGIN
                </span>
            </div>
            {state.loading && <Loading />}
            {!state.loading &&            
                <div className="forgot-pass-content">
                    <div className="scrollbar">
                        <div className="table">
                            <div className="table-cell">
                                <form className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`} onSubmit={e => {
                                    e.preventDefault()
                                    verifyPasscodeRequest()
                                }}>
                                    <h3>Add the SMS code</h3>
                                    <div className={`form-row ${state.errors.code ? 'error' : ''}`}>
                                        <input
                                            id="form-name"
                                            placeholder="Code"
                                            onChange={e => setCode(e.target.value)}
                                            value={state.code}
                                        />
                                    </div>
                                    <div className="sms-pass-options">
                                        <a href="#" onClick={e => {
                                            e.preventDefault()
                                            resendPasscode()
                                        }}>Resend code</a>
                                        <a className="hide-forgot-pass" href="#" onClick={e => {
                                            e.preventDefault()
                                            accessAnotherAccount()
                                        }}>Login with another account</a>
                                    </div>
                                    {Object.keys(state.errors).length > 0 &&
                                        <div className="error-msg">{Object.values(state.errors)[0]}</div>
                                    }
                                    <div className="form-row last">
                                        <button className="button with-icon-right">
                                            <i className="icon-ia-arrow-right"></i>Send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default VerifySMS