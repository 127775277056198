import { elements as ELEMENTS } from '../constants/elements';

export const getAnswersByCode = (values, steps) => {
    const answers_by_code = {};

    if (values) {
        const elements = [];

        steps.forEach(step => {
            step.elements.forEach(element => elements.push(element))
        });

        values.forEach(value => {
            const element = elements.find(elm => elm._id === value.element_id);

            if (element && element.code) {

                switch(element.type) {
                    case ELEMENTS.CONTRACT_COMMUNICATION_OPTIONS:
                        answers_by_code[element.code+'_name'] = value.name;
                        answers_by_code[element.code+'_phone'] = value.phone;
                        answers_by_code[element.code+'_address'] = value.address;
                        answers_by_code[element.code+'_email'] = value.email;
                        answers_by_code[element.code+'_monthly_income'] = value.monthly_income;
                        answers_by_code[element.code+'_children_no'] = value.children_no;
                        break;
                    case ELEMENTS.STANDARD_ADDRESS:
                        answers_by_code[element.code+'_first_name'] = value.value.first_name;
                        answers_by_code[element.code+'_last_name'] = value.value.last_name;
                        answers_by_code[element.code+'_country'] = value.value.country;
                        answers_by_code[element.code+'_locality'] = value.value.locality;
                        answers_by_code[element.code+'_street'] = value.value.street;
                        answers_by_code[element.code+'_nr'] = value.value.nr;
                        answers_by_code[element.code+'_block'] = value.value.block_value;
                        answers_by_code[element.code+'_entry'] = value.value.entry;
                        answers_by_code[element.code+'_floor'] = value.value.floor;
                        answers_by_code[element.code+'_apartment'] = value.value.apartment;
                        answers_by_code[element.code+'_postal_code'] = value.value.postal_code;
                        break;
                    case ELEMENTS.ID_CARD_EXTRACTOR:
                        answers_by_code[element.code+'_first_name'] = value.value.first_name;
                        answers_by_code[element.code+'_last_name'] = value.value.last_name;
                        answers_by_code[element.code+'_email'] = value.value.email;
                        answers_by_code[element.code+'_phone'] = value.value.phone;
                        answers_by_code[element.code+'_cnp'] = value.value.cnp;
                        answers_by_code[element.code+'_date_of_birth'] = value.value.date_of_birth;
                        answers_by_code[element.code+'_country'] = value.value.country;
                        answers_by_code[element.code+'_locality'] = value.value.locality;
                        answers_by_code[element.code+'_street'] = value.value.street;
                        answers_by_code[element.code+'_nr'] = value.value.nr;
                        answers_by_code[element.code+'_block'] = value.value.block_value;
                        answers_by_code[element.code+'_entry'] = value.value.entry;
                        answers_by_code[element.code+'_floor'] = value.value.floor;
                        answers_by_code[element.code+'_apartment'] = value.value.apartment;
                        answers_by_code[element.code+'_series_ci'] = value.value.series_ci;
                        answers_by_code[element.code+'_nr_ci'] = value.value.nr_ci;
                        answers_by_code[element.code+'_emitted_by'] = value.value.emitted_by;
                        answers_by_code[element.code+'_available_from'] = value.value.available_from;
                        answers_by_code[element.code+'_available_to'] = value.value.available_to;
                        break;
                    case ELEMENTS.PRIVACY_AGREEMENT:
                        answers_by_code[element.code+'_first_name'] = value.value.first_name;
                        answers_by_code[element.code+'_last_name'] = value.value.last_name;
                        answers_by_code[element.code+'_signature'] = value.value.signature;
                        break;
                    case ELEMENTS.AGENT_SIGNATURE:
                        answers_by_code[element.code+'_name'] = value.name;
                        answers_by_code[element.code+'_signature'] = value.signature;
                        break;
                    default:
                        answers_by_code[element.code] = value.value;
                }

            }
        });
    }

    return answers_by_code;
};