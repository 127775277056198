import React from "react"
import { useHistory } from "react-router-dom"
import Navbar from "../../components/Navbar/Navbar"
import Loading from "../../components/Loading"
import API from "../../api"
import { getError } from "../../utils/getError"
import { useAppContext } from "../../context"

const Forms = () => {
	const [state, setState] = React.useState({
		loading: true,
		error: null,
		data: [],
	})

	const {
		company: [company],
	} = useAppContext()

	const history = useHistory()

	React.useEffect(() => {
		getFormsList()
	}, [])

	const getFormsList = async () => {
		try {
			setState(state => ({ ...state, error: null, loading: true }))
			const { data } = await API.get("/forms/client/list/all")
			setState(state => ({ ...state, data, loading: false }))
		} catch (err) {
			setState(state => ({
				...state,
				loading: false,
				error: getError(err.response),
			}))
		}
	}

	const color_styling = {
		color:
			company.font_color === "#000000" && company.background_color === "#F1F1F1"
				? undefined
				: company.font_color,
	}

	return (
		<div className="wrapper list-page">
			<Navbar />

			<div
				className="wrapper-content"
				style={{
					backgroundColor: company.background_color,
					fontFamily: company.font_face,
				}}
			>
				{state.loading && <Loading />}

				{!state.loading && state.error && (
					<div className="error-msg">
						{state.error === "server_error"
							? "There was a problem with the server"
							: "Something went wrong"}
					</div>
				)}

				{!state.loading && !state.error && (
					<>
						{state.data.length === 0 && (
							<h1 className="wrapper-content-title" style={color_styling}>
								No forms assigned
							</h1>
						)}
						{state.data.length !== 0 && (
							<h1 className="wrapper-content-title" style={color_styling}>
								Choose one form to start:
							</h1>
						)}
						<ul className="list-content">
							{state.data.map(form => (
								<li key={form._id}>
									<a
										href="#"
										onClick={e => {
											e.preventDefault()
											history.push(`/forms/${form._id}/session`)
										}}
									>
										<strong style={color_styling}>{form.name}</strong>
										<span style={color_styling}>{form.category}</span>
									</a>
								</li>
							))}
						</ul>
					</>
				)}
			</div>
		</div>
	)
}

export default Forms
