import React from 'react'
import { notification } from "antd"
import { saveAs } from 'file-saver'
import FileSvg from "../../images/file.svg"
import API from '../../api'

export default ({
    value = [],
    session_id,
    secret
}) => {

    const onFileDownload = async (fileId, fileName) => {
        try {
            let response;
            !secret
                ? response = await API.get(`/sessions/${session_id}/file/${fileId}`, {responseType: 'blob'})
                : response = await API.get(`/sessions/${session_id}/file/${fileId}/secret/${secret}`, {responseType: 'blob'})
    
            saveAs(response.data, session_id + "_" + fileName)
        } catch (err) {
            notification.error({
                message: 'There was a problem downloading the file'
            })
        }
    }

    return (
        <div className="fieldset-row">
            <div className="form-grid files-list" style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
                {value.length === 0 && <div>No Files Uploaded</div>}

                {value.map(file => (
                    <div key={file.id} className="file" style={{cursor:'pointer'}} onClick={() => onFileDownload(file.id, file.filename)}>
                        <img src={FileSvg} alt="" />
                        <strong>{file.filename}</strong>
                    </div>
                ))}
            </div>
        </div>
    )
}