import React from "react"

import CpopUp from '../../components/CpopUp'

const CollectLocation = ({ value = null, changeValue = f => f }) => {
	React.useEffect(() => {
		if (!value) {
			navigator.geolocation.getCurrentPosition(
				location => {
					const { latitude, longitude } = location.coords
					changeValue({ coordinates: [longitude, latitude] })
				},
				err => {
					console.log(err)
				}
			)
		}
	}, [])

	return (
		<CpopUp
			visible={!value}
			title="Location"
			disableFooter
			disableCancel={true}
			overLayclassName="overlay-template"
		>
			<p>
				We need to access your location in order to complete this form. You can access it from browser settings.
			</p>
		</CpopUp>
	)
}

export default CollectLocation
