import React from 'react'
import {message} from 'antd'
import {isEqual} from 'lodash'

import Modal from './Modal'
import {css} from 'styled-components'

const cssFixes = css`
    .car-insurance-menu i:after {
        opacity: 0;
    }

    .car-insurance-menu {
        grid-template-columns: 6.4rem auto 6.4rem auto 6.4rem auto 6.4rem;
        grid-gap: unset;
    }

    .car-insurance-menu div {
        position: relative;
        ::before {
            width: 100%;
            height: 1px;
            position: absolute;
            top: 3.2rem;
            /* left: 100%; */
            content: "";
            background: #666;
        }
    }


    .switcher input[type="checkbox"] + label::before {
        content: "NU";
        text-align: right;
        color: white;
    }

    .switcher input[type="checkbox"]:checked + label::before {
        content: "DA";
        text-align: left;
        color: var(--primary-color);
    }

    .switcher label::before {
        padding: 0 0.4rem;
    }

    .switcher label::after {
        right: 4.4rem;
    } 

    .switcher input[type="checkbox"]:checked + label::after {
        transform: translate3d(2.6rem,0,0);
    } 
`

const exteriorModal = {
    zgariat: false,
    crapat: false,
    indoit: false,
    ruginit: false,
    cost: 0
};

const interiorModal = {
    zgariat: false,
    patat: false,
    rupt: false,
    foarte: false,
    cost: 0
};

const initialValue = {
    exterior: {
        baraFata: exteriorModal,
        baraFataStanga: exteriorModal,
        baraFataDreapta: exteriorModal,
        grilaFata: exteriorModal,
        capotaFata: exteriorModal,
        farDreapta: exteriorModal,
        farStanga: exteriorModal,

        parbriz: exteriorModal,
        aripaStangaFata: exteriorModal,
        jantaStangaFata: exteriorModal,
        oglindaStangaFata: exteriorModal,
        portieraStangaFata: exteriorModal,

        portieraStangaSpate: exteriorModal,
        pragaStanga: exteriorModal,
        jantaStangaSpate: exteriorModal,
        aripaStangaSpate: exteriorModal,
        baraSpate: exteriorModal,
        baraSpateStanga: exteriorModal,
        baraSpateDreapta: exteriorModal,

        capotaSpate: exteriorModal,
        luneta: exteriorModal,
        stopStanga: exteriorModal,
        stopDreapta: exteriorModal,
        aripaDreaptaSpate: exteriorModal,

        jantaDreaptaSpate: exteriorModal,
        portieraDreapta: exteriorModal,
        portieraDreaptaFata: exteriorModal,
        pragDreapta: exteriorModal,
        plafonDreapta: exteriorModal,
        plafonStanga: exteriorModal,

        oglindaDreapta: exteriorModal,
        jantaDreaptaFata: exteriorModal,
        aripaDreaptaFata: exteriorModal,
        stalpi: {
            checked: false,
            text: ""
        },

        lonjeroane: {
            checked: false,
            text: ""
        },

        observatii: {
            checked: false,
            text: ""
        }
    },
    interior: {
        pedale: interiorModal,
        volan: interiorModal,
        schimbatorViteze: interiorModal,
        butoanePlansaBord: interiorModal,
        butoaneMultimedia: interiorModal,

        butoanePortiere: interiorModal,
        cotiera: interiorModal,
        plansaBord: interiorModal,
        plafon: interiorModal,
        scaunSofer: interiorModal,

        scaunPasager: interiorModal,
        banchetaSpate: interiorModal,
        centuriSiguranta: interiorModal,
        capitonajeUsi: interiorModal,
        covorase: interiorModal,

        mirosNeplacut: {
            checked: false,
            text: ""
        },
        podea: {
            checked: false,
            text: ""
        },
        tapiserie: {
            checked: false,
            text: ""
        },
        observatii: {
            checked: false,
            text: ""
        }

    },
    pornireMotor: {
        martoriImportanti: {
            checked: false,
            text: ""
        },
        alteAlerteActive: {
            checked: false,
            text: ""
        },
        zgomoteMotor: {
            checked: false,
            text: ""
        },
        zgomoteTransmisie: {
            checked: false,
            text: ""
        },
        observatii: {
            checked: false,
            text: ""
        }

    },
    accesorii: {
        bareTransversale: {
            checked: false,
            text: ""
        },
        bullBar: {
            checked: false,
            text: ""
        },
        cameraVideoParbiz: {
            checked: false,
            text: ""
        },
        carligTractare: {
            checked: false,
            text: ""
        },
        pachetSiguranta: {
            checked: false,
            text: ""
        },
        portBagajSuplimentar: {
            checked: false,
            text: ""
        },
        praguriInox: {
            checked: false,
            text: ""
        },
        suportBiciclete: {
            checked: false,
            text: ""
        },
        suportSchiuri: {
            checked: false,
            text: ""
        },
        alteAcessorii: {
            checked: false,
            text: ""
        }
    }
};

export default ({
                    element,
                    value = initialValue,
                    changeValue = f => f
                }) => {
    const [state, setState] = React.useState({
        collapsed: {
            exterior: true,
            interior: true,
            engine: true,
            accesory: true
        }

    });

    const onOpenClick = key => {
        setState(state => ({
            ...state,
            collapsed: {
                exterior: true,
                interior: true,
                engine: true,
                accesory: true,
                [key]: !state.collapsed[key]
            }
        }));
        setTimeout(() => scrolltoId(
            ({
                exterior: 'outside-car-row',
                interior: 'inside-car-row',
                engine: 'engine-row',
                accesory: 'accessory-car-row'
            })[key]
        ), 1)
    };

    const scrolltoId = id => {
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({behavior: "smooth", block: "start"})
    };

    return (
        <div css={cssFixes}>
            <div className="fieldset-row fieldset-row-header">
                {/* <h6 className="sup-title">Lorem Ipsum is simply dummy text</h6> */}
                <h1 className="main-title">{element.title}</h1>
                <p className="paragraph">{element.text}</p>
                <nav className="car-insurance-menu">
                    <a onClick={() => scrolltoId("outside-car-row")}><i
                        className="icon-ia-exterior"/><span>Exterior</span></a>
                        <div />
                    <a onClick={() => scrolltoId("inside-car-row")}><i
                        className="icon-ia-interior"/><span>Interior</span></a>
                        <div />
                    <a onClick={() => scrolltoId("engine-row")}><i
                        className="icon-ia-engine"/><span>Pornire Motor</span></a>
                        <div />
                    <a onClick={() => scrolltoId("accessory-car-row")}><i
                        className="icon-ia-accessories"/><span>Accesorii</span></a>
                </nav>
            </div>

            <Exterior
                onOpenClick={() => onOpenClick("exterior")}
                collapsed={state.collapsed.exterior}
                value={value.exterior}
                onChange={newValue => changeValue({...value, exterior: newValue})}
            />

            <Interior
                onOpenClick={() => onOpenClick("interior")}
                collapsed={state.collapsed.interior}
                onChange={newValue => changeValue({...value, interior: newValue})}
                value={value.interior}
            />

            <Engine
                onOpenClick={() => onOpenClick("engine")}
                collapsed={state.collapsed.engine}
                onChange={newValue => changeValue({...value, pornireMotor: newValue})}
                value={value.pornireMotor}
            />

            <Accessory
                onOpenClick={() => onOpenClick("accesory")}
                collapsed={state.collapsed.accesory}
                onChange={newValue => changeValue({...value, accesorii: newValue})}
                value={value.accesorii}
            />
        </div>
    )
}

const Exterior = ({
                      collapsed = true,
                      onOpenClick = f => f,
                      value,
                      onChange
                  }) => {

    const [state, setState] = React.useState({
        group0: 0,
        group1: 0,
        group2: 0
    });

    const toggleGroup = group => {
        setState(state => ({...state, [group]: state[group] === 0 ? 1 : 0}))
    };

    return (
        <div className={`exp-row ${collapsed ? "" : "expand"}`} id="outside-car-row">

            <div className="exp-row-header" onClick={onOpenClick}>
                <h3><i className="exp-icon icon-ia-exterior"/>Exterior</h3>
                <span className="exp-row-arrow icon-ia-arrow"/>
            </div>

            <div className="exp-row-body">

                <div className={`exterior-car-diagram ${state.group0 === 0 ? "" : "view-info-content"}`}>
                    <svg viewBox="0 0 720 250" style={{enableBackground: "new 0 0 720 250"}} xmlSpace="preserve">
                        <image width="720" height="250" xlinkHref="img/car-exterior-diagram-image-1.svg"
                               transform="matrix(1 0 0 1 0 0)"/>

                        <ExteriorModal
                            number={1}
                            title="Bara Fata"
                            onChange={baraFata => onChange({...value, baraFata})}
                            value={value.baraFata}
                        >
                            <g className="car-label">
                                <circle cx="168" cy="211.1" r="22"/>
                                <text transform="matrix(1 0 0 1 167.9834 216.1417)">1</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={2}
                            title="Grila fata"
                            onChange={grilaFata => onChange({...value, grilaFata})}
                            value={value.grilaFata}
                        >
                            <g className="car-label">
                                <circle cx="168" cy="160.2" r="22"/>
                                <text transform="matrix(1 0 0 1 167.9834 165.2606)">2</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={3}
                            title="Capota fata"
                            onChange={capotaFata => onChange({...value, capotaFata})}
                            value={value.capotaFata}
                        >
                            <g className="car-label">
                                <circle cx="168" cy="108.5" r="22"/>
                                <text transform="matrix(1 0 0 1 167.9834 113.4987)">3</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={4}
                            title="Far dreapta"
                            onChange={farDreapta => onChange({...value, farDreapta})}
                            value={value.farDreapta}
                        >
                            <g className="car-label">
                                <circle cx="74" cy="131" r="22"/>
                                <text transform="matrix(1 0 0 1 73.9834 136.1417)">4</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={5}
                            title="Far stanga"
                            onChange={farStanga => onChange({...value, farStanga})}
                            value={value.farStanga}
                        >
                            <g className="car-label">
                                <circle cx="264" cy="131" r="22"/>
                                <text transform="matrix(1 0 0 1 263.9834 136.1417)">5</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={6}
                            title="Parbriz"
                            onChange={parbriz => onChange({...value, parbriz})}
                            value={value.parbriz}
                        >
                            <g className="car-label">
                                <circle cx="168" cy="54" r="22"/>
                                <text transform="matrix(1 0 0 1 167.9834 58.9987)">6</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={15}
                            title="Bara spate"
                            onChange={baraSpate => onChange({...value, baraSpate})}
                            value={value.baraSpate}
                        >
                            <g className="car-label">
                                <circle cx="550" cy="181" r="22"/>
                                <text transform="matrix(1 0 0 1 548.7021 186.2606)">15</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={16}
                            title="Capota spate"
                            onChange={capotaSpate => onChange({...value, capotaSpate})}
                            value={value.capotaSpate}
                        >
                            <g className="car-label">
                                <circle cx="550" cy="102" r="22"/>
                                <text transform="matrix(1 0 0 1 548.7021 108.2606)">16</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={17}
                            title="Luneta"
                            onChange={luneta => onChange({...value, luneta})}
                            value={value.luneta}
                        >
                            <g className="car-label">
                                <circle cx="550" cy="38" r="22"/>
                                <text transform="matrix(1 0 0 1 548.7021 43.2606)">17</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={18}
                            title="Stop stanga"
                            onChange={stopStanga => onChange({...value, stopStanga})}
                            value={value.stopStanga}
                        >
                            <g className="car-label">
                                <circle cx="451" cy="103" r="22"/>
                                <text transform="matrix(1 0 0 1 449.7021 108.2606)">18</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={19}
                            title="Stop dreapta"
                            onChange={stopDreapta => onChange({...value, stopDreapta})}
                            value={value.stopDreapta}
                        >
                            <g className="car-label">
                                <circle cx="651" cy="103" r="22"/>
                                <text transform="matrix(1 0 0 1 649.7021 108.2606)">19</text>
                            </g>
                        </ExteriorModal>
                    </svg>

                    <div className="info-content info-content-exterior-1">
                        <ul>
                            <ExteriorModal
                                number={1}
                                title="Bara Fata"
                                onChange={baraFata => onChange({...value, baraFata})}
                                value={value.baraFata}
                            >
                                <li><span>1</span><strong>Bara fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={2}
                                title="Grila fata"
                                onChange={grilaFata => onChange({...value, grilaFata})}
                                value={value.grilaFata}
                            >
                                <li><span>2</span><strong>Grila fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={3}
                                title="Capota fata"
                                onChange={capotaFata => onChange({...value, capotaFata})}
                                value={value.capotaFata}
                            >
                                <li><span>3</span><strong>Capota fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={4}
                                title="Far dreapta"
                                onChange={farDreapta => onChange({...value, farDreapta})}
                                value={value.farDreapta}
                            >
                                <li><span>4</span><strong>Far dreapta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={5}
                                title="Far stanga"
                                onChange={farStanga => onChange({...value, farStanga})}
                                value={value.farStanga}
                            >
                                <li><span>5</span><strong>Far stanga</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={6}
                                title="Parbriz"
                                onChange={parbriz => onChange({...value, parbriz})}
                                value={value.parbriz}
                            >
                                <li><span>6</span><strong>Parbriz</strong></li>
                            </ExteriorModal>
                        </ul>
                        <ul>
                            <ExteriorModal
                                number={15}
                                title="Bara spate"
                                onChange={baraSpate => onChange({...value, baraSpate})}
                                value={value.baraSpate}
                            >
                                <li><span>15</span><strong>Bara spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={16}
                                title="Capota spate"
                                onChange={capotaSpate => onChange({...value, capotaSpate})}
                                value={value.capotaSpate}
                            >
                                <li><span>16</span><strong>Capota spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={17}
                                title="Luneta"
                                onChange={luneta => onChange({...value, luneta})}
                                value={value.luneta}
                            >
                                <li><span>17</span><strong>Luneta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={18}
                                title="Stop stanga"
                                onChange={stopStanga => onChange({...value, stopStanga})}
                                value={value.stopStanga}
                            >
                                <li><span>18</span><strong>Stop stanga</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={19}
                                title="Stop dreapta"
                                onChange={stopDreapta => onChange({...value, stopDreapta})}
                                value={value.stopDreapta}
                            >
                                <li><span>19</span><strong>Stop dreapta</strong></li>
                            </ExteriorModal>
                        </ul>
                    </div>

                    <i onClick={() => toggleGroup('group0')} className="info icon-ia-info info-exterior-1"/>
                </div>

                <div className={`exterior-car-diagram ${state.group1 === 0 ? "" : "view-info-content"}`}>
                    <svg viewBox="0 0 720 250" style={{enableBackground: "new 0 0 720 250"}} xmlSpace="preserve">
                        <image width="720" height="250" xlinkHref="img/car-exterior-diagram-image-2.svg"
                               transform="matrix(1 0 0 1 0 0)"/>

                        <ExteriorModal
                            number={1}
                            title="Bara Fata Stanga"
                            onChange={baraFataStanga => onChange({...value, baraFataStanga})}
                            value={value.baraFataStanga}
                        >
                            <g className="car-label">
                                <circle cx="46" cy="196" r="22"/>
                                <text id="_x31_" transform="matrix(1 0 0 1 45.9834 201.2606)">1</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={7}
                            title="Aripa stanga fata"
                            onChange={aripaStangaFata => onChange({...value, aripaStangaFata})}
                            value={value.aripaStangaFata}
                        >
                            <g className="car-label">
                                <circle cx="176" cy="127" r="22"/>
                                <text id="_x37_" transform="matrix(1 0 0 1 175.9834 132.2606)">7</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={8}
                            title="Janta stanga fata"
                            onChange={jantaStangaFata => onChange({...value, jantaStangaFata})}
                            value={value.jantaStangaFata}
                        >
                            <g className="car-label">
                                <circle cx="147" cy="196" r="22"/>
                                <text id="_x38_" transform="matrix(1 0 0 1 146.9834 201.2606)">8</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={9}
                            title="Oglinda stanga fata"
                            onChange={oglindaStangaFata => onChange({...value, oglindaStangaFata})}
                            value={value.oglindaStangaFata}
                        >
                            <g className="car-label">
                                <circle cx="259" cy="103" r="22"/>
                                <text id="_x39_" transform="matrix(1 0 0 1 258.9834 108.2606)">9</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={10}
                            title="Portiera stanga fata"
                            onChange={portieraStangaFata => onChange({...value, portieraStangaFata})}
                            value={value.portieraStangaFata}
                        >
                            <g className="car-label">
                                <circle cx="307" cy="158" r="22"/>
                                <text id="_x31_0" transform="matrix(1 0 0 1 306.7021 163.2606)">10</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={11}
                            title="Portiera stanga spate"
                            onChange={portieraStangaSpate => onChange({...value, portieraStangaSpate})}
                            value={value.portieraStangaSpate}
                        >
                            <g className="car-label">
                                <circle cx="452" cy="158" r="22"/>
                                <text id="_x31_1" transform="matrix(1 0 0 1 451.7021 163.2606)">11</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={12}
                            title="Prag stanga"
                            onChange={pragaStanga => onChange({...value, pragaStanga})}
                            value={value.pragaStanga}
                        >
                            <g className="car-label">
                                <circle cx="380" cy="208" r="22"/>
                                <text id="_x31_2" transform="matrix(1 0 0 1 379.7021 213.2606)">12</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={13}
                            title="Janta stanga spate"
                            onChange={jantaStangaSpate => onChange({...value, jantaStangaSpate})}
                            value={value.jantaStangaSpate}
                        >
                            <g className="car-label">
                                <circle cx="585" cy="196" r="22"/>
                                <text id="_x31_3" transform="matrix(1 0 0 1 584.7021 201.2606)">13</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={14}
                            title="Aripa stanga spate"
                            onChange={aripaStangaSpate => onChange({...value, aripaStangaSpate})}
                            value={value.aripaStangaSpate}
                        >
                            <g className="car-label">
                                <circle cx="623" cy="119" r="22"/>
                                <text id="_x31_4" transform="matrix(1 0 0 1 621.7021 124.2606)">14</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={15}
                            title="Bara spate stanga"
                            onChange={baraSpateStanga => onChange({...value, baraSpateStanga})}
                            value={value.baraSpateStanga}
                        >
                            <g className="car-label">
                                <circle cx="678" cy="158" r="22"/>
                                <text id="_x31_5" transform="matrix(1 0 0 1 676.7021 163.2606)">15</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={25}
                            title="Plafon Stanga"
                            onChange={plafonStanga => onChange({...value, plafonStanga})}
                            value={value.plafonStanga}
                        >
                            <g className="car-label">
                                <circle cx="420" cy="23" r="22"/>
                                <text id="_x32_5" transform="matrix(1 0 0 1 419.7021 28.2606)">25</text>
                            </g>
                        </ExteriorModal>
                    </svg>

                    <div className="info-content info-content-exterior-2">
                        <ul>
                            <ExteriorModal
                                number={1}
                                title="Bara Fata Stanga"
                                onChange={baraFataStanga => onChange({...value, baraFataStanga})}
                                value={value.baraFataStanga}
                            >
                                <li><span>1</span><strong>Bara fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={7}
                                title="Aripa stanga fata"
                                onChange={aripaStangaFata => onChange({...value, aripaStangaFata})}
                                value={value.aripaStangaFata}
                            >
                                <li><span>7</span><strong>Aripa stanga fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={8}
                                title="Janta stanga fata"
                                onChange={jantaStangaFata => onChange({...value, jantaStangaFata})}
                                value={value.jantaStangaFata}
                            >
                                <li><span>8</span><strong>Janta stanga fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={9}
                                title="Oglinda stanga fata"
                                onChange={oglindaStangaFata => onChange({...value, oglindaStangaFata})}
                                value={value.oglindaStangaFata}
                            >
                                <li><span>9</span><strong>Oglinda stanga fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={10}
                                title="Portiera stanga fata"
                                onChange={portieraStangaFata => onChange({...value, portieraStangaFata})}
                                value={value.portieraStangaFata}
                            >
                                <li><span>10</span><strong>Portiera stanga fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={11}
                                title="Portiera stanga spate"
                                onChange={portieraStangaSpate => onChange({...value, portieraStangaSpate})}
                                value={value.portieraStangaSpate}
                            >
                                <li><span>11</span><strong>Portiera stanga spate</strong></li>
                            </ExteriorModal>
                        </ul>
                        <ul>
                            <ExteriorModal
                                number={12}
                                title="Prag stanga"
                                onChange={pragaStanga => onChange({...value, pragaStanga})}
                                value={value.pragaStanga}
                            >
                                <li><span>12</span><strong>Prag stanga</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={13}
                                title="Janta stanga spate"
                                onChange={jantaStangaSpate => onChange({...value, jantaStangaSpate})}
                                value={value.jantaStangaSpate}
                            >
                                <li><span>13</span><strong>Janta stanga spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={14}
                                title="Aripa stanga spate"
                                onChange={aripaStangaSpate => onChange({...value, aripaStangaSpate})}
                                value={value.aripaStangaSpate}
                            >
                                <li><span>14</span><strong>Aripa stanga spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={15}
                                title="Bara spate stanga"
                                onChange={baraSpateStanga => onChange({...value, baraSpateStanga})}
                                value={value.baraSpateStanga}
                            >
                                <li><span>15</span><strong>Bara spate stanga</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={25}
                                title="Plafon Stanga"
                                onChange={plafonStanga => onChange({...value, plafonStanga})}
                                value={value.plafonStanga}
                            >
                                <li><span>25</span><strong>Plafon Stanga</strong></li>
                            </ExteriorModal>
                        </ul>
                    </div>
                    <i onClick={() => toggleGroup('group1')} className="info icon-ia-info info-exterior-2"/>
                </div>

                <div className={`exterior-car-diagram ${state.group2 === 0 ? "" : "view-info-content"}`}>
                    <svg viewBox="0 0 720 250" style={{enableBackground: "new 0 0 720 250"}} xmlSpace="preserve">
                        <image width="720" height="250" xlinkHref="img/car-exterior-diagram-image-3.svg"
                               transform="matrix(0.9999 0 0 0.9999 0 0)"/>
                        <ExteriorModal
                            number={1}
                            title="Bara Fata Dreapta"
                            onChange={baraFataDreapta => onChange({...value, baraFataDreapta})}
                            value={value.baraFataDreapta}
                        >
                            <g className="car-label">
                                <circle cx="674" cy="196" r="22"/>
                                <text id="_x31__1_" transform="matrix(1 0 0 1 672.9834 201.2606)">1</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={15}
                            title="Bara spate dreapta"
                            onChange={baraSpateDreapta => onChange({...value, baraSpateDreapta})}
                            value={value.baraSpateDreapta}
                        >
                            <g className="car-label">
                                <circle cx="42" cy="158" r="22"/>
                                <text id="_x31_5" transform="matrix(1 0 0 1 41.438 163.2606)">15</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={20}
                            title="Aripa dreapta spate"
                            onChange={aripaDreaptaSpate => onChange({...value, aripaDreaptaSpate})}
                            value={value.aripaDreaptaSpate}
                        >
                            <g className="car-label">
                                <circle cx="97" cy="119" r="22"/>
                                <text id="_x32_0" transform="matrix(1 0 0 1 96.7021 124.2606)">20</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={21}
                            title="Janta dreapta spate"
                            onChange={jantaDreaptaSpate => onChange({...value, jantaDreaptaSpate})}
                            value={value.jantaDreaptaSpate}
                        >
                            <g className="car-label">
                                <circle cx="135" cy="196" r="22"/>
                                <text id="_x32_1" transform="matrix(1 0 0 1 135.7021 201.2606)">21</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={22}
                            title="Portiera dreapta spate"
                            onChange={portieraDreapta => onChange({...value, portieraDreapta})}
                            value={value.portieraDreapta}
                        >
                            <g className="car-label">
                                <circle cx="269" cy="158" r="22"/>
                                <text id="_x32_2" transform="matrix(1 0 0 1 268.7021 163.2606)">22</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={22}
                            title="Portiera dreapta spate"
                            onChange={portieraDreapta => onChange({...value, portieraDreapta})}
                            value={value.portieraDreapta}
                        >
                            <g className="car-label">
                                <circle cx="269" cy="158" r="22"/>
                                <text id="_x32_2" transform="matrix(1 0 0 1 268.7021 163.2606)">22</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={23}
                            title="Portiera dreapta fata"
                            onChange={portieraDreaptaFata => onChange({...value, portieraDreaptaFata})}
                            value={value.portieraDreaptaFata}
                        >
                            <g className="car-label">
                                <circle cx="413" cy="158" r="22"/>
                                <text id="_x32_3" transform="matrix(1 0 0 1 412.7021 163.2606)">23</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={24}
                            title="Prag dreapta"
                            onChange={pragDreapta => onChange({...value, pragDreapta})}
                            value={value.pragDreapta}
                        >
                            <g className="car-label">
                                <circle cx="340" cy="208" r="22"/>
                                <text id="_x32_4" transform="matrix(1 0 0 1 339.7021 213.2606)">24</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={25}
                            title="Plafon Dreapta"
                            onChange={plafonDreapta => onChange({...value, plafonDreapta})}
                            value={value.plafonDreapta}
                        >
                            <g className="car-label">
                                <circle cx="300" cy="23" r="22"/>
                                <text id="_x32_5" transform="matrix(1 0 0 1 299.7021 28.2606)">25</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={26}
                            title="Oglinda dreapta"
                            onChange={oglindaDreapta => onChange({...value, oglindaDreapta})}
                            value={value.oglindaDreapta}
                        >
                            <g className="car-label">
                                <circle cx="461" cy="103" r="22"/>
                                <text id="_x32_6" transform="matrix(1 0 0 1 460.7021 108.2606)">26</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={27}
                            title="Janta dreapta fata"
                            onChange={jantaDreaptaFata => onChange({...value, jantaDreaptaFata})}
                            value={value.jantaDreaptaFata}
                        >
                            <g className="car-label">
                                <circle cx="573" cy="196" r="22"/>
                                <text id="_x32_7" transform="matrix(1 0 0 1 572.7021 201.2606)">27</text>
                            </g>
                        </ExteriorModal>

                        <ExteriorModal
                            number={27}
                            title="Aripa dreapta fata"
                            onChange={aripaDreaptaFata => onChange({...value, aripaDreaptaFata})}
                            value={value.aripaDreaptaFata}
                        >
                            <g className="car-label">
                                <circle cx="544" cy="127" r="22"/>
                                <text id="_x32_7_1_" transform="matrix(1 0 0 1 543.7021 132.2606)">28</text>
                            </g>
                        </ExteriorModal>
                    </svg>
                    <div className="info-content info-content-exterior-3">
                        <ul>
                            <ExteriorModal
                                number={1}
                                title="Bara Fata Dreapta"
                                onChange={baraFataDreapta => onChange({...value, baraFataDreapta})}
                                value={value.baraFataDreapta}
                            >
                                <li><span>1</span><strong>Bara fata dreapta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={15}
                                title="Bara spate dreapta"
                                onChange={baraSpateDreapta => onChange({...value, baraSpateDreapta})}
                                value={value.baraSpateDreapta}
                            >
                                <li><span>15</span><strong>Bara spate dreapta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={20}
                                title="Aripa dreapta spate"
                                onChange={aripaDreaptaSpate => onChange({...value, aripaDreaptaSpate})}
                                value={value.aripaDreaptaSpate}
                            >
                                <li><span>20</span><strong>Aripa dreapta spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={21}
                                title="Janta dreapta spate"
                                onChange={jantaDreaptaSpate => onChange({...value, jantaDreaptaSpate})}
                                value={value.jantaDreaptaSpate}
                            >
                                <li><span>21</span><strong>Janta dreapta spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={22}
                                title="Portiera dreapta spate"
                                onChange={portieraDreapta => onChange({...value, portieraDreapta})}
                                value={value.portieraDreapta}
                            >
                                <li><span>22</span><strong>Portiera dreapta spate</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={23}
                                title="Portiera dreapta fata"
                                onChange={portieraDreaptaFata => onChange({...value, portieraDreaptaFata})}
                                value={value.portieraDreaptaFata}
                            >
                                <li><span>23</span><strong>Portiera dreapta fata</strong></li>
                            </ExteriorModal>
                        </ul>
                        <ul>
                            <ExteriorModal
                                number={24}
                                title="Prag dreapta"
                                onChange={pragDreapta => onChange({...value, pragDreapta})}
                                value={value.pragDreapta}
                            >
                                <li><span>24</span><strong>Prag dreapta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={25}
                                title="Plafon Dreapta"
                                onChange={plafonDreapta => onChange({...value, plafonDreapta})}
                                value={value.plafonDreapta}
                            >
                                <li><span>25</span><strong>Plafon Dreapta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={26}
                                title="Oglinda dreapta"
                                onChange={oglindaDreapta => onChange({...value, oglindaDreapta})}
                                value={value.oglindaDreapta}
                            >
                                <li><span>26</span><strong>Oglinda dreapta</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={27}
                                title="Janta dreapta fata"
                                onChange={jantaDreaptaFata => onChange({...value, jantaDreaptaFata})}
                                value={value.jantaDreaptaFata}
                            >
                                <li><span>27</span><strong>Janta dreapta fata</strong></li>
                            </ExteriorModal>

                            <ExteriorModal
                                number={28}
                                title="Aripa dreapta fata"
                                onChange={aripaDreaptaFata => onChange({...value, aripaDreaptaFata})}
                                value={value.aripaDreaptaFata}
                            >
                                <li><span>28</span><strong>Aripa dreapta fata</strong></li>
                            </ExteriorModal>
                        </ul>
                    </div>
                    <i onClick={() => toggleGroup('group2')} className="info icon-ia-info info-exterior-2"/>
                </div>

                <div className="fieldset-row">
                    <div className="switcher">
                        <input
                            id="ext-s1"
                            type="checkbox"
                            checked={value.stalpi.checked}
                            onChange={({target: {checked}}) => {
                                onChange({...value, stalpi: {...value.stalpi, checked}});
                                if (checked) {
                                    document.getElementById("ext-s1").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-s1">Stalpi / Montanti</label>
                        <div id="switcher-expand-box-6" className="fieldset-row switcher-expand-box"
                             style={{display: value.stalpi.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.stalpi.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        stalpi: {...value.stalpi, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-s2"
                            type="checkbox"
                            checked={value.lonjeroane.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    lonjeroane: {...value.lonjeroane, checked}

                                });
                                if (checked) {
                                    document.getElementById("ext-s2").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }
                            }
                        />
                        <label htmlFor="ext-s2">Lonjeroane</label>
                        <div id="switcher-expand-box-7" className="fieldset-row switcher-expand-box"
                             style={{display: value.lonjeroane.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.lonjeroane.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        lonjeroane: {...value.lonjeroane, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="sw-more-outside"
                            type="checkbox"
                            checked={value.observatii.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    observatii: {...value.observatii, checked}
                                });
                                if (checked) {
                                    document.getElementById("sw-more-outside").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="sw-more-outside">Alte avarii / Observatii</label>
                        <div id="switcher-expand-box-1" className="fieldset-row switcher-expand-box"
                             style={{display: value.observatii.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.observatii.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        observatii: {...value.observatii, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
};

const ExteriorModal = ({
                           children,
                           number = "",
                           title = "",
                           onChange = f => f,
                           value
                       }) => {
    const [open, setOpen] = React.useState(false);
    const [localState, setLoocalState] = React.useState(value);
    const [state, setState] = React.useState({
        errors: {}
    })


    const changed = !isEqual(value, exteriorModal);
    const localChanged = !isEqual(localState, exteriorModal);

    const onSave = () => {
        setState(state => ({...state, errors: {}}))
        if (localChanged) {
            const errors = {}
            
            if (isNaN(localState.cost) || localState.cost <= 0) {
                errors.cost = true
                setState(state => ({...state, errors}))
                // message.warn('E necesar sa adaugi un numar.');
                return
            }

            if (!localState.zgariat && !localState.crapat && !localState.indoit && !localState.ruginit) {
                errors.cost = true
                setState(state => ({...state, errors}))
                // message.warn("Toate datele sunt obligatorii.");
                return
            }
        }
        onChange(localState);
        setOpen(false)
    };

    const onCancel = () => {
        setLoocalState(value);
        setOpen(false)
    };

    const onCheckClick = name => {
        setLoocalState(localState => ({
            ...localState,
            [name]: !localState[name]
        }));
        setLoocalState(localState => {
            if (!localState.zgariat && !localState.crapat && !localState.indoit && !localState.ruginit) {
                return {
                    ...localState,
                    cost: 0
                }
            }
            return localState
        })
    };

    return (
        <>
            {React.cloneElement(
                children,
                {
                    onClick: () => {
                        setLoocalState(value);
                        setOpen(true);
                    },
                    className: `${children.props.className} ${changed ? "active" : ""}`
                }
            )}
            <Modal
                visible={open}
                onCancel={onCancel}
            >
                <div className="cld-header">
                    <strong className={`${localChanged ? "active" : ""}`}>{number}</strong>
                    <span>{title}</span>
                </div>
                <div className="cld-body">
                    <ul className="cld-list">
                        <li onClick={() => onCheckClick("zgariat")}>
                            <div className={`car-list-detail-button ${localState.zgariat ? "active" : ""}`}>
                                <strong>Z</strong>
                                <span>Zgariat</span>
                            </div>
                        </li>
                        <li onClick={() => onCheckClick("crapat")}>
                            <div className={`car-list-detail-button ${localState.crapat ? "active" : ""}`}>
                                <strong>C</strong>
                                <span>Crapat</span>
                            </div>
                        </li>
                        <li onClick={() => onCheckClick("indoit")}>
                            <div className={`car-list-detail-button ${localState.indoit ? "active" : ""}`}>
                                <strong>I</strong>
                                <span>Indoit</span>
                            </div>
                        </li>
                        <li onClick={() => onCheckClick("ruginit")}>
                            <div className={`car-list-detail-button ${localState.ruginit ? "active" : ""}`}>
                                <strong>R</strong>
                                <span>Ruginit</span>
                            </div>
                        </li>
                    </ul>
                    <div className="form">
                        <div className="fieldset-row">
                            <div className={`form-row ${state.errors.cost ? 'error' : ''}`}>
                                <label htmlFor="">Cost</label>
                                <input type="number" value={localState.cost} min={0}
                                       onChange={({target: {value: newValue}}) => setLoocalState({
                                           ...localState,
                                           cost: parseInt(newValue)
                                       })}/>
                            </div>
                        </div>
                        <button className="button" type="button" onClick={onSave}>Salveaza</button>
                    </div>
                </div>
            </Modal>
        </>
    )
};

const Interior = ({
                      collapsed = true,
                      onOpenClick = f => f,
                      value,
                      onChange
                  }) => {
    const [state, setState] = React.useState({
        mode: 0
    });
    const toggleGroup = () => {
        setState(state => ({...state, mode: state.mode === 0 ? 1 : 0}))
    };
    return (
        <div className={`exp-row ${collapsed ? "" : "expand"}`} id="inside-car-row">
            <div className="exp-row-header" onClick={onOpenClick}>
                <h3><i className="exp-icon icon-ia-interior"/>Interior</h3>
                <span className="exp-row-arrow icon-ia-arrow"/>
            </div>
            <div className="exp-row-body">
                <div className={`interior-car-diagram ${state.mode === 0 ? "" : "view-info-content"}`}>
                    <svg viewBox="0 0 720 450" style={{enableBackground: "new 0 0 720 450"}} xmlSpace="preserve">
                        <image width="720" height="450" xlinkHref="img/car-interior-diagram-image.svg"
                               transform="matrix(0.9999 0 0 0.9999 0 0)"/>
                        <InteriorModal
                            number={1}
                            title="Pedale"
                            onChange={pedale => onChange({...value, pedale})}
                            value={value.pedale}

                        >
                            <g className="car-label">
                                <circle cx="116" cy="366" r="22"/>
                                <text id="_x31__0_" transform="matrix(1 0 0 1 115.9834 371.2606)">1</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={2}
                            title="Volan"
                            onChange={volan => onChange({...value, volan})}
                            value={value.volan}

                        >
                            <g className="car-label">
                                <circle cx="193" cy="292" r="22"/>
                                <text id="_x32_" transform="matrix(1 0 0 1 192.9834 297.2606)">2</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={3}
                            title="Schimbator viteze"
                            onChange={schimbatorViteze => onChange({...value, schimbatorViteze})}
                            value={value.schimbatorViteze}

                        >
                            <g className="car-label">
                                <circle cx="258" cy="244" r="22"/>
                                <text id="_x32__1_" transform="matrix(1 0 0 1 257.9834 249.2606)">3</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={4}
                            title="Butoane plansa bord"
                            onChange={butoanePlansaBord => onChange({...value, butoanePlansaBord})}
                            value={value.butoanePlansaBord}

                        >
                            <g className="car-label">
                                <circle cx="142" cy="210" r="22"/>
                                <text id="_x32__2_" transform="matrix(1 0 0 1 141.9834 215.2606)">4</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={5}
                            title="Butoane multimedia"
                            onChange={butoaneMultimedia => onChange({...value, butoaneMultimedia})}
                            value={value.butoaneMultimedia}

                        >
                            <g className="car-label">
                                <circle cx="180" cy="175" r="22"/>
                                <text id="_x32__3_" transform="matrix(1 0 0 1 179.9834 180.2606)">5</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={6}
                            title="Butoane portiere / geamuri"
                            onChange={butoanePortiere => onChange({...value, butoanePortiere})}
                            value={value.butoanePortiere}
                        >
                            <g className="car-label">
                                <circle cx="269" cy="133" r="22"/>
                                <text id="_x32__4_" transform="matrix(1 0 0 1 268.9834 138.2606)">6</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={7}
                            title="Cotiera"
                            onChange={cotiera => onChange({...value, cotiera})}
                            value={value.cotiera}
                        >
                            <g className="car-label">
                                <circle cx="362" cy="225" r="22"/>
                                <text id="_x32__5_" transform="matrix(1 0 0 1 361.9834 230.2606)">7</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={8}
                            title="Plansa bord (incl .torpedou)"
                            onChange={plansaBord => onChange({...value, plansaBord})}
                            value={value.plansaBord}
                        >
                            <g className="car-label">
                                <circle cx="121" cy="119" r="22"/>
                                <text id="_x32__6_" transform="matrix(1 0 0 1 120.9834 124.2606)">8</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={9}
                            title="Plafon"
                            onChange={plafon => onChange({...value, plafon})}
                            value={value.plafon}
                        >
                            <g className="car-label">
                                <circle cx="499" cy="23" r="22"/>
                                <text id="_x32__7_" transform="matrix(1 0 0 1 498.9834 28.2606)">9</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={10}
                            title="Scaun sofer"
                            onChange={scaunSofer => onChange({...value, scaunSofer})}
                            value={value.scaunSofer}
                        >
                            <g className="car-label">
                                <circle cx="458" cy="272" r="22"/>
                                <text id="_x32__8_" transform="matrix(1 0 0 1 457.7021 277.2606)">10</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={11}
                            title="Scaun pasager"
                            onChange={scaunPasager => onChange({...value, scaunPasager})}
                            value={value.scaunPasager}
                        >
                            <g className="car-label">
                                <circle cx="425" cy="158" r="22"/>
                                <text id="_x32__9_" transform="matrix(1 0 0 1 424.7021 163.2606)">11</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={12}
                            title="Bancheta spate"
                            onChange={banchetaSpate => onChange({...value, banchetaSpate})}
                            value={value.banchetaSpate}
                        >
                            <g className="car-label">
                                <circle cx="690" cy="244" r="22"/>
                                <text id="_x32__10_" transform="matrix(1 0 0 1 689.7021 249.2606)">12</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={13}
                            title="Centuri siguranta"
                            onChange={centuriSiguranta => onChange({...value, centuriSiguranta})}
                            value={value.centuriSiguranta}
                        >
                            <g className="car-label">
                                <circle cx="564" cy="257" r="22"/>
                                <text id="_x32__11_" transform="matrix(1 0 0 1 563.7021 262.2606)">13</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={13}
                            title="Centuri siguranta"
                            onChange={centuriSiguranta => onChange({...value, centuriSiguranta})}
                            value={value.centuriSiguranta}
                        >
                            <g className="car-label">
                                <circle cx="372" cy="60" r="22"/>
                                <text id="_x32__12_" transform="matrix(1 0 0 1 371.7021 65.2606)">13</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={14}
                            title="Capitonaje usi"
                            onChange={capitonajeUsi => onChange({...value, capitonajeUsi})}
                            value={value.capitonajeUsi}
                        >
                            <g className="car-label">
                                <circle cx="472" cy="119" r="22"/>
                                <text id="_x32__13_" transform="matrix(1 0 0 1 471.7021 124.2606)">14</text>
                            </g>
                        </InteriorModal>

                        <InteriorModal
                            number={15}
                            title="Covorase"
                            onChange={covorase => onChange({...value, covorase})}
                            value={value.covorase}
                        >
                            <g className="car-label">
                                <circle cx="423" cy="380" r="22"/>
                                <text id="_x32__14_" transform="matrix(1 0 0 1 422.7021 385.2606)">15</text>
                            </g>
                        </InteriorModal>

                        {/* <InteriorModal
                                number={15}
                                title="Covorase"
                                onChange={covorase => onChange({...value, covorase})}
                                value={value.covorase}
                                >
                                <g className="car-label">
                                <circle cx="171" cy="380" r="22" />
                                <text id="_x32__15_" transform="matrix(1 0 0 1 170.7021 385.2606)">15</text>
                                </g>
                                </InteriorModal> */}
                    </svg>
                    <div className="info-content info-content-interior">
                        <ul>
                            <InteriorModal
                                number={1}
                                title="Pedale"
                                onChange={pedale => onChange({...value, pedale})}
                                value={value.pedale}

                            >
                                <li><span>1</span><strong>Pedale</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={2}
                                title="Volan"
                                onChange={volan => onChange({...value, volan})}
                                value={value.volan}

                            >
                                <li><span>2</span><strong>Volan</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={3}
                                title="Schimbator viteze"
                                onChange={schimbatorViteze => onChange({...value, schimbatorViteze})}
                                value={value.schimbatorViteze}

                            >
                                <li><span>3</span><strong>Schimbator viteze</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={4}
                                title="Butoane plansa bord"
                                onChange={butoanePlansaBord => onChange({...value, butoanePlansaBord})}
                                value={value.butoanePlansaBord}

                            >
                                <li><span>4</span><strong>Butoane plansa bord</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={5}
                                title="Butoane multimedia"
                                onChange={butoaneMultimedia => onChange({...value, butoaneMultimedia})}
                                value={value.butoaneMultimedia}

                            >
                                <li><span>5</span><strong>Butoane multimedia</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={6}
                                title="Butoane portiere / geamuri"
                                onChange={butoanePortiere => onChange({...value, butoanePortiere})}
                                value={value.butoanePortiere}
                            >
                                <li><span>6</span><strong>Butoane portiere / geamuri</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={7}
                                title="Cotiera"
                                onChange={cotiera => onChange({...value, cotiera})}
                                value={value.cotiera}
                            >
                                <li><span>7</span><strong>Cotiera</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={8}
                                title="Plansa bord (incl .torpedou)"
                                onChange={plansaBord => onChange({...value, plansaBord})}
                                value={value.plansaBord}
                            >
                                <li><span>8</span><strong>Plansa bord (incl .torpedou)</strong></li>
                            </InteriorModal>
                        </ul>
                        <ul>
                            <InteriorModal
                                number={9}
                                title="Plafon"
                                onChange={plafon => onChange({...value, plafon})}
                                value={value.plafon}
                            >
                                <li><span>9</span><strong>Plafon</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={10}
                                title="Scaun sofer"
                                onChange={scaunSofer => onChange({...value, scaunSofer})}
                                value={value.scaunSofer}
                            >
                                <li><span>10</span><strong>Scaun sofer</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={11}
                                title="Scaun pasager"
                                onChange={scaunPasager => onChange({...value, scaunPasager})}
                                value={value.scaunPasager}
                            >
                                <li><span>11</span><strong>Scaun pasager</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={12}
                                title="Bancheta spate"
                                onChange={banchetaSpate => onChange({...value, banchetaSpate})}
                                value={value.banchetaSpate}
                            >
                                <li><span>12</span><strong>Bancheta spate</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={13}
                                title="Centuri siguranta"
                                onChange={centuriSiguranta => onChange({...value, centuriSiguranta})}
                                value={value.centuriSiguranta}
                            >
                                <li><span>13</span><strong>Centuri siguranta</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={14}
                                title="Capitonaje usi"
                                onChange={capitonajeUsi => onChange({...value, capitonajeUsi})}
                                value={value.capitonajeUsi}
                            >
                                <li><span>14</span><strong>Capitonaje usi</strong></li>
                            </InteriorModal>

                            <InteriorModal
                                number={15}
                                title="Covorase"
                                onChange={covorase => onChange({...value, covorase})}
                                value={value.covorase}
                            >
                                <li><span>15</span><strong>Covorase</strong></li>
                            </InteriorModal>
                        </ul>
                    </div>
                    <i onClick={toggleGroup} className="info icon-ia-info info-interior"/>
                </div>

                <div className="fieldset-row">
                    <div className="switcher">
                        <input
                            id="ext-s22"
                            type="checkbox"
                            checked={value.mirosNeplacut.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    mirosNeplacut: {...value.mirosNeplacut, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-s22").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }
                            }
                        />
                        <label htmlFor="ext-s22">Miros neplacut</label>
                        <div id="switcher-expand-box-8" className="fieldset-row switcher-expand-box"
                             style={{display: value.mirosNeplacut.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.mirosNeplacut.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        mirosNeplacut: {...value.mirosNeplacut, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-s23"
                            type="checkbox"
                            value={value.podea.checked}
                            onChange={({target: {checked}}) => {
                                onChange({...value, podea: {...value.podea, checked}});
                                if (checked) {
                                    document.getElementById("ext-s23").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-s23">Podea / Tapiserie cu urme de umezeala</label>
                        <div id="switcher-expand-box-9" className="fieldset-row switcher-expand-box"
                             style={{display: value.podea.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.podea.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        podea: {...value.podea, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="sw-more-inside-1"
                            type="checkbox"
                            checked={value.tapiserie.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    tapiserie: {...value.tapiserie, checked}
                                });
                                if (checked) {
                                    document.getElementById("sw-more-inside-1").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="sw-more-inside-1">Stare generala tapiserie</label>
                        <div id="switcher-expand-box-5" className="fieldset-row switcher-expand-box"
                             style={{display: value.tapiserie.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.tapiserie.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        tapiserie: {...value.tapiserie, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="sw-more-inside"
                            type="checkbox"
                            name="switcher2"
                            checked={value.observatii.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    observatii: {...value.observatii, checked}
                                });
                                if (checked) {
                                    document.getElementById("sw-more-inside").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="sw-more-inside">Alte avarii / Observatii</label>
                        <div id="switcher-expand-box-2" className="fieldset-row switcher-expand-box"
                             style={{display: value.observatii.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.observatii.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        observatii: {...value.observatii, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
const InteriorModal = ({
                           children,
                           number = "",
                           title = "",
                           onChange = f => f,
                           value
                       }) => {
    const [open, setOpen] = React.useState(false);
    const [localState, setLoocalState] = React.useState(value);
    const [state, setState] = React.useState({
        errors: {}
    })

    const changed = !isEqual(value, interiorModal);
    const localChanged = !isEqual(localState, interiorModal);

    const onSave = () => {
        setState(state => ({...state, errors: {}}))
        if (localChanged) {
            const errors = {}
            if (isNaN(localState.cost) || localState.cost <= 0) {
                // message.warn('E necesar sa adaugi un numar.');
                errors.cost = true
                setState(state => ({...state, errors}))
                return
            }

            if (!localState.zgariat && !localState.patat && !localState.rupt && !localState.foarte) {
                errors.cost = true
                setState(state => ({...state, errors}))
                // message.warn("Toate datele sunt obligatorii.");
                return
            }
        }
        onChange(localState);
        setOpen(false)
    };

    const onCancel = () => {
        setLoocalState(value);
        setOpen(false)
    };

    const onCheckClick = name => {
        setLoocalState(localState => ({
            ...localState,
            [name]: !localState[name]
        }));
        setLoocalState(localState => {
            if (!localState.zgariat && !localState.patat && !localState.rupt && !localState.foarte) {
                return {
                    ...localState,
                    cost: 0
                }
            }
            return localState
        })
    };

    return (
        <>
            {React.cloneElement(
                children,
                {
                    onClick: () => setOpen(true),
                    className: `${children.props.className} ${changed ? "active" : ""}`
                }
            )}
            <Modal
                visible={open}
                onCancel={onCancel}
            >
                <div className="cld-header">
                    <strong className={`${localChanged ? "active" : ""}`}>{number}</strong>
                    <span>{title}</span>
                </div>
                <div className="cld-body">
                    <ul className="cld-list">
                        <li onClick={() => onCheckClick("zgariat")}>
                            <div className={`car-list-detail-button ${localState.zgariat ? "active" : ""}`}>
                                <strong>Z</strong>
                                <span>Zgariat</span>
                            </div>
                        </li>
                        <li onClick={() => onCheckClick("patat")}>
                            <div className={`car-list-detail-button ${localState.patat ? "active" : ""}`}>
                                <strong>P</strong>
                                <span>Patat</span>
                            </div>
                        </li>
                        <li onClick={() => onCheckClick("rupt")}>
                            <div className={`car-list-detail-button ${localState.rupt ? "active" : ""}`}>
                                <strong>R</strong>
                                <span>Rupt</span>
                            </div>
                        </li>
                        <li onClick={() => onCheckClick("foarte")}>
                            <div className={`car-list-detail-button ${localState.foarte ? "active" : ""}`}>
                                <strong>U</strong>
                                <span>Foarte Uzat</span>
                            </div>
                        </li>
                    </ul>
                    <div className="form">
                        <div className="fieldset-row">
                            <div className={`form-row ${state.errors.cost ? 'error' : ''}`}>
                                <label htmlFor="">Cost</label>
                                <input type="number" placeholder="0" value={localState.cost} min={0}
                                       onChange={({target: {value: newValue}}) => setLoocalState({
                                           ...localState,
                                           cost: newValue
                                       })}/>
                            </div>
                        </div>
                        <button className="button" type="button" onClick={onSave}>Salveaza</button>
                    </div>
                </div>
            </Modal>
        </>
    )
};

const Engine = ({
                    collapsed = true,
                    onOpenClick = f => f,
                    value,
                    onChange
                }) => {

    return (
        <div className={`exp-row ${collapsed ? "" : "expand"}`} id="engine-row">
            <div className="exp-row-header" onClick={onOpenClick}>
                <h3><i className="exp-icon icon-ia-engine"/>Pornire Motor</h3>
                <span className="exp-row-arrow icon-ia-arrow"/>
            </div>
            <div className="exp-row-body">
                <div className="fieldset-row">

                    <div className="switcher">
                        <input
                            id="ext-e1"
                            type="checkbox"
                            checked={value.martoriImportanti.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    martoriImportanti: {...value.martoriImportanti, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-e1").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-e1">Martori importanti aprinsi<span className="info-txt">de ex. ECU, Airbag, ABS, ulei, temperatura apa, placute frana etc</span></label>
                        <div id="switcher-expand-box-10" className="fieldset-row switcher-expand-box"
                             style={{display: value.martoriImportanti.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.martoriImportanti.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        martoriImportanti: {...value.martoriImportanti, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-e2"
                            type="checkbox"
                            checked={value.alteAlerteActive.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    alteAlerteActive: {...value.alteAlerteActive, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-e2").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-e2">Alte alerte active<span className="info-txt">de ex. becuri faruri, stopuri arse, revizie depasita etc</span></label>
                        <div id="switcher-expand-box-11" className="fieldset-row switcher-expand-box"
                             style={{display: value.alteAlerteActive.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.alteAlerteActive.text}
                                    onChange={({target: {value: text}}) => {
                                        onChange({
                                            ...value,
                                            alteAlerteActive: {...value.alteAlerteActive, text}
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-e3"
                            type="checkbox"
                            checked={value.zgomoteMotor.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    zgomoteMotor: {...value.zgomoteMotor, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-e3").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-e3">Probleme / Zgomote motor</label>
                        <div id="switcher-expand-box-12" className="fieldset-row switcher-expand-box"
                             style={{display: value.zgomoteMotor.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.zgomoteMotor.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        zgomoteMotor: {...value.zgomoteMotor, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-e4"
                            type="checkbox"
                            checked={value.zgomoteTransmisie.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    zgomoteTransmisie: {...value.zgomoteTransmisie, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-e4").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-e4">Probleme / Zgomote transmisie (cutie de viteze si diferential)</label>
                        <div id="switcher-expand-box-13" className="fieldset-row switcher-expand-box"
                             style={{display: value.zgomoteTransmisie.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.zgomoteTransmisie.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        zgomoteTransmisie: {...value.zgomoteTransmisie, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="sw-more-engine"
                            type="checkbox"
                            checked={value.observatii.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    observatii: {...value.observatii, checked}
                                });
                                if (checked) {
                                    setTimeout(()=>document.getElementById("switcher-expand-box-3").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    }), 100);
                                }
                            }}
                        />
                        <label htmlFor="sw-more-engine">Alte avarii / Observatii</label>
                        <div id="switcher-expand-box-3" className="fieldset-row switcher-expand-box"
                             style={{display: value.observatii.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.observatii.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        observatii: {...value.observatii, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};

const Accessory = ({
                       collapsed = true,
                       onOpenClick = f => f,
                       value,
                       onChange = f => f
                   }) => {
    return (
        <div className={`exp-row ${collapsed ? "" : "expand"}`} id="accessory-car-row">
            <div className="exp-row-header" onClick={onOpenClick}>
                <h3><i className="exp-icon icon-ia-accessories"/>Accesorii</h3>
                <span className="exp-row-arrow icon-ia-arrow"/>
            </div>

            <div className="exp-row-body">
                <div className="fieldset-row">
                    <div className="switcher">
                        <input
                            id="ext-a1"
                            type="checkbox"
                            value={value.bareTransversale.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    bareTransversale: {...value.bareTransversale, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a1").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a1">Bare transversale (inclusiv cheie)</label>
                        <div id="switcher-expand-box-14" className="fieldset-row switcher-expand-box"
                             style={{display: value.bareTransversale.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.bareTransversale.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        bareTransversale: {...value.bareTransversale, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a2"
                            type="checkbox"
                            value={value.bullBar.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    bullBar: {...value.bullBar, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a2").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a2">Bull bar</label>
                        <div id="switcher-expand-box-15" className="fieldset-row switcher-expand-box"
                             style={{display: value.bullBar.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.bullBar.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        bullBar: {...value.bullBar, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a3"
                            type="checkbox"
                            value={value.cameraVideoParbiz.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    cameraVideoParbiz: {...value.cameraVideoParbiz, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a3").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a3">Camera video parbriz</label>
                        <div id="switcher-expand-box-16" className="fieldset-row switcher-expand-box"
                             style={{display: value.cameraVideoParbiz.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.cameraVideoParbiz.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        cameraVideoParbiz: {...value.cameraVideoParbiz, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a4"
                            type="checkbox"
                            value={value.carligTractare.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    carligTractare: {...value.carligTractare, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a4").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a4">Carlig tractare</label>
                        <div id="switcher-expand-box-17" className="fieldset-row switcher-expand-box"
                             style={{display: value.carligTractare.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.carligTractare.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        carligTractare: {...value.carligTractare, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a5"
                            type="checkbox"
                            value={value.pachetSiguranta.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    pachetSiguranta: {...value.pachetSiguranta, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a5").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a5">Pachet siguranta (extinctor, trusa medicala)</label>
                        <div id="switcher-expand-box-18" className="fieldset-row switcher-expand-box"
                             style={{display: value.pachetSiguranta.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.pachetSiguranta.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        pachetSiguranta: {...value.pachetSiguranta, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a6"
                            type="checkbox"
                            value={value.portBagajSuplimentar.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    portBagajSuplimentar: {...value.portBagajSuplimentar, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a6").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a6">Portbagaj suplimentar (inclusiv cheie)</label>
                        <div id="switcher-expand-box-20" className="fieldset-row switcher-expand-box"
                             style={{display: value.portBagajSuplimentar.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.portBagajSuplimentar.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        portBagajSuplimentar: {...value.portBagajSuplimentar, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a7"
                            type="checkbox"
                            value={value.praguriInox.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    praguriInox: {...value.praguriInox, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a7").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a7">Praguri inox</label>
                        <div id="switcher-expand-box-19" className="fieldset-row switcher-expand-box"
                             style={{display: value.praguriInox.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.praguriInox.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        praguriInox: {...value.praguriInox, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a8"
                            type="checkbox"
                            value={value.suportBiciclete.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    suportBiciclete: {...value.suportBiciclete, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a8").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a8">Suport biciclete</label>
                        <div id="switcher-expand-box-21" className="fieldset-row switcher-expand-box"
                             style={{display: value.suportBiciclete.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.suportBiciclete.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        suportBiciclete: {...value.suportBiciclete, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="ext-a9"
                            type="checkbox"
                            value={value.suportSchiuri.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    suportSchiuri: {...value.suportSchiuri, checked}
                                });
                                if (checked) {
                                    document.getElementById("ext-a9").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    })
                                }
                            }}
                        />
                        <label htmlFor="ext-a9">Suport schiuri</label>
                        <div id="switcher-expand-box-22" className="fieldset-row switcher-expand-box"
                             style={{display: value.suportSchiuri.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.suportSchiuri.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        suportSchiuri: {...value.suportSchiuri, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="switcher">
                        <input
                            id="sw-more-accessories"
                            type="checkbox"
                            value={value.alteAcessorii.checked}
                            onChange={({target: {checked}}) => {
                                onChange({
                                    ...value,
                                    alteAcessorii: {...value.alteAcessorii, checked}
                                });
                                if (checked) {
                                    setTimeout(()=>document.getElementById("switcher-expand-box-4").scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    }), 100);
                                }
                            }}
                        />
                        <label htmlFor="sw-more-accessories">Alte accesorii importante</label>
                        <div id="switcher-expand-box-4" className="fieldset-row switcher-expand-box"
                             style={{display: value.alteAcessorii.checked ? "block" : "none"}}>
                            <div className="form-row">
                                <textarea
                                    placeholder="Adauga detalii despre avarii"
                                    value={value.alteAcessorii.text}
                                    onChange={({target: {value: text}}) => onChange({
                                        ...value,
                                        alteAcessorii: {...value.alteAcessorii, text}
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};