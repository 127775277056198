import React from 'react'
import {useAppContext} from '../context'
import jwt_decode from 'jwt-decode'
import axios from 'axios'



const SessionMaintainer = () => {

    const [state, setState] = React.useState({
        refreshTimeout: null,
        scheduleTime: null 
    }, 'sessionMaintainer')

    const {auth: [auth, setAuth]} = useAppContext()

    const refreshToken = async () => {
        console.log('refreshing...')
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/token`, 
                {
                    grant_type: 'refresh_token',
                    refresh_token: auth.refresh_token
                },
                {
                    auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET}
                }
            )

            const {access_token, refresh_token} = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in * 1000;
            const decodedToken = jwt_decode(access_token);

            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })

        } catch (err) {
            // could not refresh token!
            console.log("error refreshing", err)
            setAuth(null)
        }
    }


    React.useEffect(() => { 

        if(auth) {



            const tokenExpirationTime = auth.expirationTime 
            const now = Date.now()
            const refreshTokenExpirationTime = auth.refreshExpirationTime

            console.log({tokenExpirationTime, now})

            if(now > (tokenExpirationTime - (1000 * 60 * 2))) {
                console.log("token has expired")

                // check refresh token
                
                if(now > (refreshTokenExpirationTime - (1000 * 60 * 1))) {
                    console.log("refresh token has expired also")
                    // do logout
                    setAuth(null)
                } else {
                    console.log("refresh token has not epired yet")
                    
                    // do refresh token
                    refreshToken()

                }

            } else {
                console.log("token has not expired yet")

                // schedule a refresh right before token expires - 1 min margin to ensure smooth transition
                const scheduleTime = (tokenExpirationTime - (1000 * 60 * 1)) - now
                console.log("refresh scheduled after ", scheduleTime / 1000, " seconds")
                const refreshTimeout = setTimeout(() => {
                    refreshToken()
                    setState({})
                }, scheduleTime)

                setState(state => ({...state, refreshTimeout, scheduleTime: (tokenExpirationTime - (1000 * 60 * 1))}))
       
            } 
           
        } else {
            if(state.refreshTimeout) {
                clearTimeout(state.refreshTimeout)
                setState(state => ({...state, refreshTimeout: null}))
            }
        }

    }, [auth])

    return null
}

export default SessionMaintainer