import React from 'react'
import parse from 'html-react-parser'
import root from 'react-shadow'

export default ({children}) => {
    return(
        <root.div style={{height: '100%'}}>
            {parse(children)}
        </root.div>
    )
}