import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react';
import reactDebugHooks from 'react-debug-hooks'
import {AppContextProvider} from './context'

reactDebugHooks(React)

ReactDOM.render(
    <AppContextProvider>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </AppContextProvider>,
    document.getElementById('root')
)