import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code
}) => {
    
    return (
        <div className="fieldset-row fieldset-row-title">
            <p className="main-title">{synchronizeText(element.text, answers_by_code)}</p>
        </div>
    )
}