import React from 'react'
import { VALIDATION } from '../../constants/validations'
import styled from 'styled-components'
import bytes from 'bytes';
import CdeleteConfirm from '../../components/CdeleteConfirm'
import { synchronizeText } from "../../utils/synchronizeText"
import Loading from "../../components/Loading"

import tempFileService from '../../services/tempFileService'

export default ({
    element,
    value = [],
    changeValue,
    answers_by_code,
    errors = [],
    setErrors
}) => {

    const [state, setState] = React.useState({
        loadingFilePreview: false,
        previewLink: null
    }, `element-${element.id}`)

    const previewLinkRef = React.useRef(null)

    let max_no_files = 10;
    let max_file_size = bytes('70MB');

    if (element.validations) {

        element.validations.forEach(validation => {
            if (validation.validation === VALIDATION.MAX_FILE_SIZE) {
                max_file_size = bytes(validation.value);
            } else if (validation.validation === VALIDATION.MAX_NUMBER_OF_FILES) {
                max_no_files = parseInt(validation.value);
            }
        })
    }

    let uploader;

    const setValue = (value) => {
        changeValue(value)
    };

    const clickUpload = () => {
        uploader.click();
    };

    const uploadFiles = (e) => {
        setErrors(undefined)
        if (value.length + e.target.files.length > max_no_files) {
            setErrors([`max number of files|${max_no_files}`])
            e.target.value = null;
            return;
        }

        const current_filenames = value.map(file => file.name);

        for (let i = 0; i < e.target.files.length; i++) {
            if (current_filenames.includes(e.target.files[i].name)) {
                setErrors([`duplicated name|${e.target.files[i].name}`])
                e.target.value = null;
                return;
            }

            if (e.target.files[i].size > max_file_size) {
                setErrors([`max file size|${bytes(max_file_size)}`])
                e.target.value = null;
                return;
            }
        }

        const file_list = [...value];

        for(let i = 0; i < e.target.files.length; i++) {
            file_list.push(e.target.files[i]);
        }

        setValue(file_list);
        e.target.value = null;
    };

    const uploadFile = (file_index) => (e) => {
        setErrors(undefined)
        const current_filenames = value.filter((_, index) => index !== file_index).map(file => file.name);

        for (let i = 0; i < e.target.files.length; i++) {
            if (current_filenames.includes(e.target.files[i].name)) {
                setErrors([`duplicated name|${e.target.files[i].name}`])
                e.target.value = null;
                return;
            }

            if (e.target.files[i].size > max_file_size) {
                setErrors([`max file size|${bytes(max_file_size)}`])
                e.target.value = null;
                return;
            }
        }

        const file_list = [...value];

        file_list[file_index] = e.target.files[0];

        setValue(file_list);
        e.target.value = null;
    };

    const changeFileName = (e) => {
        setValue(value.map((file, index) => {
            if (parseInt(e.target.name) === index) {
                return new File([file], e.target.value, {type: file.type});
            }
            return file;
        }));
    };

    const removeFile = (index) => () => {
        const file_list = value.filter((file, i) => i !== index);
        setValue(file_list);
    };

    const previewFileInNewTab = async (file) => {
        setState(state => ({...state, loadingFilePreview: true}))
        const response = await tempFileService.upload(file)
        const previewLink = `file-preview?link=${response.downloadLink}`
        setState(state => ({
            ...state, 
            previewLink
        }))
        setTimeout(() => {
            previewLinkRef.current.click()
            setState(state => ({
                ...state, 
                loadingFilePreview: false,
            }))
        }, 100)
    }

    return (
        <div className="fieldset-row form-grid">
            <div className="multiple-files-upload">
                {element.text && (
					<label>{synchronizeText(element.text, answers_by_code)}</label>
				)}
                {state.loadingFilePreview && <Loading />}
                <a href={state.previewLink} ref={previewLinkRef} target="_blank" style={{display: 'none'}}/>
                {!state.loadingFilePreview && value.map((file, index) => (
                    <div key={index} className="upload-image-grid">
                        <UploadButton className="upload-image-box doc-upload" uploadButton={element.uploadButton} onClick={() => previewFileInNewTab(file)}>
                            {/* <input className="upload-image-input" type="file" onChange={uploadFile(index)} /> */}
                        </UploadButton>

                        <div className="form-row with-delete-btn">
                            <label htmlFor="img-caption">{element.fileName}</label>
                            <input name={`${index}`} value={file.name} onChange={changeFileName} />
                            <CdeleteConfirm
                                title="Esti sigur ca vrei sa stergi documentul?"
                                onConfirm={removeFile(index)}
                                okText="Sterge"
                            >
                                <i className="icon-ia-trash-bold delete-form-row-btn"/>
                            </CdeleteConfirm>
                            <span className="file-size">size: {bytes(file.size)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <span className="add-block-input mar2b" onClick={clickUpload}>{element.addFileButton}</span>

            <input style={{display: 'none'}} ref={ref => uploader = ref} type="file" onChange={uploadFiles} multiple />

            {errors.length > 0 &&
                <div className={`form-row ${(errors.length > 0) ? 'error' : '' }`}>
                    {renderErrors(errors, element)}
                </div>
            }
        </div>
    );
}


const renderErrors = (errors, element) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        const error_array = error.split('|');
        switch(error_array[0]) {
            case 'required':
                return 'This field is required';
            case 'min number of files':
                const minNrFiles = element.validations.find(({validation}) => validation === 'min number of files').value
                return `Minimum ${minNrFiles} ${minNrFiles > 1 ? 'files' : 'file' }`;
            case 'max number of files':
                const maxNrFiles = element.validations.find(({validation}) => validation === 'max number of files').value
                return `Maximum ${maxNrFiles} ${maxNrFiles > 1 ? 'files' : 'file'}`
            case 'max file size':
                const maxFileSize = bytes(element.validations.find(({validation}) => validation === 'max file size').value)
                return `File exceeds ${maxFileSize}bytes`;
            case 'duplicated name':
                return `The document named ${error_array[1]} has already been added`
        }
    }).map(error => <span key={error} className="form-row-error-msg">{error}</span>)
};

const UploadButton = styled.div`
    ::before {
        content: "${({ uploadButton }) => uploadButton}" !important;
    }
`