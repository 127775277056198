import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Button } from 'antd'

export default ({
    visible = false,
    closeFn = f=>f,
    children
}) => {
    return(
        <>
            {visible && ReactDOM.createPortal((
                <Wrapper>
                    <CloseButton icon="close" onClick={closeFn}/>
                    {children}
                </Wrapper>
            ), document.querySelector("#modal"))}
        </>
    )
}

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: white;
`
const CloseButton = styled(Button)`
    position: absolute; 
    right: 16px;
    top: 16px;
    z-index: 100;
`
