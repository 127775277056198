import React from "react"
import ReactDOM from "react-dom"
import { css } from "styled-components"

const cssFixes = css`
	.overlay {
		width: 100%;
		height: 100%;
		opacity: 0;
		overflow: hidden;
		position: fixed;
		left: 0;
		top: 100%;
		z-index: 500;
		background: rgba(200, 200, 200, 0.8);
	}

	.overlay-content {
		width: 45rem;
		height: auto;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0;
		background: #fff;
		border-radius: 0.3rem; /*border:1px solid rgba(0,0,0,.1);*/
		-moz-transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s,
			box-shadow 0.3s ease-out 0.3s;
		-o-transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s,
			box-shadow 0.3s ease-out 0.3s;
		-webkit-transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s,
			box-shadow 0.3s ease-out 0.3s;
		transition: transform 0.3s ease-out 0.3s, opacity 0.3s ease-out 0.3s,
			box-shadow 0.3s ease-out 0.3s;
		-moz-transform: translate3d(-50%, -50%, 0) scale(0.9);
		-ms-transform: translate3d(-50%, -50%, 0) scale(0.9);
		-o-transform: translate3d(-50%, -50%, 0) scale(0.9);
		-webkit-transform: translate3d(-50%, -50%, 0) scale(0.9);
		transform: translate3d(-50%, -50%, 0) scale(0.9);
		backface-visibility: hidden;
	}
	.overlay-content.with-header {
		padding-top: 6rem;
	}
	.overlay-content.with-footer {
		padding-bottom: 8rem;
	}

	.overlay-header {
		width: 100%;
		height: 6rem;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		padding: 0 5.5rem 0 3rem;
		border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	}
	.overlay-header h1,
	.overlay-header h3 {
		color: #000;
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 6rem; /*text-transform:uppercase;*/
		margin: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		backface-visibility: hidden;
	}

	.overlay-body {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
		overflow-y: auto;
		padding: 0;
		font-size: 1.1rem;
		line-height: 1.8rem;
	}
	.overlay-alert .overlay-body {
		text-align: center;
		padding: 1rem 3rem 2rem;
	}
	.overlay-template .overlay-body {
		padding: 3rem 10%;
	}
	.overlay-body .scrollbar {
		padding: 2rem 3rem;
	}
	.overlay-body h1,
	.overlay-body h2,
	.overlay-body h3,
	.overlay-body h4,
	.overlay-body h5,
	.overlay-body h6 {
		padding: 1rem 0 0;
		margin-bottom: 1rem;
		color: #000;
		font-weight: normal;
	}
	.overlay-body h1,
	.overlay-body h2 {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}
	.overlay-body h3,
	.overlay-body h4 {
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	.overlay-alert .overlay-body h3,
	.overlay-alert .overlay-body h4 {
		margin-bottom: 1.5rem;
	}
	.overlay-body h5,
	.overlay-body h6 {
		font-size: 1.2rem;
		line-height: 1.6rem;
		font-weight: bold;
		text-transform: uppercase;
	}
	.overlay-body p {
		color: var(--dark);
		font-size: 1.2rem;
		line-height: 1.8rem;
		margin: 0 auto 1rem;
	}

	.overlay-body .scrollbar.emails-list-content {
		padding: 0;
	}
	.overlay-client-sessions .overlay-body .scrollbar {
		min-height: 14rem;
		max-height: 23.5rem;
		padding: 0;
	}
	.overlay-allow-documents-storage .overlay-body {
		padding: 5rem 0 6rem;
	}
	.overlay-finish-form-steps .overlay-body .scrollbar {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	.overlay-finish-form-steps .overlay-body-actions {
		padding-top: 4rem;
	}
	.overlay-body .form {
		background: transparent !important;
	}
	.overlay-template.overlay-table-layout .overlay-body {
		padding: 0;
	}
	.overlay-template.overlay-allow-documents-storage .overlay-body {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.overlay-footer {
		width: 100%;
		height: 8rem;
		position: absolute;
		bottom: 0;
		left: 0;
		overflow: hidden;
		border-top: 1px dashed rgba(0, 0, 0, 0.1);
		padding: 2rem 3rem;
	}
	.overlay-footer .overlay-action-btn {
		width: auto;
		height: 100%;
		display: inline-block;
		position: relative;
		float: right;
		cursor: pointer;
		color: var(--primary-color);
		font-size: 1.1rem;
		font-weight: 600;
		line-height: 5rem;
		text-align: center;
		text-transform: uppercase;
		padding: 0 2rem;
		border-radius: 0;
		-webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		-moz-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		-o-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		-ms-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
	}
	.overlay-footer .overlay-action-btn:first-of-type {
		float: right;
	}
	.overlay-footer .overlay-action-btn:last-of-type {
		color: var(--dark);
		float: left;
	}
	.overlay-interese .overlay-footer .overlay-action-btn:before {
		content: none;
	}
	.overlay-footer .overlay-action-btn:last-of-type:before {
		background: transparent;
	}
	.overlay-footer .overlay-action-btn:hover {
		color: #fff;
		background: var(--primary-color);
		text-decoration: none;
	}
	.overlay-footer .overlay-action-btn.left {
		float: left;
	}
	.overlay-footer .overlay-action-btn.inactive-btn {
		color: #ccc;
		pointer-events: none;
		cursor: default;
	}
	.overlay-footer .button {
		margin-right: 1rem;
		backface-visibility: hidden;
	}
	.overlay-email-form .overlay-footer {
		background: #f8f8f8;
	}
	.overlay-footer.overlay-actions .button {
		margin-right: 1.5rem;
	}

	.overlay-template .overlay-actions {
		margin: 2.5rem auto 1rem;
		overflow: hidden;
	}
	.overlay-template .overlay-footer.overlay-actions {
		margin: 0 auto;
		overflow: hidden;
	}
	.overlay-send-emails .overlay-actions {
		margin: 2.5rem auto 2rem;
		overflow: hidden;
	}
	.overlay-footer.overlay-actions .button {
		margin-right: 1.5rem;
	}

	.overlay .close-button-overlay {
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 0.6rem;
		right: 0.6rem;
		display: block;
		border-radius: 0.3rem;
		font-size: 1.4rem;
		line-height: 4rem;
		text-align: center;
		color: rgba(0, 0, 0, 0.3);
		cursor: pointer;
		-webkit-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		-moz-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		-o-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		-ms-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
		transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
	}
	.overlay .close-button-overlay:hover {
		color: #fff;
		background: var(--primary-color);
	}
	.overlay .overlay-header .close-button-overlay {
		top: 0.9rem;
		right: 0.8rem;
	}

	.button.with-icon-right i {
		width: 2rem;
		height: 100%;
		display: block;
		float: left;
		margin: 0 0.5rem 0 -0.75rem;
		font-size: 1.1rem;
		line-height: 3.8rem;
		text-align: center;
	}
	.button.with-icon-right i {
		float: right;
		margin: 0 -0.75rem 0 0.5rem;
	}
	.overlay-template .button.with-icon-right {
		width: 11rem;
		margin-right: 1.5rem;
		padding-right: 2.5rem;
	}
	.approval-box-header .button.with-icon-right i {
		line-height: 3rem;
		margin-right: -0.5rem;
	}

	.button-link,
	.button-link:hover,
	.button-link:active {
		color: #39193b;
		background: transparent !important;
		border-color: transparent !important;
		padding: 0 !important;
		box-shadow: none;
	}
	.button-link:hover {
		text-decoration: underline;
	}
	.forgot-pass-header .button-link {
		padding-left: 1rem !important;
		margin-left: -13.5rem;
		color: #999;
	}
	.forgot-pass-header .button-link:hover,
	.forgot-pass-header .button-link:active {
		color: #1a1a1a;
	}
	.overlay-template .button-link {
		width: auto;
	}

	.overlay-template .overlay-body {
		padding: 3rem 10%;
	}
	.overlay-template .overlay-icon {
		width: 100%;
		height: 20rem;
		position: relative;
		text-align: center;
		margin: 1rem auto 1.5rem;
	}
	.overlay-template .overlay-icon img {
		width: auto;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.overlay-template h3 {
		margin-bottom: 0.5rem;
	}
	.overlay-add-category.overlay-template h3,
	.overlay-edit-category.overlay-template h3 {
		font-size: 1.4rem;
		margin-bottom: 1rem;
	}
	.overlay-template p {
		margin-bottom: 1.5rem;
	}
	.overlay-template .form-row {
		overflow: visible;
		margin-bottom: 3rem;
	}
	.overlay-template .button.with-icon-right {
		width: 11rem;
		margin-right: 1.5rem;
		padding-right: 2.5rem;
	}
	.overlay-template .button i.icon-mail,
	.overlay-template .button i.icon-ia-checked-outline {
		font-size: 1.3rem;
	}
	.overlay-template .button i.icon-ia-trash,
	.overlay-template .button i.icon-ia-delete {
		font-size: 1.5rem;
	}
	.overlay-template .button i.icon-ia-trash {
		line-height: 3.7rem;
	}
	.overlay-template .button-link {
		width: auto;
	}
	.overlay-template .overlay-actions {
		margin: 2.5rem auto 1rem;
		overflow: hidden;
	}
	.overlay-template .overlay-footer.overlay-actions {
		margin: 0 auto;
		overflow: hidden;
	}
	.overlay-allow-documents-storage.overlay-template .overlay-content {
		width: 56rem;
	}
	.overlay-template .overlay-content {
		width: 35rem;
	}
	.overlay-template.overlay-table-layout .overlay-body {
		padding: 0;
	}

	.overlay-template.overlay-allow-documents-storage .overlay-body {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.button {
		width: auto;
		height: 4rem;
		display: inline-block;
		font-size: 1.1rem;
		line-height: 3.8rem;
		font-weight: 400;
		text-transform: uppercase;
		font-family: "Lato", Arial, sans-serif;
		border: 1px solid transparent;
		padding: 0 2rem;
		cursor: pointer;
		color: #fff;
		border-radius: 2rem;
		text-align: center;
		box-shadow: 0 0.1rem 0.2rem rgba(114, 124, 245, 0);
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		outline: none;
	}
	/*button.button { line-height:inherit!important; }*/
	.button:hover,
	.button:active {
		box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
		text-decoration: none;
		outline: none;
	}
	.button:active {
		background: #1a1a1a;
		outline: none !important;
	}

	.button-outline {
		color: #666;
		background: transparent;
		border-color: rgba(0, 0, 0, 0.3);
	}
	.button-outline:hover,
	.button-outline:active {
		color: #fafbfe;
		background: var(--primary-color);
		box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
	}

	.button-link,
	.button-link:hover,
	.button-link:active {
		color: #39193b;
		background: transparent !important;
		border-color: transparent !important;
		padding: 0 !important;
		box-shadow: none;
	}
	.button-link:hover {
		text-decoration: underline;
	}

	.box-action .button {
		height: 3.4rem;
		line-height: 3.2rem;
		justify-self: end;
	}
	.box-action .button i {
		line-height: 3.2rem !important;
	}
	/*.box-action .button-outline,*/
	.export-options .button {
		border-radius: 0.3rem;
		font-size: 1rem;
	}

	.button.with-icon-left i,
	.button.with-icon-right i {
		width: 2rem;
		height: 100%;
		display: block;
		float: left;
		margin: 0 0.5rem 0 -0.75rem;
		font-size: 1.1rem;
		line-height: 3.8rem;
		text-align: center;
	}
	.button.with-icon-right i {
		float: right;
		margin: 0 -0.75rem 0 0.5rem;
	}

	.button i.icon-ia-trash-bold {
		font-size: 1.3rem;
	}
`

const ApopUp = ({
	children,
	title = "",
	visible = false,
	footer = null,
	disableFooter = false,
	disableHeader = false,
	disableCancel = false,
	disableMask = false,
	onCancel = f => f,
	okText = "Confirm",
	cancelText = "Cancel",
	onConfirm = f => f,
	rawCss = ``,
	overLayClass = "",
}) => {
	return (
		<>
			{ReactDOM.createPortal(
				<>
					{visible && (
						<>
							<div
								css={`
									&& {
										.overlay {
											animation: overlay-anim 0.5s ease forwards;
										}

										.overlay-content {
											opacity: 1;
											box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
											transform: translate3d(-50%, -50%, 0) scale(1);
										}

										&& {
											.button.with-icon-right {
												min-width: 11rem;
												width: unset;
											}
										}
									}
									${cssFixes}
									${rawCss}
								`}
							>
								<div
									className={`overlay ${overLayClass}`}
									onClick={() => {
										if (!disableMask) {
											onCancel()
										}
									}}
								>
									<div
										className={`overlay-content ${!disableHeader ? "with-header" : ""} ${
											!disableFooter ? "with-footer" : ""
										}`}
										onClick={e => e.stopPropagation()}
									>
										{!disableHeader && (
											<div className="overlay-header">
												<h3>{title}</h3>
											</div>
										)}
										<div className="overlay-body">{children}</div>
										{!disableFooter && (
											<div className="overlay-footer overlay-actions">
												{footer === null ? (
													<>
														<div className="button with-icon-right" onClick={onConfirm}>
															<i className="icon-ia-checked-outline" />
															{okText}
														</div>
														{!disableCancel && (
															<div className="button button-link" onClick={onCancel}>
																{cancelText}
															</div>
														)}
													</>
												) : (
													footer
												)}
											</div>
										)}
										<i className="icon-x close-button-overlay" onClick={onCancel} />
									</div>
								</div>
							</div>
						</>
					)}
				</>,
				document.querySelector("#modal")
			)}
		</>
	)
}

export default ApopUp
