import React from 'react'
import Ratio from 'react-ratio'
import SignatureCanvas from 'react-signature-canvas'
import CdeleteConfirm from './CdeleteConfirm'

const LsignaturePad = ({
    value = null,
    onChange = f=>f,
}) => {
    const sigRef = React.useRef({})


    React.useEffect(() => {
        sigRef.current.clear()
        sigRef.current.fromDataURL(value)
    }, [value])

    const clear = () => {
        onChange(null)
        sigRef.current.clear()
    }

    const save = () => {
        onChange(sigRef.current.toDataURL("image/png"))
    }

    return (
        <Ratio ratio={ 16 / 9 }   css={`
            .clear-signature-pad {
                z-index: 1;
            }
            .signature-pad {
                width: 100%;
                height: 100%;
                margin: unset;
            }
        `}>
             <CdeleteConfirm
                title="Are you sure you want to delete the signature?"
                onConfirm={clear}
                okText="DELETE"
            >
                <i class="icon-refresh-cw clear-signature-pad"></i>
            </CdeleteConfirm>
            <SignatureCanvas
                onEnd={save}
                clearOnResize={false}
                ref={sigRef}
                penColor='black'
                canvasProps={{
                    className: "signature-pad"
                }}
            />
        </Ratio>
    )
}

export default LsignaturePad