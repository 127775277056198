import React from 'react'

import Navbar from '../../components/Navbar/Navbar'
import Loading from '../../components/Loading'
import API from '../../api'
import Details from './Profile.Details'
import ChangePassword from './Profile.ChangePassword'
import EditSignature from './Profile.EditSignature'

export default () => {

    const [state, setState] = React.useState({
        profile: null,
        loading: false,
        tab: null,
    }, 'profile')

    const signatureTabRef = React.useRef()
    const paswordTabRef = React.useRef()

    React.useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        try {
            setState(state => ({...state, loading: true}));
            const {data: profile} = await API.get('users/personal/profile');
            setState(state => ({
                ...state, 
                profile: {
                    ...profile,
                    image: profile.image
                        ? profile.image?.startsWith('http')
                                ? profile.image
                                : `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${profile.image}`
                        : null
                }, 
                loading: false
            }))
        } catch (err) {
            setState(state => ({...state, loading: false}));
            // message.warning("A aparut o eroare. Va rugam reincercati.")
        }
    }

    const setTab = tab => setState(state => ({...state, tab}))

    return (
        <div className={`wrapper profile-page ${state.tab === 'CHANGE_PASSWORD' ? 'view-change-password' : ''} ${state.tab === 'EDIT_SIGNATURE' ? 'view-edit-signature' : ''}`}>
            <Navbar/>
            <div class="content">
                {state.loading && <Loading />}
                {!state.loading && state.profile &&
                    <div class="scrollbar">
                        <Details profileStore={[state, setState]} setTab={setTab} signatureTabRef={signatureTabRef} paswordTabRef={paswordTabRef}/>
                        <ChangePassword setTab={setTab} paswordTabRef={paswordTabRef}/>
                        <EditSignature profileStore={[state, setState]} setTab={setTab} signatureTabRef={signatureTabRef}/>
                    </div>
                }
            </div>
        </div>
    )
}