import React from 'react'
import {useHistory} from 'react-router-dom'
import look from './newSession.look'

const FinishPage = ({form}) => {

    const history = useHistory()

    return (
        <div className="wrapper fixed-elements form-page last-form-msg" css={look}>

            <div className="wrapper-content">
                <div className="table">
                    <div className="table-cell">

                        <div className="form-final-msg">
                            <div>
                                <h1 className="main-title">
                                    {form?.public_availability?.thankYouText || 'Thank you!'}
                                </h1>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}
                            </div>
                            <a href="#" onClick={e => {
                                e.preventDefault()
                                history.push('/')
                            }} className="button">New Session</a>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default FinishPage