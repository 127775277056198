import React from 'react'
import {notification} from 'antd'
import {css} from "styled-components"
import API from '../../api'
import Loading from '../../components/Loading'
import { VALIDATION } from "../../constants/validations"
import CpopUp from '../../components/CpopUp'

const TwoStep = ({
    onFinish = f => f,
    currentStep,
    twoStepType,
    elementValue,
    formId,
    sessionStore
}) => {

    const [state, setState] = React.useState({
        createCode: {
            creating: false,
            codeId: undefined
        },
        sendingConfirmation: false,
        code: '',
        errors: {}
    }, 'two-step-validation');

    const [sessionState, setSessionState] = sessionStore

    console.log("validation type",twoStepType)

    React.useEffect(() => {
        createTwoStepCode()
    }, []);

    React.useEffect(() => {
        if(
            sessionState.twostepvalidations &&
            sessionState.twostepvalidations.find(twostepvalidation => twostepvalidation.code === state.code)
        ) {
            onFinish()
        }
    }, [sessionState.twostepvalidations])

    const createTwoStepCode = async () => {
        try {
            setState(state => ({...state, createCode: {...state.createCode, creating: true}}));
            const communication = Array.isArray(elementValue) ? elementValue?.[0] : elementValue
            const response = await API.post(`/sessions/${formId}/twostepcode/`, {
                communication,
                step: currentStep + 1
            });
            setState(state => ({...state, createCode: {creating: false, codeId: response.data}}))
        } catch (err) {
            setState(state => ({...state, createCode: {creating: false, codeId: undefined}}));
            notification.error({message: "Something went wrong!"})
        }

    };

    const onConfirmClick = async () => {
        try {
            setState(state => ({...state, errors: {}}))
            setState(state => ({...state, sendingConfirmation: true}));
            await API.patch(`/sessions/${formId}/twostepcode/${state.createCode.codeId}`, {
                step: currentStep + 1,
                code: state.code
            });
            setState(state => ({...state, sendingConfirmation: false}));

            setSessionState(sessionState => ({
                ...sessionState,
                twostepvalidations: [
                    ...(sessionState.twostepvalidations || []),
                    {
                        _id: state.createCode.codeId,
                        code: state.code,
                        step: currentStep + 1,
                        communication: elementValue
                    }
                ]
            }))


        } catch (err) {
            setState(state => ({...state, sendingConfirmation: false, errors: {code: true}}));
            notification.error({message: "Cod incorect"})
        }
    };

    const onResendClick = async () => {
        try {
            setState(state => ({...state, errors: {}, code: ''}))
            setState(state => ({...state, createCode: {creating: true, codeId: undefined}}));

            const communication = Array.isArray(elementValue) ? elementValue?.[0] : elementValue
            const response = await API.post(`/sessions/${formId}/twostepcode/${state.createCode.codeId}/reset`, {
                communication,
                step: currentStep + 1
            });
            setState(state => ({...state, createCode: {creating: false, codeId: response.data}}))
        } catch (err) {
            setState(state => ({...state, createCode: {creating: false, codeId: undefined}}));
            notification.error({message: "Something went wrong!"})
        }
    };

    const onCodeChange = ({target: {value: code}}) => setState(state => ({...state, code}));


    return (
        <>
            {state.createCode.creating || state.sendingConfirmation
                ? <Loading/>
                : <CpopUp
                    visible={true}
                    title={(twoStepType === VALIDATION.EMAIL_TWO_STEP_VALIDATION) ? "CONFIRM EMAIL" : "CONFIRM PHONE NUMBER"}
                    onCancel={onFinish}
                    overLayClass="overlay-template"
                    rawCss={css`
                        .overlay-actions {
                            display: grid;
                            grid-template-columns: max-content max-content;
                        }
                    `}
                    footer={
                        <>
                            <div className="button" onClick={onConfirmClick}>Confirm</div>
                            <div className="button button-link" onClick={onResendClick}>Resend code</div>
                        </>
                    }
                    disableMask
                >
                    <p>You recieved a code by {twoStepType === VALIDATION.EMAIL_TWO_STEP_VALIDATION ? "Email" : "SMS"}.
                        To continue please enter it in the box below.</p>
                       <div className="form">
                            <div className={`form-row ${state.errors.code ? 'error' : ''}`}>
                                <input type="text" value={state.code} onChange={onCodeChange}/>
                                {state.errors.code &&
                                    <span className="form-row-error-msg">Code is incorrect</span>
                                }
                            </div>
                       </div>
                </CpopUp>
            }
        </>
    )
}

export default TwoStep
