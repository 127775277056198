import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code
}) => {

    return (
        <div className="fieldset-row img-fieldset-row">
            <div className="form-grid" style={{ gridTemplateColumns: '1fr'}}>
                <div className="form-row-image">
                    <img style={{width: '100%', height: 'auto'}} src={element.url || element.image} alt="" />
                    {element.text && <p className="image-caption">{synchronizeText(element.text, answers_by_code)}</p>}
                </div>
            </div>
        </div>
    )
}