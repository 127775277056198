import React from 'react'
import LsignaturePad from '../../components/LsignaturePad'

export default ({
    element,
    value = {
        name: '',
        signature: null
    }
}) => {

    return (
        <div className="fieldset-row">
            {element.text && <p>{element.text}</p>}
            <p className="form-box-wrap-title">{value.name}</p>
            <LsignaturePad value={value.signature} />
        </div>
    )
}