import React from "react"
import moment from "moment"
import { saveAs } from "file-saver"
import { notification } from "antd"
import { useParams } from "react-router-dom"

import Navbar from "../../components/Navbar/Navbar"
import SupportedElements from "../../FormElements"
import { getAnswersByCode } from "../../utils/getAnswersByCode"
import Loading from "../../components/Loading"
import API from "../../api"
import PageNotFound from '../../components/PageNotFound'

const ViewSession = () => {
	const [state, setState] = React.useState({
		loading: false,
		error: null,
		data: null,
	}, 'view-session')

	const params = useParams()

	React.useEffect(() => {
		getSession()
	}, [])

	const getSession = async () => {
		try {
			setState(state => ({ ...state, loading: true, error: null }))
			const { data } = await API.get(`/sessions/${params.sessionId}`)
			setState(state => ({ ...state, loading: false, data }))
		} catch (err) {
			setState(state => ({
				...state,
				loading: false,
				...((err.response?.status === 404 || err.response?.status === 401) ? {pageNotFound: true} : {}),
			}))
		}
	}

	const downloadGeneratedDocumentPdf = async ({
		_id: document_id,
		filename,
		provider,
	}) => {
		try {
			setState(state => ({ ...state, loading: true }))
			const {
				data: pdf,
			} = await API.get(
				`/sessions/${state.data._id}/document/${document_id}/pdf`,
				{ responseType: "blob" }
			)
			const pdf_filename = filename  //.split(".").slice(0, -1).join(".") + ".pdf"
			saveAs(pdf, state.data._id + "_" + pdf_filename)
			setState(state => ({ ...state, loading: false }))
		} catch (err) {
			let message = "Something went wrong with downloading the file"

			if (err.response && err.response.status === 404) {
				message = "The file was not found"
			} else if (err.response && err.response.data && err.response.data.message) {
				if (err.response.data.message === "reauthorize_storage") {
					message =
						"There was an error when connecting to the selected default storage, please try again authorizing it."
				} else if (err.response.data.message === "different_storage") {
					message = `The document is stored on an inaccessible storage platform or account. Please connect directly to your ${
						{
							gdrive: "Google Drive",
							onedrive: "Microsoft OneDrive",
							dropbox: "Dropbox",
						}[provider.name]
					} account to retrieve the file. File name: ${
						params.sessionId
					}_${filename}`
				} else if (err.response.data.message === "different_account") {
					message = `The account of the storage provider for this document is different from the one authorized now`
				} else if (err.response.data.message === "different_folder") {
					message = `The folder of the storage provider for this document is different from the one selected now`
				}
			}
			notification.error({
				message: message,
			})
			setState(state => ({ ...state, loading: false }))
		}
	}

	let answers_by_code = {}
	let pinnedItems = []
	let values = []
	if (state.data) {
		for (let i = 0; i < state.data.formSnapshot.length; i++) {
			for (let j = 0; j < state.data.formSnapshot[i].elements.length; j++) {
				if (
					state.data.formSnapshot[i].elements[j].pinned &&
					state.data.formSnapshot[i].elements[j].pinned.status
				) {
					pinnedItems.push(state.data.formSnapshot[i].elements[j])
				}
				if (state.data.formSnapshot[i].elements[j].value) {
					values.push({
						element_id: state.data.formSnapshot[i].elements[j]._id,
						value: state.data.formSnapshot[i].elements[j].value,
					})
				}
			}
		}
		answers_by_code = getAnswersByCode(values, state.data.formSnapshot)
		pinnedItems.sort((elementA, elementB) => {
			if (elementA.pinned.updatedAt > elementB.pinned.updatedAt) {
				return 1
			} else if (elementA.pinned.updatedAt < elementB.pinned.updatedAt) {
				return -1
			} else return 0
		})
	}

	return (
		<>
			<Navbar />
			{state.loading && <Loading />}
			<PageNotFound active={!!state.pageNotFound}>
				<div class="wrapper session-page">
					{!state.loading && state.data && (
						<div class="content">
							<div class="content-box session-content-box">
								<div class="scrollbar">
									<div class="content-box-header">
										<div>
											<h5>{state.data._form._category.name}</h5>
											<h1>{state.data._form.name}</h1>
											<span>13 mai 2019, 8:56</span>
											<span>
												{moment(state.data.updated_at).format("D MMM YYYY, HH:mm")}
											</span>
										</div>
										<div>
											<div class="tbl-li-img">
												<strong>{state.data._agent.name}</strong>

												<div class="tbl-img tbl-agent-img">
													<div>
														{state.data._agent.image !== null ? (
															<img
																src={`${process.env.REACT_APP_API_BASE_URL}/images/users/image_thumbnail/${state.data._agent.image}`}
																alt=""
															/>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="content-box-body">
										<div class="session-form">

											{pinnedItems.map(element => {
												if (!SupportedElements[element.type]) return null

												let value
												if (element.value !== undefined) value = element.value
												
												return (
													<div key={element._id}>
														{SupportedElements[element.type].view({
															element,
															answers_by_code,
															value,
															session_id: params.sessionId,
														})}
													</div>
												)
											})}

											{state.data.formSnapshot.map((step, index) => {
												const step_elements = step.elements.map(element => {

													if (!SupportedElements[element.type]) return null
													if (pinnedItems.find(item => item._id === element._id)) return null

													let value
													if (element.value !== undefined) value = element.value
													
													return (
														<div key={element._id}>
															{SupportedElements[element.type].view({
																element,
																answers_by_code,
																value,
																session_id: params.sessionId,
															})}
														</div>
													)

												})

												return (
													<>
														{index !== 0 && <div className="step-divider" />}

														{step.header && <h2>{step.header}</h2>}

														{step_elements}
													</>
												)
											})}

											{state.data.documents.length !== 0 && (
												<div class="fieldset-row fieldset-row-end">
													<div style={{ borderTop: "none" }}>
														<h3 class="session-form-box-wrap-title">Documente</h3>

														<div
															class="session-form-grid files-list mar1b"
															style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
														>
															{state.data.documents.map(file => (
																<div
																	key={file._id}
																	className="file"
																	onClick={() => downloadGeneratedDocumentPdf(file)}
																>
																	<img src="img/file.svg" />
																	<strong>{file.filename.replace(/\.[^/.]+$/, "")}</strong>
																	<a class="button button-outline">
																		Descarca
																	</a>
																</div>
															))}
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</PageNotFound>
		</>
	)
}

export default ViewSession
