import { createGlobalStyle } from 'styled-components';

export const GlobalFontMedias = createGlobalStyle`
@media only screen and (max-width: 3000px) {
    html, body { font-size: 14px; }
}

@media only screen and (max-width: 1920px) {
    html, body { font-size: 13px; }
}

@media only screen and (max-width: 1680px) {
    html, body { font-size: 12px; }
}

@media only screen and (max-width: 1440px) {
    html, body { font-size: 11px; }
}

@media only screen and (max-width: 1280px) {
    html, body { font-size: 10px; }
}
`;