const initialState = null

const auth = (state = initialState, action) => {
    switch(action.type) {
        case "LOGIN":
            return action.payload
        case "REFRES_TOKEN":
            return { ...state, ...action.payload }
        case "LOGOUT":
            return null
        case "UPDATE_NAVBAR_IMAGE":
            return {...state, decodedToken: {...state.decodedToken, image: action.payload}}
        default:
            return state
    }
}

export default auth