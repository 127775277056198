import React from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import './style.css'

const AdatePicker = ({
    value, 
    onChange=f=>f, 
    placeholder="", 
    disabled=false,
    showTimeSelect=false,
    showTimeSelectOnly=false, 
    ...rest
}) => {
    return(
        <DatePicker
            selected={value}
            onChange={onChange}
            {...rest}
            placeholderText={placeholder}
            portalId="datePicker-portal"
            disabled={disabled}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
        />
    )
}

export default AdatePicker

    
