import React from "react"
import { useHistory } from "react-router-dom"
import look from "./newSession.look"
import {css} from 'styled-components'

const cssFixes = css`
	.content-preview {
		min-height: 35rem;
	}
`

const StartPage = ({ startSession, form }) => {
	const history = useHistory()

	return (
	<div className="wrapper form-preview-page" css={`${look}${cssFixes}`}>
			<div className="wrapper-content">
				<div className="content">
					<CloseCros onClick={() => history.push("/")} />
					<div className="form-preview">
						<header>
							<div className="form-logo">
								{!form.logo ? (
									<img src="img/Alchemist_Identity_Final-01.png" />
								) : (
									<img src={form.logo} alt="" />
								)}
							</div>
							<h6 className="sup-title">{form._category.name}</h6>
							<h1 className="main-title">{form.name}</h1>
						</header>

						<div className="content-preview">
							<div className="content-preview-body">
								<pre style={{whiteSpace: 'pre-wrap'}}>{form.description}</pre>
								<div className="content-preview-action">
									<a
										className="button"
										href="#"
										title=""
										onClick={e => {
											e.preventDefault()
											startSession()
										}}
									>
										Start
									</a>
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>

			<footer />
		</div>
	)
}

export default StartPage

const CloseCros = params => {
	return (
		<i
			className="icon-x"
			css={`
				top: 1rem;
				right: 6rem;
				width: 4rem;
				height: 4rem;
				position: absolute;
				display: block;
				border-radius: 0.3rem;
				font-size: 2rem;
				line-height: 4rem;
				text-align: center;
				color: rgba(0, 0, 0, 0.3);
				cursor: pointer;
				transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
				:hover {
					color: ${({ theme: { backgroundColor } }) => backgroundColor};
					background: ${({ theme: { fontColor } }) => fontColor};
				}
			`}
			{...params}
		/>
	)
}
