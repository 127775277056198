import {elements} from '../constants/elements'

import shortAnswer from './TextBox/TextBox'
import dropdown from './Dropdown/DropDown'
import longAnswer from './LongAnswer/LongAnswer'
import time from './Time/Time'
import checkboxGroup from './CheckboxGroup/CheckboxGroup'
import radioGroup from './RadioGroup/RadioGroup'
import heading from './Heading/Heading'
import paragraph from './Paragraph/Paragraph'
import image from './Image/Image'
import imageUpload from './ImageUpload/ImageUpload'
import fileUpload from './FileUpload/FileUpload'
import clientSignature from './ClientSignature/ClientSignature'
import agentSignature from './AgentSignature/AgentSignature'
import standardAddress from './StandardAddress/StandardAdress'
import privacyAgreement from './PrivacyAgreement/PrivacyAgreement'
import contractCommunicationOptions from './ContractCommunicationOptions/ContractCommunicationOptions'
import documentsPreview from './DocumentsPreview/DocumentsPreview'
import idCardExtractor from './IdCardExtractor/IdCardExtractor'
import marketingCommunicationOptions from './MarketingCommunicationOptions/MarketingCommunicationOptions'
import customerSatisfaction from './CustomerSatisfaction/CustomerSatisfaction'
import vehicleEvaluation from './VehicleEvaluation/VehicleEvaluation'
import registrationNumber from './RegistrationNumber/RegistrationNumber'
import iban from './Iban/Iban'
import date from './Date/Date'
import vin from './Vin/Vin'
import itemsSelectListWithImages from './ItemsSelectListWithImages/ItemsSelectListWithImages'
import map from './Map/Map'

export default {
    [elements.CONTRACT_COMMUNICATION_OPTIONS]: contractCommunicationOptions,
    [elements.SHORT_ANSWER]: shortAnswer,
    [elements.VIN]: vin,
    [elements.IBAN]: iban,
    [elements.REGISTRATION_NUMBER]: registrationNumber,
    [elements.LONG_ANSWER]: longAnswer,
    [elements.FILE_UPLOAD]: fileUpload,
    [elements.DROPDOWN]: dropdown,
    [elements.SIGNATURE]: clientSignature,
    [elements.DATE]: date,
    [elements.TIME]: time,
    [elements.HEADING]: heading,
    [elements.PARAGRAPH]: paragraph,
    [elements.IMAGE]: image,
    [elements.CHECKBOX_GROUP]: checkboxGroup,
    [elements.STANDARD_ADDRESS]: standardAddress,
    [elements.RADIO_GROUP]: radioGroup,
    [elements.AGENT_SIGNATURE]: agentSignature,
    [elements.DOCUMENTS_PREVIEW]: documentsPreview,
    [elements.PRIVACY_AGREEMENT]: privacyAgreement,
    [elements.COMMUNICATION_OPTIONS]: marketingCommunicationOptions,
    [elements.ID_CARD_EXTRACTOR]: idCardExtractor,
    [elements.IMAGE_UPLOAD]: imageUpload,
    [elements.ITEMS_SELECT_LIST_WITH_IMAGES]: itemsSelectListWithImages,
    [elements.VEHICLE_EVALUATION]: vehicleEvaluation,
    [elements.CUSTOMER_SATISFACTION]: customerSatisfaction,
    [elements.MAP]: map
}