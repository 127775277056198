import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code,
    value
}) => {

    return (
        <div className="fieldset-row">
            {element.text && <label>{synchronizeText(element.text, answers_by_code)}</label>}

            <div className="session-form-grid" style={{ width: '100%', display: 'grid', gridTemplateColumns: `repeat(${element.grid_columns || 1}, 1fr`}}>
                {element.items.map(item => (
                    <div key={item} className="session-form-row-radio">
                        <input type="radio" value={item} checked={item === value} onChange={f=>f} />
                        <label>{item}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}