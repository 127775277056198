import React from 'react'

import Navbar from '../../components/Navbar/Navbar'

import {useParams} from 'react-router-dom'
import API from '../../api'
import StartPage from '../NewSession/NewSession.StartPage'
import Loading from '../../components/Loading'
import {ThemeProvider} from 'styled-components'
import Elements from '../../FormElements'
import {getAnswersByCode} from "../../utils/getAnswersByCode"
import {Oppression} from '../../components/Oppression'
import {getFormErrors} from '../../utils/isValidValues'
import {VALIDATION} from '../../constants/validations'
import {getError} from "../../utils/getError"
import FinishPage from '../NewSession/NewSession.FinishePage'
import NavigationPrompt from "react-router-navigation-prompt"
import TwoStep from '../NewSession/NewSession.TwoStep'
import CollectLocation from '../NewSession/NewSession.CollectLocation'
import {useAppContext} from '../../context'
import look from '../NewSession/newSession.look'
import CpopUp from '../../components/CpopUp'
import CdeleteConfirm from '../../components/CdeleteConfirm'
import CpopConfirm from '../../components/CpopConfirm'
import PageNotFound from '../../components/PageNotFound'

const EditSession = () => {

    const [state, setState] = React.useState({
        loading: false,
        current_step: 0,
        
        sessionStarted: false,
        form: null,
        loadingForm: false,
        loadingFormError: null,
        currentStep: 0,
        answers: [],
        hiddenElements: {},
        validationErrors: {},
        twostepvalidations: undefined,
        submittingSession: false,
        submissionSuccess: false,
        submissionError: null,
        error: null,
        location: null,
        loadingSession: false,
        draftSession: null

    }, 'Edit-session')

    React.useEffect(() => {
        getForm()
        getDraftSession()
    }, [])

    const params = useParams()

    const contentRef = React.useRef()

    React.useEffect(() => {
        if(contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    }, [state.currentStep])

    const {
        company:[company]
    } = useAppContext()

    const getDraftSession = async () => {
        try {
            setState(state => ({...state, loadingSession: true}))
            const {data: draftSession} = await API.get(`/sessions/${params.sessionId}`)
            const answers = []
            draftSession.formSnapshot.forEach(step =>
                step.elements.forEach(element => {
                    if (element.value) {
                        answers.push({
                            value: element.value,
                            element_id: element._id
                        })
                    }
                })
            )
            setState(state => ({...state, answers, draftSession, loadingSession: false}))
        } catch (err) {
            setState(state => ({
				...state,
				form: null,
				...(err.response?.status === 404 ? {pageNotFound: true} : {}),
				loadingSession: false,
			}))
        }
    }

    const getForm = async () => {

        try {
            setState(state => ({
                ...state,
                form: null,
                loadingFormError: null,
                loadingForm: true,
            }))
            const response = await API.get(`/forms/${params.formId}/preview`)
            setState(state => ({
                ...state,
                form: response.data,
                loadingFormError: null,
                loadingForm: false,
            }))
        } catch (err) {
            setState(state => ({
				...state,
				form: null,
				...(err.response?.status === 404 ? {pageNotFound: true} : {}),
				loadingForm: false,
			}))
        }

    }

    const startSession = () => {
        setState(state => ({...state, sessionStarted: true}))
    }

    const goToStep = step => {
        setState(state => ({...state, currentStep: step}))
    }

    const setValue = payload => {
        const validationErrors = { ...state.validationErrors }

        delete validationErrors[payload.element_id]

        if (payload.value === null || (Array.isArray(payload.value) && payload.value.length === 0)) {
            setState(state => ({
                ...state,
                answers: state.answers.filter(answer => (answer.element_id !== payload.element_id)),
                validationErrors
            }))
            return
        }

        const elements = []

        for (let i = 0; i < state.form.steps.length; i++) {
            state.form.steps[i].elements.forEach(element => elements.push(element));
        }

        let values = [];

        if (state.answers.find(answer => answer.element_id === payload.element_id)) {
            values = state.answers.map(answer => {
                if (answer.element_id === payload.element_id) return payload
                return answer
            })
        } else {
            values = [ ...state.answers, payload ]
            values.sort((a, b) => {
                const sorting = elements.map(element => element._id)
                return sorting.indexOf(a.element_id) - sorting.indexOf(b.element_id)
            })
        
        }
        
        setState(state => ({
            ...state,
            answers: values,
            validationErrors
        }))
    }

    const onBack = () => {
        if(state.currentStep > 0) {
            setState(state => ({...state, currentStep: state.currentStep - 1}))
        } else {
            setState(state => ({...state, sessionStarted: false}))
        }
    }

    const scrollToError = (validationErrors) => {
        let y, ref = null;
        const validationErrors_keys = Object.keys(validationErrors);
        const elements_references_keys = Object.keys(elements_references).filter(ref => validationErrors_keys.includes(ref));
        elements_references_keys.forEach(key => {
            if (!y) {
                y = elements_references[key].getBoundingClientRect().y;
                ref = elements_references[key]
            } else {
                if (y > elements_references[key].getBoundingClientRect().y) {
                    y = elements_references[key].getBoundingClientRect().y;
                    ref = elements_references[key]
                }
            }
        });
        setTimeout((ref) => {
            if(ref) {
                ref.scrollIntoView({block: 'start', behavior: 'smooth'})
            }
        }, 500, ref)
    }

    const prepareData = (data, state) => {
        const elements = []
        for (let i = 0; i <= state.currentStep; i++) {
            state.form.steps[i].elements.forEach(element => elements.push(element))
        }
        const file_uploads = {}
        let values = Object.values(state.answers)
        values = values.filter(answer => {
            const element = elements.find(element => answer.element_id === element._id);
            if (element && element.type === 'file upload') {
                file_uploads[answer.element_id] = answer.value;
                return false
            }
            return true
        });
        data.append('values', JSON.stringify(values));
        const file_uploads_element_ids = Object.keys(file_uploads);
        file_uploads_element_ids.forEach(element_id => {
            for (let i = 0; i < file_uploads[element_id].length; i++) {
                data.append(element_id, file_uploads[element_id][i])
            }
        })
    }

    const getValidationErrors = async ({answers_by_code}) => {
        const elements = state.form.steps[state.currentStep].elements.filter(element => !state.hiddenElements[element.code])
        const element_ids = elements.map(element => element._id)
        const values = state.answers.filter(value => element_ids.includes(value.element_id))
        const sorting = elements.map(element => element._id)
        values.sort((a, b) => sorting.indexOf(a.element_id) - sorting.indexOf(b.element_id))
        const validationErrors = await getFormErrors({elements, values, answers_by_code, formId: params.formId, sessionId: params.sessionId})
        return validationErrors || {}
    }

    const endSession = async () => {
        const validationErrors = await getValidationErrors({answers_by_code})
        
        const data = new FormData();
        if (Object.keys(validationErrors).length > 0) {
            scrollToError(validationErrors)
            setValidationErrors(validationErrors);
            prepareData(data, {
                ...state
            }, answers_by_code);
            data.append('completed_steps', (state.currentStep).toString())
        } else {
            prepareData(data, state, answers_by_code);
            data.append('completed_steps', state.currentStep + 1)
        }
        data.append('converted', false);
        if (state.form && state.form.proofOfLocation) {
            data.append('location[coordinates][0]', state.location.coordinates[0])
            data.append('location[coordinates][1]', state.location.coordinates[1])
        }
        submitSession({data})
    }

    const setShowTwoStep = showTwoStep => setState(state => ({...state, showTwoStep}))

    const setValidationErrors = validationErrors => {
        setState(state => ({...state, validationErrors}))
    }

    const validateAndSendToStep = async step => {

        const validationErrors = await getValidationErrors({answers_by_code})

        console.log({validationErrors})

        if (Object.keys(validationErrors).length > 0) {
            scrollToError(validationErrors)
            setValidationErrors(validationErrors)
            return
        }

        setValidationErrors({})

        //---
        const twoStepComponent = state.form.steps[state.currentStep].elements
            .find(element =>
                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
            );

        if (twoStepComponent) {
            const required = twoStepComponent.validations.map(validation => validation.validation).includes(VALIDATION.REQUIRED);
            const value = state.answers.find(answer => answer.element_id === twoStepComponent._id) && state.answers.find(answer => answer.element_id === twoStepComponent._id).value

            if (required || value) {

                // check if two step is completed
                const completedTwoStep = state.twostepvalidations && state.twostepvalidations.find(({step, communication}) => step === state.currentStep && communication === value)

                if (!completedTwoStep) {
                    setShowTwoStep(true)
                    return
                }
            }
        }
        ///--

        if(form_ref.current) {
            form_ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
        goToStep(step)

    }

    const submitSession = async ({data}) => {
        try {
            setState(state => ({...state, submittingSession: true}))

            await API.put(`/sessions/${params.sessionId}/form/${params.formId}`, data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })

            setState(state => ({...state, submittingSession: false, submissionSuccess: true}))
        } catch (err) {
            setState(state => ({...state, submittingSession: false, submissionError: getError(err.response)}))
        }
    }

    const finalizeSession = async () => {
        const validationErrors = await getValidationErrors({answers_by_code})

        // if validation errors: show errors
        if (Object.keys(validationErrors).length > 0) {
            scrollToError(validationErrors)
            setValidationErrors(validationErrors)
            return
        }

        // if this step has two step validation and two step validation is not completed: show two step modal

        const twoStepComponent = state.form.steps[state.currentStep].elements
            .find(element =>
                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
            );

        if (twoStepComponent) {
            const required = twoStepComponent.validations.map(validation => validation.validation).includes(VALIDATION.REQUIRED);
            const value = state.answers.find(answer => answer.element_id === twoStepComponent._id) && state.answers.find(answer => answer.element_id === twoStepComponent._id).value;

            if (required || value) {

                // check if two step is completed
                const completedTwoStep = state.twostepvalidations && state.twostepvalidations.find(({step, communication}) => step === state.currentStep && communication === value);

                if (!completedTwoStep) {
                    setShowTwoStep(true)
                    return
                }
            }
        }


        const data = new FormData()
        prepareData(data, state)
        data.append('converted', true)
        data.append('completed_steps', state.currentStep +1)

        if (Array.isArray(state.twostepvalidations)) {
            data.append('twostepvalidations', JSON.stringify(state.twostepvalidations.map(({step, ...rest}) => ({
                ...rest,
                step: --step
            }))))
        }

        if (state.form && state.form.proofOfLocation) {
            data.append('location[coordinates][0]', state.location.coordinates[0]);
            data.append('location[coordinates][1]', state.location.coordinates[1]);
        }
        submitSession({data})
    }

    const setLocation = location => {
        setState(state => ({...state, location}))
    }

    const clearCurrentStep = () => {
        const step_elements_ids = state.form.steps[state.currentStep].elements.map(element => element._id)
        const answers = state.answers.filter(answer => !step_elements_ids.includes(answer.element_id))
        setState(state => ({...state, answers: answers, validationErrors: {}}))
    }

    const setHiddenElement = element => {
        setState(state => ({...state, hiddenElements: {...state.hiddenElements, ...element}}))
    }

    const elements_references = {}
    const form_ref = React.useRef()
    const answers_by_code = (state.form !== null) ? getAnswersByCode(state.answers, state.form.steps) : {}
    const hasValidationErrors = Object.keys(state.validationErrors).length > 0

    return(
        <>
            <Navbar />
            {(state.loadingForm || state.loadingSession) && <Loading />}
      
            <PageNotFound active={!!state.pageNotFound}>
                {!state.loadingForm && !state.loadingSession && state.form &&
                    <ThemeProvider theme={{ 
                        mode: 'dark',
                        backgroundColor: state.form.background_color,
                        fontColor: state.form.font_color,
                        fontFace: state.form.font_face,
                        defaultLook: {
                            fontColor: "#5D2560",
                            backgroundColor: "#F1F1F1",
                            fontFace: 'Open Sans'
                        },
                        company: {
                            backgroundColor: company.background_color
                        }
                    }}>    
                        <>
                            {!state.sessionStarted && 
                                <StartPage startSession={startSession} form={state.form} />
                            }

                            {state.submissionSuccess &&
                                <FinishPage />
                            }

                            {state.sessionStarted && !state.submissionSuccess &&
                                <>
                                    {state.submittingSession && <Loading />}
                                    {!state.submittingSession &&
                                        <div className="wrapper fixed-elements form-page" css={look}>

                                            {state.showTwoStep &&
                                                <TwoStep
                                                    sessionStore={[state, setState]}
                                                    onFinish={() => {
                                                        setShowTwoStep(false) 

                                                        if(state.currentStep !== state.form.steps.length - 1) {
                                                            validateAndSendToStep(state.currentStep + 1)
                                                        } else {
                                                            finalizeSession()
                                                        }
                                                        
                                                    }}
                                                    formId={params.formId}
                                                    currentStep={state.currentStep}
                                                    elementValue={
                                                        state.answers.find(answer =>
                                                            answer.element_id === state.form.steps[state.currentStep].elements
                                                                .find(element =>
                                                                    element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                                                                    element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
                                                                )._id
                                                        ).value
                                                    }
                                                    twoStepType={
                                                        state.form.steps[state.currentStep].elements
                                                            .find(element =>
                                                                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION)
                                                            ) ? VALIDATION.PHONE_TWO_STEP_VALIDATION : VALIDATION.EMAIL_TWO_STEP_VALIDATION
                                                    }
                                                />
                                            }

                                            {state.form && state.form.proofOfLocation && !state.location &&
                                                <CollectLocation
                                                    value={state.location}
                                                    changeValue={setLocation}
                                                />
                                            }

                                            <div class="wrapper-content" ref={contentRef}>

                                                {!state.loading && state.error &&
                                                    <div className="error-msg">{state.error === 'server_error'
                                                        ?   'A aparut o eroare. Te rugam sa reincerci.'
                                                        :   'Pentru a putea salva, este nevoie sa porniti locatia din setarile dispozitivului.'
                                                    }</div>
                                                }
            
                                                {hasValidationErrors &&
                                                    <div class="error-msg">
                                                        <div class="content">
                                                            Te rugam completeaza campurile marcate cu rosu de mai jos
                                                        </div>
                                                    </div>
                                                }
            
                                                <div class="table">
                                                    <div class="table-cell">
                                                        <div className={`content ${hasValidationErrors ? 'error-anim' : ''}`} style={{minHeight: '100%'}}>
            
                                                            <form class="form">
            
                                                                {state.form.steps[state.currentStep].heading &&
                                                                    <div class="fieldset-row fieldset-row-title">
                                                                        <h1 class="main-title">{state.form.steps[state.currentStep].heading}</h1>
                                                                    </div>
                                                                }
            
                                                                {
                                                                    state.form.steps[state.currentStep].elements.map(element => {
            
                                                                        // Element not found!
                                                                        if(!Elements[element.type]) return null 
            
                                                                        const answer = state.answers.find(answer => answer.element_id === element._id)
                                                                        let value;
                                                                        if (answer !== undefined) value = answer.value
                                                                        
                                                                        return (
                                                                            <Oppression {...{
                                                                                answers_by_code,
                                                                                form: state.form,
                                                                                setHiddenElement,
                                                                                hiddenElements: state.hiddenElements,
                                                                                element
                                                                            }}>
                                                                                <div key={element._id}
                                                                                    ref={ref => elements_references[element._id] = ref}>
                                                                                    {Elements[element.type].session({
                                                                                        element,
                                                                                        answers_by_code,
                                                                                        value,
                                                                                        props: {
            
                                                                                        },
                                                                                        changeValue: value => setValue({
                                                                                            element_id: element._id,
                                                                                            value
                                                                                        }),
                                                                                        errors: state.validationErrors[element._id],
                                                                                        form: state.form,
                                                                                        newSessionStore: [state, setState]
                                                                                    })}
                                                                                </div>
                                                                            </Oppression>
                                                                        )
                                                                        
                                                                    })
                                                                }
            
            
                                                            </form>
            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
            
                                            <footer class="footer">
                                                <button class="footer-btn prev-btn" onClick={onBack}>
                                                    <i class="icon-ia-arrow-left"></i>
                                                    <span>BACK</span>
                                                </button>
            
                                                <CdeleteConfirm
                                                    title="Are you sure you want to delete all completed data?"
                                                    onConfirm={clearCurrentStep}
                                                    okText="DELETE"
                                                >
                                                    <button class="footer-btn clear-btn">
                                                        <i class="icon-refresh-cw"></i>
                                                        <span>DELETE</span>
                                                    </button>
                                                </CdeleteConfirm>
            
                                                <CpopConfirm
                                                    title="Esti sigur ca vrei sa renunti?"
                                                    onConfirm={endSession}
                                                    okText="Renunta"
                                                >
                                                    <button className="footer-btn end-btn">
                                                        <i className="icon-upload"/>
                                                        <span>DRAFT</span>
                                                    </button>
                                                </CpopConfirm>
            
                                                {state.currentStep !== state.form.steps.length -1 
            
                                                    ?   
                                                        <a class="footer-btn next-btn" href="#" onClick={e => {
                                                            e.preventDefault()
                                                            validateAndSendToStep(state.currentStep + 1)
                                                        }}>
                                                            <i class="icon-ia-arrow-right"></i>
                                                            <span>Inainte</span>
                                                        </a>
                                                        
                                                    :
                                                        <a class="footer-btn next-btn" href="#" onClick={e => {
                                                            e.preventDefault()
                                                            finalizeSession()
                                                        }}>
                                                            <i class="icon-ia-arrow-right"></i>
                                                            <span>SAVE</span>
                                                        </a>
                                                }
            
                                                <div class="footer-pagination">
                                                    <strong>{state.currentStep + 1}</strong> / {state.form.steps.length}
                                                </div>
            
                                            </footer>
            
                                        </div>
                                    }
                                </> 

                            }
                        </>
                    </ThemeProvider>
                }

                <NavigationPrompt
                    renderIfNotActive={true}
                    when={() => !state.submissionSuccess && state.answers.length !== 0}
                >
                    {({isActive, onCancel, onConfirm}) => {
                        if (isActive) {
                            return (
                                <CpopUp
                                    title="Paraseste pagina!"
                                    visible={true}
                                    onCancel={onCancel}
                                    onConfirm={onConfirm}
                                    okText="Da"
                                    overLayClass="overlay-template"
                                >
                                    <p>Toate datele completate se vor pierde. Esti sigur ca vrei sa continui?</p>
                                </CpopUp>
                            );
                        }
                        return null;
                    }}
                </NavigationPrompt>
            </PageNotFound>
        </>
    )
}

export default EditSession

