import { createStore, compose } from 'redux'
import rootReducer from "./reducers"

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store =  createStore(
  persistedReducer,
  composeEnhancer(
    //applyMiddleware(thunk)
  )
)
const persistor = persistStore(store)

export { store, persistor }
