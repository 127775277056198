import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code,
    value = []
}) => {
    
    return (
        <div className="fieldset-row">
            {element.title && <label className="session-form-box-wrap-title">{synchronizeText(element.title, answers_by_code)}</label>}
            {element.text &&<label className="session-form-box-wrap-descrition">{synchronizeText(element.text, answers_by_code)}</label>}
            <div className="session-form-row">
                <div className="session-form-grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>

                    {element.offers.map(offer => (
                        <div key={offer._id} className={`offer-row ${value.includes(offer._id) ? 'selected' : '' }`}>
                            <div className="upload-image-grid">
                                {offer.image && <div className="upload-image-box"><img src={offer.image} alt="" /></div>}
                                {offer.title && <p>{offer.title}</p>}
                                {offer.description && <p>{offer.description}</p>}
                                <i className="icon-check" />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}