import React from "react"
import { Prompt, useHistory } from "react-router-dom"

const CvavigationPrompt = ({
	when = false,
	children = () => null,
	promptCallback,
}) => {
	const [state, setState] = React.useState({
		active: false,
		confirm: false,
		nextLocation: null,
	})

	const history = useHistory()

	React.useEffect(() => {
		state.confirm && history.push(state.nextLocation.pathname)
	}, [state.confirm])

	React.useEffect(() => {
		if (state.active) {
			promptCallback({ onConfirm, onCancel })
		}
	}, [state.active])

	const onConfirm = () =>
		setState(state => ({ ...state, confirm: true, active: false }))
	const onCancel = () =>
		setState(state => ({ ...state, confirm: false, active: false }))

	return (
		<>
			<Prompt
				when={when}
				message={nextLocation => {
					if (state.confirm) {
						return true
					} else {
						setState(state => ({ ...state, active: true, nextLocation }))
						return false
					}
				}}
			/>
			{state.active && children({ onConfirm, onCancel })}
		</>
	)
}

export default CvavigationPrompt
