import React from 'react'

import API from '../../api'
import Loading from '../../components/Loading'
import {css} from 'styled-components'

const cssFixes = css`
    .form-actions button{
        width: auto;
    }
`

const ChangePassword = ({setTab, paswordTabRef}) => {

    const [state, setState] = React.useState({
        loading: false,
        password: '',
        newPassword: '',
        repeatPassword: '',
        errors: {}
    }, 'change-password')

    const setPassword = password => setState(state => ({...state, password}))
    const setNewPassword = newPassword => setState(state => ({...state, newPassword}))
    const setRepeatPassword = repeatPassword => setState(state => ({...state, repeatPassword}))

    const changePassword = async () => {
        try {
            let errors = {}
            if (state.password === "") errors.password = "Wrong password"
            else if (state.password.length < 6) errors.password = "Minimum 6 characters "

            if (state.newPassword === "") errors.newPassword = "Wrong password"
            else if (state.newPassword.length < 6) errors.newPassword = "Minimum 6 characters "

            if (state.repeatPassword === "") errors.repeatPassword = "Wrong password"
            else if (state.repeatPassword.length < 6) errors.repeatPassword = "Minimum 6 characters "
            else if (state.newPassword !== state.repeatPassword) errors.repeatPassword = "Passwords don’t match"

            setState(state => ({...state, errors}))
            if (Object.keys(errors).length > 0) return
            
            setState(state => ({...state, loading: true}))
            await API.put('users/set/new/password', {password: state.password, new_password: state.newPassword})
            setState(state => ({...state, loading: false, password: '', newPassword: '', repeatPassword: ''}))
            // message.success("Modificarile au fost salvate cu succes")
            setTab(null)
        } catch (err) {
            let errors = {}
            if (err.response.data.error) {
                if(err.response.data.error === "password_mismatch") errors.password = "Wrong password"
            }
            // else message.warning("A aparut o eroare. Va rugam reincercati.")
            setState(state => ({...state, loading: false, errors}))
        }
    }

    return(
        <div className="change-password" ref={paswordTabRef} css={cssFixes}>
            <div className="titleDesc">
                <h3>Change password</h3>
                {/* <p>Please note that when changing your password, we ask you to set your self a secure password that contains both uppercase and lowercase letters as well as numbers. This is for your own safety.</p> */}
            </div>
            {state.loading && <Loading />}
            {!state.loading &&
                <form className={`form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`} onSubmit={e => {
                    e.preventDefault()
                    changePassword()
                }}>
                    <div className="fieldset-row">
                        <div className={`form-row ${state.errors.password ? 'error' : ''}`}>
                            <label for="">OLD PASSWORD</label>
                            <input id="" type="password" placeholder="" onChange={({target:{value}}) => setPassword(value)} value={state.password}/>
                            {state.errors.password && <span className="form-row-error-msg">{state.errors.password}</span>}
                        </div>
                        <div className={`form-row ${state.errors.newPassword ? 'error' : ''}`}>
                            <label for="">NEW PASSWORD</label>
                            <input id="" type="password" placeholder="" onChange={({target:{value}}) => setNewPassword(value)} value={state.newPassword}/>
                            {state.errors.newPassword && <span className="form-row-error-msg">{state.errors.newPassword}</span>}
                        </div>
                        <div className={`form-row ${state.errors.repeatPassword ? 'error' : ''}`}>
                            <label for="">REPEAT NEW PASSWORD</label>
                            <input id="" type="password" placeholder="" onChange={({target:{value}}) => setRepeatPassword(value)} value={state.repeatPassword}/>
                            {state.errors.repeatPassword && <span className="form-row-error-msg">{state.errors.repeatPassword}</span>}
                        </div>
                    </div>
                    <div className="form-actions">
                        <button className="button with-icon-right close-box"><i className="icon-ia-checked"></i>CHANGE PASSWORD</button>
                        <span className="button button-link close-box" onClick={() => setTab(null)}>CLOSE</span>
                    </div>
                </form>
            }

        </div>
    )
}

export default ChangePassword