import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    value = {
        first_name: '',
        last_name: '',
        country: '',
        locality: '',
        street: '',
        nr: '',
        block_value: '',
        entry: '',
        floor: '',
        apartment: '',
        postal_code: ''
    },
    answers_by_code
}) => {

   return (
       <div className="fieldset-row">
           {element.text && <label>{synchronizeText(element.text, answers_by_code)}</label>}

           <div className="session-form-grid">
               <div className="session-form-grid-row">
                   <div className="session-form-row">
                       <label>Name</label>
                       <span className="input-box">{value.first_name}</span>
                   </div>
                   <div className="session-form-row">
                       <label>Surname</label>
                       <span className="input-box">{value.last_name}</span>
                   </div>
               </div>

               <div className="session-form-grid-row">
                   <div className="session-form-row">
                       <label>County</label>
                       <span className="input-box">{value.country}</span>
                   </div>

                   <div className="session-form-row">
                       <label>City</label>
                       <span className="input-box">{value.locality}</span>
                   </div>
               </div>

               <div className="session-form-grid-row street-grid">
                   <div className="session-form-row">
                       <label>Street</label>
                       <span className="input-box">{value.street}</span>
                   </div>
                   <div className="session-form-row">
                       <label>No</label>
                       <span className="input-box">{value.nr}</span>
                   </div>
               </div>

               <div className="session-form-grid-row address-grid">
                   <div className="session-form-row">
                       <label>Building</label>
                       <span className="input-box">{value.block_value}</span>
                   </div>
                   <div className="session-form-row">
                       <label>Entry</label>
                       <span className="input-box">{value.entry}</span>
                   </div>
                   <div className="session-form-row">
                       <label>Floor</label>
                       <span className="input-box">{value.floor}</span>
                   </div>
                   <div className="session-form-row">
                       <label>Ap</label>
                       <span className="input-box">{value.apartment}</span>
                   </div>
               </div>

               <div className="session-form-grid-row address-grid">
                   <div className="session-form-row">
                       <label>Postal code </label>
                       <span className="input-box">{value.postal_code}</span>
                   </div>
               </div>

           </div>
       </div>
   );
}