import React from 'react';
import RichTextViewer from '../../components/RichTextViewer'
import {synchronizeText} from "../../utils/synchronizeText"

export default ({
    element,
    answers_by_code
}) => {
    return (
        <div className="fieldset-row">
            <RichTextViewer>{synchronizeText(element.text, answers_by_code)}</RichTextViewer>
        </div>
    )
}