import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText";
import LsignaturePad from '../../components/LsignaturePad'

export default ({
    element,
    value = null,
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const onChange = value => {
        changeValue(value)
    }

    let has_error = errors.length !== 0;

    return (
        <div className="fieldset-row">
            {element.text && <h3>{synchronizeText(element.text, answers_by_code)}</h3>}
            
            <LsignaturePad value={value} onChange={onChange} />

            <div className={`form-row ${(has_error) ? 'error' : '' }`}>
                {renderErrors(errors)}
            </div>
        </div>
        
    )
}

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        if (error === 'required') {
            return <span key={error} className="form-row-error-msg">This field is required</span>;
        }

        return null;
    })
}