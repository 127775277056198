import React from 'react'

import jwt_decode from 'jwt-decode'
import API from "../../api";
import {useAppContext} from "../../context";

import {AgoogleSignIn} from "../../components/AgoogleSignIn";


export const LoginWithGoogle = () => {

    const [state, setState] = React.useState(
        {
            loading: false,
        },
        "login-with-google"
    )

    const {auth:[auth, setAuth]} = useAppContext()

    const responseError = err => {
        console.log(err)
    }

    const loginWithAuthCode = async (response) => {
        try {
            setState(state => ({...state, loading: true}));
            const { data } = await API.post('/auth/google', { code: response.code }, {
                auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
            });
            const { access_token, refresh_token } = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in  * 1000;
            const decodedToken = jwt_decode(access_token);
            setState(state => ({...state, loading: false}));
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })
        } catch(err) {
            console.log(err)
            setState(state => ({...state, loading: false }))
        }
    }

    return (
        <AgoogleSignIn
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="button button-outline with-icon-left">
                    <i className="icon-google-round"></i>
                    login with google
                </button>
            )}
            onSuccess={loginWithAuthCode}
            onFailure={responseError}
            scope="profile email openid"
        />
    )
}

