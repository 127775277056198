import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";
import {Parser} from 'hot-formula-parser'
import {VALIDATION} from '../../constants/validations'
import {addPlusPrefix} from '../../utils/phoneValidator'
import styled from "styled-components";

export const TextBoxSession = ({
    element,
    value = [""],
    changeValue,
    errors = [],
    answers_by_code,
    auth
}) => {

    return (
        (
            !element.formula && !element.multipleValues && (!element.constant || element.constant.value !== 'username') &&
            <SimpleTextBox
                element={element}
                value={value?.[0] || ""}
                setValue={value => changeValue([value])}
                answers_by_code={answers_by_code}
                errors={errors?.[0] || []}
            />
        )
        ||
        (
            element.formula &&
            <FormulaTextBox
                value={value?.[0]}
                element={element}
                answers_by_code={answers_by_code}
                setValue={value => changeValue([value])}
            />

        )
        ||
        (
            element?.constant?.value === 'username' &&
            <ConstantValueTextBox
                element={element}
                value={value}
                setValue={value => changeValue([value])}
                auth={auth}
                answers_by_code={answers_by_code}
            />
        )
        ||
        (
            element.multipleValues &&
            <MultipleValueTextBox
                values={value}
                setValues={changeValue}
                element={element}
                errors={errors}
                answers_by_code={answers_by_code}
            />
        )
    )
};

const SimpleTextBox = ({element, value, setValue, errors, answers_by_code }) => {

    const onValueChange = newValue => {
        setValue(
            !!element.validations.find(({validation}) => validation === VALIDATION.PHONE)
                ? addPlusPrefix(newValue)
                : newValue
        )

    }

    return (
        <div className="fieldset-row">
            <div className={`form-row ${(errors.length > 0) ? 'error' : ''}`}>
                {element.text &&
                    <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <input
                    placeholder={element.placeholder}
                    value={value}
                    onChange={({target:{value}}) => onValueChange(value)}
                />
                {renderErrors(errors)}
            </div>
        </div>
    )
}

const ConstantValueTextBox = ({element, value, setValue, auth, answers_by_code}) => {

    React.useEffect(() => {
        if(element?.constant?.value === 'username') {
            setValue(
                 [auth?.decodedToken?.name || "Public"]
            )
        }
    }, [element.constant]);

    return (
        <div className="fieldset-row">
            <div className="form-row">
                {element.text &&
                    <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <input
                    readOnly={element.constant.readOnly ? element.constant.readOnly : undefined}
                    value={value}
                    onChange={({target:{value}}) => setValue([value])}
                />
            </div>
        </div>
    )
}

const FormulaTextBox = ({element, value, setValue, answers_by_code}) => {

    React.useEffect(() => {
        if (element.formula) {
            const parser = new Parser();
            const formulaValue = (parser.parse(synchronizeText(element.formula, answers_by_code, true)).result || 0).toString()
            if(formulaValue !== value) setValue(formulaValue)
        }
    }, [answers_by_code])

    return (
        <div className="fieldset-row">
            <div className="form-row">
                {element.text &&
                    <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <input
                    style={{cursor: "pointer"}}
                    title="Read only formula"
                    readOnly={true}
                    placeholder={element.placeholder}
                    value={value}
                    onChange={f=>f}
                />
            </div>
        </div>
    )
}

const MultipleValueTextBox = styled(({
        className,
        element,
        values,
        setValues,
        errors: errorsList,
        answers_by_code
    }) => {

    const removeValue = valueIndex => {
        setValues(
            values.filter(
                (_, index) => index !== valueIndex
            )
        )
    }

    const addValue = () => {
        setValues(
            [...values, ""]
        )
    }

    const setItemValue = ({value, valueIndex}) => {

        setValues(
            values.map((v, vIndex) =>
                vIndex !== valueIndex
                    ? v
                    : !!element.validations.find(({validation}) => validation === VALIDATION.PHONE)
                        ? addPlusPrefix(value)
                        : value
            )
        )
    }

    return (
        <div className={className}>
            {values.map((value, valueIndex) => {

                const isLast = valueIndex === (values.length - 1)
                const errors = errorsList?.[valueIndex] || []

                return (
                    <div key={valueIndex} className="fieldset-row">
                        <div className={`form-row ${(errors.length > 0) ? 'error' : ''}`}>
                            {element.text &&
                                <label>{synchronizeText(element.text, answers_by_code)}</label>
                            }
                            <div className="input-wrapper">
                                <input
                                    placeholder={element.placeholder}
                                    value={value}
                                    onChange={({target:{value}}) => setItemValue({value, valueIndex})}
                                />
                                {isLast && <i className="icon-plus" onClick={addValue}/>}
                                {!isLast && <i className="icon-ia-delete" onClick={() => removeValue(valueIndex)}/>}
                            </div>
                            {renderErrors(errors)}
                        </div>
                    </div>
                )
            })}
        </div>
    )
})`
    display: flex;
    flex-flow: column;
    row-gap: 3rem;
  
    .input-wrapper {
      position: relative;
      
      > i {
            top: 0;
            right: 0;
            width: 3.6rem;
            height: 3.6rem;
            position: absolute;
            display: block;
            border-radius: 0.3rem;
            font-size: 2rem;
            line-height: 3.6rem;
            text-align: center;
            color: rgba(0,0,0,0.3);
            cursor: pointer;
            transition: color 0.3s ease-in-out,background 0.3s ease-in-out;
          :hover {
            color: #F1F1F1;
            background: #2C2457;;
          }
      }
      
      > input {
        padding-right: 4.8rem;
      }
    }
`

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        const error_array = error.split('|');
        switch (error_array[0]) {
            case 'required':
                return 'This field is required';
            case 'phone':
                return 'Invalid phone number';
            case 'email':
                return 'Invalid email';
            case 'number':
                return 'The filed should be a number';
            case 'integer':
                return 'The field should be an integer';
            case 'positive':
                return 'The field should be a positive number';
            case 'min length':
                return `Minimum length is ${error_array[1]}`;
            case 'max length':
                return `Maximum length is ${error_array[1]}`;
            case 'min value':
                return `Minimum value is ${error_array[1]}`;
            case 'max value':
                return `Maximum value is ${error_array[1]}`;
            case 'unique':
                return `You already have a session with this information`;
        }
    }).map(error => <span key={error} className="form-row-error-msg">{error}</span>)
};