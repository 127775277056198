export const plateValidator = (registrationPlate) => {
    registrationPlate = registrationPlate.toUpperCase();
    if (!/(^B\d{2}\d?[A-Z]{3}$)|(^[A-Z]{2}\d{2}[A-Z]{3}$)/.test(registrationPlate)) {
        return false;
    }
    const indicativeJud = [
        "AB",
        "AG",
        "AR",
        "B",
        "BC",
        "BH",
        "BN",
        "BR",
        "BT",
        "BV",
        "BZ",
        "CJ",
        "CL",
        "CS",
        "CT",
        "CV",
        "DB",
        "DJ",
        "GJ",
        "GL",
        "GR",
        "HD",
        "HR",
        "IF",
        "IL",
        "IS",
        "MH",
        "MM",
        "MS",
        "NT",
        "OT",
        "PH",
        "SB",
        "SJ",
        "SM",
        "SV",
        "TL",
        "TM",
        "TR",
        "VL",
        "VN",
        "VS"
    ];
    const splitPlate = registrationPlate.replace(registrationPlate.match(/\d+/)[0], "-" + registrationPlate.match(/\d+/)[0] + "-").split("-");
    if (!indicativeJud.includes(splitPlate[0])) {
        return false;
    }
    return parseInt(splitPlate[1]) > 0;
};