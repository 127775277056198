import React from 'react'
import styled from 'styled-components'

export default () => (

    <Wrapper>
        <div className="wrapper page-404">
            <div className="content">
                <div className="content-404">
                    <div className="table">
                        <div className="table-cell">
                            <h6>Eroare 404</h6>
                            <h2>Pagina nu a fost gasita.</h2>
                        </div>
                    </div>
                    <div className="content-404-icon"></div>
                </div>
            </div>
        </div>
    </Wrapper>
    
)

const Wrapper = styled.div`
    height: 100%;

    .page-404 .content { width:100%; height: 100%; }

    .content-404 { width:72rem; height:30rem; position:absolute; top:50%; left:50%; transform: translate(-50%,-50%); }
    .content-404:before { width:100%; height:100%; position:absolute; top:50%; left:-15%; transform: translate(0,-50%); background: transparent url(../img/404-bg.png) no-repeat 0% 50%; background-size: auto 100%; content:''; }

    .content-404 h2 { font-size:2.8rem; line-height:3.4rem; font-weight:600; color:#151515; margin-bottom:1rem; margin-top:-1rem; }
    .content-404 h6 { font-size:5.6rem; line-height:6rem; font-weight:900; color:#8579e1; margin-bottom:1.5rem; }
    .content-404 .table-cell { padding-left:27rem; }

    .content-404-icon { width:20rem; height:16rem; position:absolute; top:50%; left:0; transform: translate(0,-50%); background: transparent url(../img/404-icon.svg) no-repeat 50% 50%; background-size: 100% auto; }

`

