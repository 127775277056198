export const getError = (response) => {
    if (response === undefined) {
        return 'server_error';
    }

    if (response.data !== undefined && response.data.error !== undefined) {
        return response.data.error;
    }

    return 'server_error';
};