import React from 'react';
import { Route, Switch } from 'react-router-dom'

import Forms from './pages/Forms/Forms'
import Login from './pages/Login/Login'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import NewSession from "./pages/NewSession/NewSession"
import NewPublicSession from './pages/NewPublicSession/NewPublicSession'
import PageNotFound from './pages/PageNotFound'
import Profile from './pages/Profile/Profile'
import Sessions from './pages/Sessions/Sessions'
import EditSession from './pages/EditSession/EditSession'
import ViewSession from './pages/ViewSession/ViewSession'
import PublicSessionWithInvitation from './pages/NewPublicSessionWithInvitation/NewPublicSessionWithInvitation'
import {FilePreview} from './pages/FilePreview'

import {Invitation} from "./pages/Login/Invitation/Invitation";
import {RedirectUri} from "./pages/Login/Invitation/Redirect";

import {useAppContext} from "./context"
import API from './api'
import SessionMaintainer from './components/SessionMaintainer'

const AuthorisedRoute = props => {
  const {auth:[auth]} = useAppContext()
  return auth ? <Route {...props} /> : <Login/>
}


const App = () => {

  const {company:[,setCompany]} = useAppContext()

  const getApplicationSettings = async () => {
    try {
      setCompany(company => ({...company, loading: true}))
      const {data} = await API.get('/company/application-settings')
      setCompany(company => ({...company, ...data}))
    } catch(err) {
      setCompany(company => ({...company, error: 'Something went wrong'}))
    }
  }

  React.useEffect(() => {
    getApplicationSettings()
  }, [])

  return (
    <>
      <SessionMaintainer />
      <Switch>
        <Route path="/login" component={Login} exact/>
        <Route path="/invitation/:provider" exact component={Invitation} />
        <Route path="/invitation/:provider/redirect" exact component={RedirectUri} />
        <Route path="/file-preview" component={FilePreview} exact/>
        {/* <Route path="/auth/reset-password/token/:token" component={ResetPassword} exact/> */}
        <AuthorisedRoute path="/" exact component={Forms} />
        <AuthorisedRoute path="/forms/:formId/session" exact component={NewSession} />
        <Route path="/public/forms/:formId/session" exact component={NewPublicSession} />
        <Route path="/forms/:formId/sessions/:sessionId/public/:secret" exact component={PublicSessionWithInvitation} />
        <AuthorisedRoute path="/profile" exact component={Profile} />
        <AuthorisedRoute path="/sessions" exact component={Sessions} />
        <AuthorisedRoute path="/forms/:formId/sessions/:sessionId/edit" exact component={EditSession} />
        <AuthorisedRoute path="/sessions/:sessionId" exact component={ViewSession} />
        <Route path="/:formId" exact component={NewPublicSession} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  )
}

export default App
