import React from "react";
import styled from "styled-components";
import {InvitationWithGoogle} from "./InvitationWithGoogle";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {P_API} from "../../../api";
import Loading from "../../../components/Loading";

const Invitation = styled(({className}) => {

    const [state, setState] = React.useState({
        errors: {},
        invitation: null,
        loading: true
    })

    const {provider} = useParams()
    const token = new URLSearchParams(useLocation().search).get('token')
    const history = useHistory()

    React.useEffect(() => {
        validateInvitation()
    }, [])


    const validateInvitation = async () => {
        try {
            const response = await P_API.get(`/users/login-invitations?token=${token}`,
            {
                    auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
                }
            )
            setState(state => ({...state, invitation: response.data, loading: false}))
        } catch(err) {
            if (err?.response?.data?.error === 'invitation_not_found') {
                setState(state => ({...state, loading: false}))
                history.push('/404')
            }
            throw err;
        }
    }

    return (
        <div className={className}>
            {
                state.loading
                ?   <Loading />
                :   (
                    <div
                        className={`wrapper login-page`}
                    >
                        <div className="table-cell">
                            <div className="logo-box">
                                <img src='AlchemistClient/img/Alchemist_Identity_Final-08.png' alt="" />
                            </div>

                            {state.invitation && {
                                'google': <InvitationWithGoogle invitation={state.invitation}/>
                            }[provider]}
                        </div>
                    </div>
                )

            }

        </div>
    )
})`
  display: contents;
`

export {Invitation}


