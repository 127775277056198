import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

export default ({
    children,
    visible=false,
    onCancel=f=>f
}) => {

    return(
        <>
            {ReactDOM.createPortal((
                <>
                    {visible &&
                        <Container>
                            <Overlay onClick={onCancel} />
                            <Wrapper>
                            <CloseCros className="icon-x" onClick={onCancel} />
                                <Body>
                                    {children}
                                </Body>
                            </Wrapper>
                        </Container>
                    }
                </>
            ), document.querySelector("#modal"))}
        </>
    )
}

const Container = styled.div`
    display: grid;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
`

const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(255,255,255,0.8);
    width: 100%;
    height: 100%;
    animation: ${keyframes`
        0%   { background: rgba(255,255,255,0); }
        100% { background: rgba(255,255,255,0.8) }
    `} 0.5s 1;
`

const Wrapper = styled.div`
    position: absolute;
    justify-self: center;
    align-self: center;
    z-index: 2;
    width: 28rem;
    max-width: 100%;
    box-shadow: 0 0 1rem rgba(114,124,245,.4);
    background: #f7f8fe;

    animation: ${keyframes`
        0%   { transform: scale(0) }
        100% { transform: scale(initial) }
    `} .5s 1;
`

const CloseCros = styled.i`
    top: .5rem;
    right: .5rem;

    width: 4rem;
    height: 4rem;
    position: absolute;
    display: block;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: center;
    color: rgba(0,0,0,0.3);
    cursor: pointer;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    :hover{
        color: #fff;
        background: #727cf5;
    }
`

const Body = styled.div`
    width: 100%;
    /* padding: 2rem 3rem; */
`