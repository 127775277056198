import React from "react"
import styled from "styled-components"

import { synchronizeText } from "../../utils/synchronizeText"

const exteriorModal = {
	zgariat: false,
	crapat: false,
	indoit: false,
	ruginit: false,
	cost: 0,
}

const interiorModal = {
	zgariat: false,
	patat: false,
	rupt: false,
	foarte: false,
	cost: 0,
}

const initialValue = {
	exterior: {
		baraFata: exteriorModal,
		baraFataStanga: exteriorModal,
		baraFataDreapta: exteriorModal,
		grilaFata: exteriorModal,
		capotaFata: exteriorModal,
		farDreapta: exteriorModal,
		farStanga: exteriorModal,

		parbriz: exteriorModal,
		aripaStangaFata: exteriorModal,
		jantaStangaFata: exteriorModal,
		oglindaStangaFata: exteriorModal,
		portieraStangaFata: exteriorModal,

		portieraStangaSpate: exteriorModal,
		pragaStanga: exteriorModal,
		jantaStangaSpate: exteriorModal,
		aripaStangaSpate: exteriorModal,
		baraSpate: exteriorModal,
		baraSpateStanga: exteriorModal,
		baraSpateDreapta: exteriorModal,

		capotaSpate: exteriorModal,
		luneta: exteriorModal,
		stopStanga: exteriorModal,
		stopDreapta: exteriorModal,
		aripaDreaptaSpate: exteriorModal,

		jantaDreaptaSpate: exteriorModal,
		portieraDreapta: exteriorModal,
		portieraDreaptaFata: exteriorModal,
		pragDreapta: exteriorModal,
		plafonDreapta: exteriorModal,
		plafonStanga: exteriorModal,

		oglindaDreapta: exteriorModal,
		jantaDreaptaFata: exteriorModal,
		aripaDreaptaFata: exteriorModal,
		stalpi: {
			checked: false,
			text: "",
		},

		lonjeroane: {
			checked: false,
			text: "",
		},

		observatii: {
			checked: false,
			text: "",
		},
	},
	interior: {
		pedale: interiorModal,
		volan: interiorModal,
		schimbatorViteze: interiorModal,
		butoanePlansaBord: interiorModal,
		butoaneMultimedia: interiorModal,

		butoanePortiere: interiorModal,
		cotiera: interiorModal,
		plansaBord: interiorModal,
		plafon: interiorModal,
		scaunSofer: interiorModal,

		scaunPasager: interiorModal,
		banchetaSpate: interiorModal,
		centuriSiguranta: interiorModal,
		capitonajeUsi: interiorModal,
		covorase: interiorModal,

		mirosNeplacut: {
			checked: false,
			text: "",
		},
		podea: {
			checked: false,
			text: "",
		},
		tapiserie: {
			checked: false,
			text: "",
		},
		observatii: {
			checked: false,
			text: "",
		},
	},
	pornireMotor: {
		martoriImportanti: {
			checked: false,
			text: "",
		},
		alteAlerteActive: {
			checked: false,
			text: "",
		},
		zgomoteMotor: {
			checked: false,
			text: "",
		},
		zgomoteTransmisie: {
			checked: false,
			text: "",
		},
		observatii: {
			checked: false,
			text: "",
		},
	},
	accesorii: {
		bareTransversale: {
			checked: false,
			text: "",
		},
		bullBar: {
			checked: false,
			text: "",
		},
		cameraVideoParbiz: {
			checked: false,
			text: "",
		},
		carligTractare: {
			checked: false,
			text: "",
		},
		pachetSiguranta: {
			checked: false,
			text: "",
		},
		portBagajSuplimentar: {
			checked: false,
			text: "",
		},
		praguriInox: {
			checked: false,
			text: "",
		},
		suportBiciclete: {
			checked: false,
			text: "",
		},
		suportSchiuri: {
			checked: false,
			text: "",
		},
		alteAcessorii: {
			checked: false,
			text: "",
		},
	},
}

export default ({ element, answers_by_code, value = initialValue }) => {
	return (
		<div className="fieldset-row">
			{element.title && (
				<label>{synchronizeText(element.title, answers_by_code)}</label>
			)}
			<Exterior value={value.exterior} />
			<Interior value={value.interior} />
			<Motor value={value.pornireMotor} />
			<Accesori value={value.accesorii} />
		</div>
	)
}

const Accesori = ({ value }) => {
	const {
		bareTransversale,
		bullBar,
		cameraVideoParbiz,
		carligTractare,
		pachetSiguranta,
		portBagajSuplimentar,
		praguriInox,
		suportBiciclete,
		suportSchiuri,
		alteAcessorii,
	} = value
	return (
		<>
			<h6 class="sup-title">Accesorii</h6>
			<ul class="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Bare transversale ( inclusiv cheie )</strong>
						</li>
						<li>
							<strong>
								{bareTransversale.checked && <i class="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{bareTransversale.checked && (
						<p class="paragraph">{bareTransversale.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Bull bar</strong>
						</li>
						<li>
							<strong>{bullBar.checked && <i class="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{bullBar.checked && <p class="paragraph">{bullBar.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Camera video parbriz</strong>
						</li>
						<li>{cameraVideoParbiz.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{cameraVideoParbiz.checked && (
						<p class="paragraph">{cameraVideoParbiz.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Carlig tractare</strong>
						</li>
						<li>{carligTractare.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{carligTractare.checked && <p class="paragraph">{carligTractare.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Pachet siguranta ( extinctor, trusa medicala )</strong>
						</li>
						<li>{pachetSiguranta.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{pachetSiguranta.checked && (
						<p class="paragraph">{pachetSiguranta.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Portbagaj suplimentar ( inclusiv cheie )</strong>
						</li>
						<li>{portBagajSuplimentar.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{portBagajSuplimentar.checked && (
						<p class="paragraph">{portBagajSuplimentar.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Praguri inox</strong>
						</li>
						<li>{praguriInox.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{praguriInox.checked && <p class="paragraph">{praguriInox.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Suport biciclete</strong>
						</li>
						<li>{suportBiciclete.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{suportBiciclete.checked && (
						<p class="paragraph">{suportBiciclete.text}</p>
					)}
				</li>

				<li>
					<ul>
						<li>
							<strong>Alte accesorii importante</strong>
						</li>
						<li>{alteAcessorii.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{alteAcessorii.checked && <p class="paragraph">{alteAcessorii.text}</p>}
				</li>
			</ul>
		</>
	)
}

const Motor = ({ value }) => {
	const {
		martoriImportanti,
		alteAlerteActive,
		zgomoteMotor,
		zgomoteTransmisie,
		observatii,
	} = value
	return (
		<>
			<h6 class="sup-title">Pornire Motor</h6>
			<ul class="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Martori importanti aprinsi</strong>
						</li>
						<li>
							<strong>
								{martoriImportanti.checked && <i class="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{martoriImportanti.checked && (
						<p class="paragraph">{martoriImportanti.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte alerte active</strong>
						</li>
						<li>
							<strong>
								{alteAlerteActive.checked && <i class="icon-ia-checked" />}
							</strong>
						</li>
					</ul>
					{alteAlerteActive.checked && (
						<p class="paragraph">{alteAlerteActive.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Probleme / Zgomote motor</strong>
						</li>
						<li>{zgomoteMotor.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{zgomoteMotor.checked && <p class="paragraph">{zgomoteMotor.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>
								Probleme / Zgomote transmisie ( cutie de viteze si diferential )
							</strong>
						</li>
						<li>{zgomoteTransmisie.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{zgomoteTransmisie.checked && (
						<p class="paragraph">{zgomoteTransmisie.text}</p>
					)}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte avarii / Observatii</strong>
						</li>
						<li>{observatii.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{observatii.checked && <p class="paragraph">{observatii.text}</p>}
				</li>
			</ul>
		</>
	)
}

const Interior = ({ value }) => {
	const { mirosNeplacut, podea, tapiserie, observatii, ...others } = value

	others.pedale.name = "Pedale"
	others.volan.name = "Volan"
	others.schimbatorViteze.name = "Schimbator viteze"
	others.butoanePlansaBord.name = "Butoane plansa bord"
	others.butoaneMultimedia.name = "Butoane multimedia"

	others.butoanePortiere.name = "Butoane portiere / geamuri"
	others.cotiera.name = "Cotiera"
	others.plansaBord.name = "Plansa bord ( incl .torpedou )"
	others.plafon.name = "Plafon"
	others.scaunSofer.name = "Scaun sofer"

	others.scaunPasager.name = "Scaun pasager"
	others.banchetaSpate.name = "Bancheta spate"
	others.centuriSiguranta.name = "Centuri siguranta"
	others.capitonajeUsi.name = "Capitonaje usi"
	others.covorase.name = "Covorase"

	return (
		<>
			<h6 class="sup-title">Interior</h6>
			<ul class="table-ul preview-table">
				<li class="table-ul-header">
					<ul>
						<li>
							<strong>Avariat</strong>
						</li>
						<li>
							<strong>Zgariat</strong>
						</li>
						<li>
							<strong>Patat</strong>
						</li>
						<li>
							<strong>Rupt</strong>
						</li>
						<li>
							<strong>Foarte Uzat</strong>
						</li>
						<li>
							<strong>Cost EUR</strong>
						</li>
					</ul>
				</li>
				{Object.values(others).map(
					({ name, zgariat, patat, rupt, foarte, cost }) => (
						<li>
							<ul>
								<li>
									<strong>{name}</strong>
								</li>
								<li>
									<strong>{zgariat && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>
									<strong>{patat && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>
									<strong>{rupt && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>
									<strong>{foarte && <i class="icon-ia-checked" />}</strong>
								</li>		
                                <li>{cost > 0 && <strong>{cost}€</strong>}</li>
							</ul>
						</li>
					)
				)}
			</ul>
			<ul class="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Miros neplacut</strong>
						</li>
						<li>
							<strong>{mirosNeplacut.checked && <i class="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{mirosNeplacut.checked && <p class="paragraph">{mirosNeplacut.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Podea / Tapiserie cu urme de umezeala</strong>
						</li>
						<li>
							<strong>{podea.checked && <i class="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{podea.checked && <p class="paragraph">{podea.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Stare generala tapiserie</strong>
						</li>
						<li>{tapiserie.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{tapiserie.checked && <p class="paragraph">{tapiserie.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Alte avarii / Observatii</strong>
						</li>
						<li>{observatii.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{observatii.checked && <p class="paragraph">{observatii.text}</p>}
				</li>
			</ul>
		</>
	)
}

const Exterior = ({ value }) => {
	const { stalpi, lonjeroane, observatii, ...others } = value

	const exteriorModal = {
		zgariat: false,
		crapat: false,
		indoit: false,
		ruginit: false,
		cost: 0,
	}

	;[
		"baraFataStanga",
		"baraFataDreapta",
		"baraSpateStanga",
		"baraSpateDreapta",
		"plafonDreapta",
		"plafonStanga",
	].forEach(key => {
		if (!others[key]) others[key] = { ...exteriorModal }
	})

	others.baraFata.name = "Bara fata"
	others.baraFataStanga.name = "Bara fata stanga"
	others.baraFataDreapta.name = "Bara fata dreapta"
	others.grilaFata.name = "Grila fata"
	others.capotaFata.name = "Capota fata"
	others.farDreapta.name = "Far dreapta"
	others.farStanga.name = "Far stanga"

	others.parbriz.name = "Parbriz"
	others.aripaStangaFata.name = "Aripa stanga fata"
	others.jantaStangaFata.name = "Janta stanga fata"
	others.oglindaStangaFata.name = "Oglinda stanga fata"
	others.portieraStangaFata.name = "Portiera stanga fata"

	others.portieraStangaSpate.name = "Portiera stanga spate"
	others.pragaStanga.name = "Prag stanga"
	others.jantaStangaSpate.name = "Janta stanga spate"
	others.aripaStangaSpate.name = "Aripa stanga spate"
	others.baraSpate.name = "Bara spate"
	others.baraSpateStanga.name = "Bara spate stanga"
	others.baraSpateDreapta.name = "Bara spate dreapta"

	others.capotaSpate.name = "Capota spate"
	others.luneta.name = "Luneta"
	others.stopStanga.name = "Stop stanga"
	others.stopDreapta.name = "Stop dreapta"
	others.aripaDreaptaSpate.name = "Aripa dreapta spate"

	others.jantaDreaptaSpate.name = "Janta dreapta spate"
	others.portieraDreapta.name = "Portiera dreapta spate"
	others.portieraDreaptaFata.name = "Portiera dreapta fata"
	others.pragDreapta.name = "Prag dreapta"
	others.plafonDreapta.name = "Plafon Dreapta"
	others.plafonStanga.name = "Plafon Stanga"

	others.oglindaDreapta.name = "Oglinda dreapta"
	others.jantaDreaptaFata.name = "Janta dreapta fata"
	others.aripaDreaptaFata.name = "Aripa dreapta fata"

	return (
		<>
			<h6 class="sup-title">Exterior</h6>
			<ul class="table-ul preview-table">
				<li class="table-ul-header">
					<ul>
						<li>
							<strong>Avariat</strong>
						</li>
						<li>
							<strong>Zgariat</strong>
						</li>
						<li>
							<strong>Crapat</strong>
						</li>
						<li>
							<strong>Indoit</strong>
						</li>
						<li>
							<strong>Ruginit</strong>
						</li>
						<li>
							<strong>Cost EUR</strong>
						</li>
					</ul>
				</li>
				{Object.values(others).map(
					({ name, zgariat, crapat, indoit, ruginit, cost }) => (
						<li>
							<ul>
								<li>
									<strong>{name}</strong>
								</li>
								<li>
									<strong>{zgariat && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>
									<strong>{crapat && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>
									<strong>{indoit && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>
									<strong>{ruginit && <i class="icon-ia-checked" />}</strong>
								</li>
								<li>{cost > 0 && <strong>{cost}€</strong>}</li>
							</ul>
						</li>
					)
				)}
			</ul>
			<ul class="table-ul preview-table preview-table-2cols">
				<li>
					<ul>
						<li>
							<strong>Stalpi / Montanti</strong>
						</li>
						<li>
							<strong>{stalpi.checked && <i class="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{stalpi.checked && <p class="paragraph">{stalpi.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Lonjeroane</strong>
						</li>
						<li>
							<strong>{lonjeroane.checked && <i class="icon-ia-checked" />}</strong>
						</li>
					</ul>
					{lonjeroane.checked && <p class="paragraph">{lonjeroane.text}</p>}
				</li>
				<li>
					<ul>
						<li>
							<strong>Slte avarii / observatii</strong>
						</li>
						<li>{observatii.checked && <i class="icon-ia-checked" />}</li>
					</ul>
					{observatii.checked && <p class="paragraph">{observatii.text}</p>}
				</li>
			</ul>
		</>
	)
}

const SectionContainer = styled.div`
	border-bottom: 1px dashed black;
	padding: 50px 0 50px 0;

	.ant-radio-wrapper {
		padding: 5px;
		display: inline-block;
		width: auto;

		span {
			color: rgba(0, 0, 0, 0.65);
			font-size: 1rem;
			font-variant: tabular-nums;
			line-height: 1.5;
			list-style: none;
			font-feature-settings: "tnum", "tnum";
		}
		.ant-radio-inner::after {
			top: 2px;
			width: 10px;
			height: 10px;
			left: 2px;
		}
	}
`
