import React from 'react'
import IsEmail from 'isemail'

import { getError } from "../../utils/getError"
import API from '../../api'
import Loading from '../../components/Loading'

import styled from 'styled-components'

const ForgotPassword = ({setTab}) => {
    const [state, setState] = React.useState({
        email: '',
        loading: false,
        errors: {}
    }, 'forgot-password')

    const setEmail = email => setState(state => ({...state, email}))

    const recover = async () => {
        const errors = {}
        const {email} = state
        if(email === '') errors.email = "Emailul este obligatoriu"
        if(!IsEmail.validate(email)) errors.email = "Invalid email"
        setState(state => ({...state, errors}))
		if(Object.keys(errors).length > 0) return

        try {
            setState(state => ({...state, loading: true}))
            await API.post('/auth/forgot-password', {email, source: 'client'});
            setState(state => ({...state, loading: false}))
            setTab("FORGOT_PASSWORD_MESSAGE")
        } catch (err) {
            const errors = {}
            const error = getError(err.response)

            if(error === 'invalid_email') errors.email = "Invalid email"
            else if(error === 'user_not_found') errors.email =  "User not found"
            else errors.other = "Something went wrong"

            setState(state => ({...state, loading: false, errors}))
        }
    }

    return(
        <ForgotPasswordStyledWrapper className="forgot-pass-box">
            <div className="forgot-pass-header">
                <span className="button button-link with-icon-left hide-forgot-pass" onClick={() => setTab("LOGIN")}>
                    <i className="icon-ia-arrow-bottom"></i>BACK TO LOGIN
                </span>
            </div>
            {state.loading && <Loading />}
            {!state.loading &&             
                <div className="forgot-pass-content">
                    <div className="forgot-pass-content-wrapper">
                        <div className="forgot-pass-content-tab">
                            <div className="scrollbar">
                                <div className="table">
                                    <div className="table-cell">
                                        <form className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`} onSubmit={e => {
                                            e.preventDefault()
                                            recover()
                                        }}>
                                            <h3>Forgot password?</h3>
                                            {/* <p>
                                                Enter the email address associated with your account. We’ll email
                                                you a link to reset your password.
                                            </p> */}
                                            <div className={`form-row ${state.errors.email ? 'error' : ''}`}>
                                                <input
                                                    id="form-name"
                                                    type="email"
                                                    placeholder="Email"
                                                    onChange={({target:{value}}) => setEmail(value)}
                                                    value={state.email}
                                                />

                                            </div>
                                            {Object.keys(state.errors).length > 0 &&
                                                <div className="error-msg">{Object.values(state.errors)[0]}</div>
                                            }
                                            <div className="form-row last">
                                                <button className="button with-icon-right">
                                                    <i className="icon-ia-arrow-right"></i>Send
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="forgot-pass-content-tab">
                            <div className="table">
                                <div className="table-cell">
                                    <div className="form login-form forgot-pass-message">
                                        <h3>
                                            <i className="icon-mail"></i>Check your email
                                        </h3>
                                        <p>
                                            We have sent you an email to <strong>{state.email}</strong> with the reset password link.
                                        </p>
                                        <p>
                                            <span className="hide-forgot-pass" onClick={() => setTab('LOGIN')}>BACK TO LOGIN</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </ForgotPasswordStyledWrapper>
    )
}

const ForgotPasswordStyledWrapper = styled.div`
  .error-msg {
    margin-top: 0;
  }
`

export default ForgotPassword