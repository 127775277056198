import React from 'react'
import styled from 'styled-components'
import Loading from "../../../components/Loading";

import {P_API} from "../../../api";

import {AgoogleSignIn} from "../../../components/AgoogleSignIn";
import jwt_decode from "jwt-decode";
import {useAppContext} from "../../../context";
import {useHistory} from "react-router-dom";

const InvitationWithGoogle = styled(({invitation, className}) => {

    const [state, setState] = React.useState({
        loading: false,
        errors: {},
        activate: false
    }, "LoginForm")

    const {auth:[auth, setAuth]} = useAppContext()

    const history = useHistory()

    const onGoogleSignInSuccess = async ({code}) => {
        let idToken;
        try {
            idToken = await activateAccount({code})
        } catch(err) {
            if(err?.response?.data?.error === 'email_taken') {
                setState(state => ({
                    ...state,
                    loading: false,
                    errors: {activateAccount: "User with this email already exists!"}
                }))
                return;
            }

            if(err?.response?.data?.error === 'different_email') {
                setState(state => ({
                    ...state,
                    loading: false,
                    errors: {activateAccount: "Email different than email in invitation!"}
                }))
                return;
            }
        }


        try {
            await loginWithIdToken({idToken})
            history.push('/')
        } catch(err) {

        }
    }

    const loginWithIdToken = async ({idToken}) => {
        try {
            setState(state => ({...state, loading: true}));
            const { data } = await P_API.post('/auth/google', { idToken },
                {
                    auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
                }
            );
            const { access_token, refresh_token } = data;
            const expirationTime = Date.now() + data.expires_in * 1000;
            const refreshExpirationTime = Date.now() + data.refresh_token_expires_in  * 1000;
            const decodedToken = jwt_decode(access_token);
            setState(state => ({...state, loading: false}));
            setAuth({
                access_token,
                expirationTime,
                refreshExpirationTime,
                refresh_token,
                decodedToken
            })
        } catch(err) {
            console.log(err)
            setState(state => ({...state, loading: false }))
        }
    }


    const activateAccount = async ({code}) => {
        try {
            const response = await P_API.put(`/users/login-invitations`, {
                code,
                token: invitation.token,
            },
                {
                    auth: {username: process.env.REACT_APP_API_KEY, password: process.env.REACT_APP_API_SECRET }
                }
            )
            return response.data.idToken;
        } catch(err) {
            throw err;
        }
    }

    const onFailure = err => {
        console.log(err)
    }

    return (
        <div className={className}>

            <div className="login-box">
                {state.loading && <Loading />}
                {!state.loading && (
                    <div className="login-tabs-content">
                        <div className="login-tab-content">
                            <div className="scrollbar">
                                <div className="table">
                                    <div className="table-cell">
                                        <form
                                            className={`form login-form ${
                                                Object.keys(state.errors).length > 0 ? "error-anim" : ""
                                            }`}
                                        >

                                            <h3 style={{textAlign: 'center'}}>Welcome!</h3>
                                            <p>Please click on the below button to activate your account.</p>

                                            <div className="form-row">
                                                <AgoogleSignIn
                                                    clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                                                    render={renderProps => (
                                                        <button
                                                            onClick={renderProps.onClick}
                                                            disabled={renderProps.disabled}
                                                            type="button"
                                                            className="button with-icon-left google-sign-in-button"
                                                        >
                                                            <i className="icon-google-round"></i>
                                                            Activate account
                                                        </button>
                                                    )}
                                                    onSuccess={onGoogleSignInSuccess}
                                                    onFailure={onFailure}
                                                    scope="profile email openid"
                                                    loginHint={invitation.email}
                                                />
                                            </div>

                                            {(state.errors.activateAccount) && (
                                                <div className="error-msg">{state.errors.activateAccount}</div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
})`
   display: contents;
  
  .form {
    .error-msg {
      margin-top: 0;
    }
  }
  
  .form-row {
    text-align: center;
  }
  
  .google-sign-in-button {
    margin-top: 1.5rem;
    width: auto;

    i {
      width: 3rem;
      font-size: 1.8rem;
      margin-left: -1.2rem;
    }
  }
`


export {InvitationWithGoogle}