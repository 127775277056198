import React from "react"
import {useLocation} from 'react-router-dom'

import API from '../../api'
import { getError } from "../../utils/getError"
import Loading from "../../components/Loading"

const ChangePassword = ({ setTab }) => {
	const [state, setState] = React.useState({
		loading: false,
		password: "",
        repeatPassword: "",
        errors: {}
    }, 'change-password')
    
    const queryParams = new URLSearchParams(useLocation().search)
    const token = queryParams.get('passwordResetToken')

	const setPassword = password => setState(state => ({ ...state, password }))
	const setRepeatPassword = repeatPassword =>
        setState(state => ({ ...state, repeatPassword }))
    
    const resetPassword = async () => {

        const errors = {}
        const { password, repeatPassword } = state
        if(password === '') errors.password = 'Password este obligatoriu'
        if(repeatPassword === '') errors.repeatPassword = 'New Password este obligatoriu'
        if (password.length < 6) errors.password = 'Minimum 6 characters'
        if (repeatPassword.length < 6) errors.repeatPassword = 'Minimum 6 characters'
        if(password !== repeatPassword) {
            errors.password = 'Passwords don’t match'
            errors.repeatPassword = 'Passwords don’t match'
        }
        setState(state => ({ ...state, errors }))
		if (Object.keys(errors).length > 0) return
        
        try {
            setState(state => ({...state, loading: true}))
            await API.post('/auth/reset-password', { token, password })
            setState(state => ({...state, loading: false}))
            setTab('CHANGE_PASSWORD_MESSAGE')
        } catch(err) {
            const errors = {}
            const error = getError(err.response)

            if(error === 'invalid_token') errors.other = 'Token was not found'
            else if(error === 'token_expired') errors.other = 'Cererea a expirat'
            else if(error === 'user_not_found') errors.other = 'User not found'
            else errors.other = 'Something went wrong'

            setState(state => ({...state, errors, loading: false}))
        }
    }

	return (
		<div className="change-pass-box">
			<div className="forgot-pass-header">
				<span
					className="button button-link with-icon-left hide-forgot-pass"
					onClick={() => setTab("LOGIN")}
				>
					<i className="icon-ia-arrow-bottom"></i>BACK TO LOGIN
				</span>
			</div>
            {state.loading && <Loading />}
            {!state.loading &&            
                <div className="forgot-pass-content">
                    <div className="forgot-pass-content-wrapper">
                        <div className="forgot-pass-content-tab">
                            <div className="scrollbar">
                                <div className="table">
                                    <div className="table-cell">
                                        <form className={`form login-form ${Object.keys(state.errors).length > 0 ? 'error-anim' : ''}`} onSubmit={e => {
                                            e.preventDefault()
                                            resetPassword()
                                        }}>
                                            <h3>Change password</h3>
                                            {/* <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry
                                            </p> */}
                                            <div className={`form-row ${state.errors.password ? 'error' : ''}`}>
                                                <label>New password</label>
                                                <input
                                                    id="form-name"
                                                    type="password"
                                                    placeholder=""
                                                    value={state.password}
                                                    onChange={({ target: { value } }) => setPassword(value)}
                                                />
                                            </div>
                                            <div className={`form-row ${state.errors.repeatPassword ? 'error' : ''}`}>
                                                <label>Repeat new password</label>
                                                <input
                                                    id="form-name"
                                                    type="password"
                                                    placeholder=""
                                                    value={state.repeatPassword}
                                                    onChange={({ target: { value } }) => setRepeatPassword(value)}
                                                />
                                            </div>
                                            
                                            {Object.keys(state.errors).length > 0 &&
                                                <div className="error-msg" style={{marginTop: '1rem'}}>{Object.values(state.errors)[0]}</div>
                                            }
                                            
                                            <div className="form-row last">
                                                <button className="button with-icon-right">
                                                    <i className="icon-ia-checked-outline"></i>SAVE
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="forgot-pass-content-tab">
                            <div className="table">
                                <div className="table-cell">
                                    <div className="form login-form forgot-pass-message">
                                        <h3>
                                            <i className="icon-check-circle"></i>Your password was successfully changed
                                        </h3>
                                        <p>
                                            <span className="hide-forgot-pass" onClick={() => setTab('LOGIN')}>BACK TO LOGIN</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

		</div>
	)
}

export default ChangePassword
