import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api'

import {useAppContext} from '../../context'

export default ({
    element,
    answers_by_code,
    value = null
}) => {
    return (
        <div className="fieldset-row">
            {element.title && <h3>{synchronizeText(element.title, answers_by_code)}</h3>}
            {element.text &&<p>{synchronizeText(element.text, answers_by_code)}</p>}
            <div className="location-box">
                <div id="map">
                    <MapComponent value={value}/>
                </div>
            </div>
        </div>
    );
}

const defaultLocation = {
    lat: 45.9237859,
    lng: 22.7775086
}

const MapComponent = ({value}) => {

    const {
        company:[company]
    } = useAppContext()

    const location = (value && value.location && value.location.coordinates && {lat: value.location.coordinates[1], lng: value.location.coordinates[0]}) || null

    return(
        <LoadScript
            googleMapsApiKey={company.google_maps_api_key}
        >
            <GoogleMap
                mapContainerStyle={{
                    height: "100%",
                    width: "100%"
                }}
                zoom={12}
                center={
                    (value && value.location && value.location.coordinates && {lat: value.location.coordinates[1], lng: value.location.coordinates[0]}) 
                    || defaultLocation
                }
                options={{
                    mapTypeControl: false
                }}
            >
                {value && 
                    <Marker
                        position={
                            (value.location && value.location.coordinates && {lat: value.location.coordinates[1], lng: value.location.coordinates[0]})
                            || defaultLocation
                        }
                        icon={'img/pin.png'}
                    >
                        <InfoWindow>
                            <span>{value.address || ""}</span>
                        </InfoWindow>
                    </Marker>
                }
            </GoogleMap>
        </LoadScript>
    )
}