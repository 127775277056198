import React, {useEffect} from 'react'
import {AutoComplete} from 'antd'
import debounce from 'lodash/debounce';

import {synchronizeText} from "../../utils/synchronizeText";
import {Country, City} from '../../components/CountryCity'
import {getError} from "../../utils/getError"
import API from '../../api'
import CdeleteConfirm from '../../components/CdeleteConfirm'
import {addPlusPrefix} from '../../utils/phoneValidator'

export default ({
    element,
    answers_by_code,
    changeValue,
    errors = [],
    ...otherProps
}) => {

    const value = {
        ...otherProps.value,
        id: otherProps.value && otherProps.value.id || '',
        first_name: otherProps.value && otherProps.value.first_name || '',
        last_name: otherProps.value && otherProps.value.last_name || '',
        sms_number: otherProps.value && otherProps.value.sms_number || '',
        email: otherProps.value && otherProps.value.email || '',
        country: otherProps.value && otherProps.value.country || '',
        locality: otherProps.value && otherProps.value.locality || '',
        street: otherProps.value && otherProps.value.street || '',
        nr: otherProps.value && otherProps.value.nr || '',
        block_value: otherProps.value && otherProps.value.block_value || '',
        entry: otherProps.value && otherProps.value.entry || '',
        floor: otherProps.value && otherProps.value.floor || '',
        apartment: otherProps.value && otherProps.value.apartment || ''
    }

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        clients: []
    })

    // useEffect(() => {
    //     getClientSuggestionsDebounced(value.first_name)
    // }, [value.first_name])

    // const getClientSuggestionsDebounced = debounce((first_name) => {
    //     getClientSuggestions(first_name)
    // }, 300)

    // const getClientSuggestions = async name => {
    //     try {
    //         setState(state => ({...state, loading: true, error: null}))
    //         const response = await API.get(`/clients/search/autocomplete?search=${name}`)
    //         setState(state => ({...state, loading: false, clients: response.data}))
    //     } catch (err) {
    //         setState(state => ({...state, loading: false, error: getError(err.response)}))
    //     }
    // };

    // const nameSelect = async client_id => {
    //     try {
    //         const {data:client} = await API.get(`/clients/${client_id}`)
    //         console.log("client", client)
    //         changeValue({
    //             id: client._id,
    //             first_name: client.first_name,
    //             last_name: client.last_name,
    //             sms_number: client.phone,
    //             email: client.email,
    //             country: client.country,
    //             locality: client.locality,
    //             street: client.street,
    //             nr: client.nr,
    //             block_value: client.block,
    //             entry: client.entry,
    //             floor: client.floor,
    //             apartment: client.apartment
    //         })
    //     } catch(err) {}
    // };

    const setValue = (e) => {
        changeValue({...value, [e.target.name]: e.target.value})
    };

    const setPhoneNumber = phone => {
        changeValue({...value, sms_number: addPlusPrefix(phone)})
    }


    const setRadioValue = (e) => {
        let id;
        if(e.target.value === 'post'){
            id = element._id+"_with_post";
        }
        else if(e.target.value === 'sms'){
            id = element._id+"_with_sms";

        }
        else if(e.target.value === 'email'){
            id = element._id+"_with_email";
        }
        document.getElementById(id).scrollIntoView({behavior: "smooth", block: "start"})
        changeValue({...value, [e.target.name]: e.target.value})
    };

    const resetData = () => {
        changeValue({
            id: ''
        })
    };

    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (errors.includes(args[i])) {
                return true;
            }
        }

        return false;
    };

    return (
        <div className="fieldset-row form-grid">
            {element.title && <h3 style={{width: '100%'}}>{synchronizeText(element.title, answers_by_code)}</h3>}
            {element.text && <p style={{width: '100%'}}>{synchronizeText(element.text, answers_by_code)}</p>}

            <div className={`mco-box ${errorsContain('required') ? 'error' : ''}`}>
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.communication === 'post'} name="communication"
                                   id={`${element._id}_with_post`} onChange={setRadioValue} value='post'/>
                            <label htmlFor={`${element._id}_with_post`}>By mail</label>
                        </div>
                    </div>
                    {(value.communication === 'post') &&
                    <fieldset className={`form-grid ${errorsContain('post_data_required') ? 'error' : ''}`}>
                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>Name</label>

                                {/*<AutoComplete*/}
                                {/*    name="name"*/}
                                {/*    onSelect={nameSelect}*/}
                                {/*    onSearch={setFirstName}*/}
                                {/*    value={value.first_name}*/}
                                {/*>*/}
                                {/*    {state.clients.map(client => (*/}
                                {/*        <AutoComplete.Option key={client._id}>{client.name}</AutoComplete.Option>*/}
                                {/*    ))}*/}
                                {/*</AutoComplete>*/}

                                <input value={value.first_name} maxLength={20} name="first_name" onChange={setValue}/>
                            </div>
                            <div className="form-row">
                                <label>Surname</label>
                                <input value={value.last_name} maxLength={20} name="last_name" onChange={setValue}/>
                            </div>
                        </div>

                        <div className="form-grid-row">
                            <div className="form-row">
                                <label>County</label>
                                <Country value={value.country} onChange={e => {
                                    changeValue({...value, [e.target.name]: e.target.value, locality: ""})
                                }} name="country" placeholder="Choose"/>
                            </div>

                            <div className="form-row">
                                <label>City</label>
                                <City value={value.locality} selectedCountry={value.country} onChange={setValue}
                                      name="locality" placeholder="Choose"/>
                            </div>
                        </div>

                        <div className="form-grid-row street-grid">
                            <div className="form-row">
                                <label>Street</label>
                                <input value={value.street} maxLength={40} name="street" onChange={setValue}/>
                            </div>

                            <div className="form-row">
                                <label>No</label>
                                <input value={value.nr} maxLength={40} name="nr" onChange={setValue}/>
                            </div>
                        </div>

                        <div className="form-grid-row address-grid">
                            <div className="form-row">
                                <label>Building</label>
                                <input value={value.block_value} maxLength={40} name="block_value" onChange={setValue}/>
                            </div>

                            <div className="form-row">
                                <label>Entry</label>
                                <input value={value.entry} maxLength={40} name="entry" onChange={setValue}/>
                            </div>

                            <div className="form-row">
                                <label>Floor</label>
                                <input value={value.floor} maxLength={40} name="floor" onChange={setValue}/>
                            </div>

                            <div className="form-row">
                                <label>Apartment</label>
                                <input value={value.apartment} maxLength={40} name="apartment" onChange={setValue}/>
                            </div>
                        </div>

                        <div className="form-grid-row address-grid">
                            <div className={`form-row ${errorsContain('not_valid_postal_code') ? 'error' : ''}`}>
                                <label>Postal code</label>
                                <input value={value.postal_code} maxLength={40} name="postal_code" onChange={setValue}/>
                                {errorsContain('not_valid_postal_code') &&
                                <span className="form-row-error-msg">Invalid postal code</span>
                                }
                            </div>
                        </div>
                        {errorsContain('post_data_required') &&
                            <span className="form-row-error-msg">The field is required</span>
                        }
                    </fieldset>
                    }
                </div>
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.communication === 'email'} name="communication"
                                   id={`${element._id}_with_email`} onChange={setRadioValue} value='email'/>
                            <label htmlFor={`${element._id}_with_email`}>By  email</label>
                        </div>

                        {value.communication === 'email' &&
                        <div
                            className={`form-row ${errorsContain('not_valid_email', 'email_required') ? 'error' : ''}`}>
                            <input value={value.email} maxLength={255} name="email" onChange={setValue}
                                   placeholder={element.emailPlaceholder}/>
                            {errorsContain('not_valid_email') &&
                            <span className="form-row-error-msg">Invalid email</span>
                            }
                            {errorsContain('email_required') &&
                            <span className="form-row-error-msg">The field is required</span>
                            }
                        </div>
                        }
                    </div>
                </div>
                <div className="mco-cell last">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.communication === 'sms'} name="communication"
                                   id={`${element._id}_with_sms`}
                                   onChange={setRadioValue} value='sms'/>
                            <label htmlFor={`${element._id}_with_sms`}>By text message</label>
                        </div>

                        {value.communication === 'sms' &&
                        <div
                            className={`form-row ${errorsContain('not_valid_sms_number', 'sms_number_required') ? 'error' : ''}`}>
                            <input value={value.sms_number} name="sms_number" onChange={({target:{value}}) => setPhoneNumber(value)}
                                   placeholder={element.phoneNumberPlaceholderSMS}/>
                            {errorsContain('not_valid_sms_number') &&
                            <span className="form-row-error-msg">Invalid phone number</span>
                            }
                            {errorsContain('sms_number_required') &&
                            <span className="form-row-error-msg">The field is required</span>
                            }
                        </div>
                        }
                    </div>
                </div>
                <CdeleteConfirm
                    title="Are you sure you want to delete client’s data?"
                    onConfirm={resetData}
                    okText="Delete"
                >
                    <span className="add-block-input mar2b">Delete</span>
                </CdeleteConfirm>
                {errorsContain('required') &&
                    <span className="form-row-error-msg">You need to choose an option for contract communication</span>
                }
            </div>
        </div>
    );
}