import React from "react"
import { message } from "antd"
import bytes from "bytes"

import API from "../../api"
import IdeleteConfirm from "../../components/CdeleteConfirm"

const Details = ({ profileStore, signatureTabRef, paswordTabRef }) => {

	const [profileState, setProfileState] = profileStore

	const toggleTab = tab =>
		setProfileState(profileState => ({
			...profileState,
			tab: profileState.tab === tab ? null : tab,
		}))

	const onImageChange = async e => {
		try {
			let errors = {}

			const image = e.target.files[0]
			e.target.value = null

			if (image.size > bytes("20MB")) errors.image = "Maximum 20MB."

			if (errors.image) {
				message.warning(errors.image)
				return
			}

			const formData = new FormData()
			formData.append("image", image)
			const response = await API.put("users", formData, {
				headers: { "content-type": "multipart/form-data" },
			})
			setProfileState(profileState => ({...profileState, profile: {
				...profileState.profile,
				image: `${process.env.REACT_APP_API_BASE_URL}/images/users/image/${response.data.user.image}`
			}}))
		} catch (err) {
			message.warning("A aparut o eroare. Va rugam reincercati.")
		}
	}

	const removeImage = async () => {
		try {
			await API.put("users", { remove_image: true })
		
			setProfileState(profileState => ({...profileState, profile: {
				...profileState.profile,
				image: null
			}}))

			// message.success("Modificarile au fost salvate cu succes")
		} catch (err) {
			// message.warning("A aparut o eroare. Va rugam reincercati.")
		}
	}

	return (
		<div className="profile-box">
			<div className="profile-details">
				<div className="profile-image">
					{profileState.profile.image && (
						<>
							<div className="ils-uploaded-img">
								<img src={profileState.profile.image} alt="" />
							</div>
							<IdeleteConfirm
								onConfirm={removeImage}
								title="Are you sure you want to delete the image?"
								okText="Delete"
							>
								<div className="tbl-btn">
									<i className="icon-ia-trash-bold" />
									<div className="tooltip tooltip-bottom">Delete image</div>
								</div>
							</IdeleteConfirm>
						</>
					)}
					<input
						className="ils-upload-file"
						type="file"
						name=""
						accept="image/*"
						onChange={onImageChange}
					/>
					<span>Upload picture</span>
				</div>
				<div>
					<h3>{profileState.profile.name}</h3>
					<p>{profileState.profile.email}</p>
					<p>{profileState.profile.phone}</p>
					{/* <p className="last-login">
						Last login: {moment(profileState.profile.lastLogin).format("D MMM HH:mm")}{" "}
						{moment(profileState.profile.lastLogin).format("YYYY")}
					</p> */}
				</div>
			</div>
			<div className="profile-action">
				<span
					className="button button-outline with-icon-right trigger-change-password"
					onClick={() => {
						toggleTab("CHANGE_PASSWORD")
						if (profileState.tab !== "CHANGE_PASSWORD") {
                            setTimeout(() => {
                                if(paswordTabRef.current) {
                                    paswordTabRef.current.scrollIntoView({block: "end", behavior: "smooth"});
                                }
                            }, 400)
						}
					}}
				>
					<i className="icon-lock" />CHANGE PASSWORD
				</span>
				<span
					className="button button-outline with-icon-right trigger-edit-signature"
					onClick={() => {
						toggleTab("EDIT_SIGNATURE")
						if (profileState.tab !== "EDIT_SIGNATURE") {
                            setTimeout(() => {
                                if(signatureTabRef.current) {
                                    signatureTabRef.current.scrollIntoView({block: "end", behavior: "smooth"});
                                }
                            }, 400)
						}
					}}
				>
					<i className="icon-ia-edit-bold" />SIGNATURE
				</span>
			</div>
		</div>
	)
}

export default Details
