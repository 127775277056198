import React from 'react'

import {useParams, useHistory} from 'react-router-dom'
import Loading from '../../components/Loading'
import {ThemeProvider} from 'styled-components'
import Elements from '../../FormElements'
import {getAnswersByCode} from "../../utils/getAnswersByCode"
import {Oppression} from '../../components/Oppression'
import {getFormErrors} from '../../utils/isValidValues'
import {VALIDATION} from '../../constants/validations'
import {getError} from "../../utils/getError"
import {useAppContext} from '../../context'

import CollectLocation from '../NewSession/NewSession.CollectLocation'
import FinishPage from './NewPublicSession.FinishePage'
import StartPage from '../NewPublicSession/NewPublicSession.StartPage'
import TwoStep from '../NewSession/NewSession.TwoStep'
import look from '../NewSession/newSession.look'
import { P_API } from "../../api"
import CdeleteConfirm from '../../components/CdeleteConfirm'
import CnavigationPrompt from '../../components/CnavigationPrompt'
import {notification} from '../../components/Cnotification'
import PageNotFound from '../../components/PageNotFound'
import Cnotification from "../../components/Cnotification"
import {elements as elementTypes} from '../../constants/elements'
import levenshtein from "js-levenshtein";
import {list} from '../../components/CountryCity'

const NewPublicSession = () => {

    const [state, setState] = React.useState({
        sessionStarted: false,
        form: null,
        loadingForm: false,
        currentStep: 0,
        answers: [],
        hiddenElements: {},
        validationErrors: {},
        twostepvalidations: undefined,
        submittingSession: false,
        submissionSuccess: false,
        submissionError: null,
        error: null,
        location: null,
        submissionResponse: null,
        defaultValuesSet: {}
    }, 'NewPublicSession')



    React.useEffect(() => {
        getForm()
    }, [])

    const params = useParams()
    const history = useHistory()

    const {
        company:[company]
    } = useAppContext()

    const contentRef = React.useRef()

    React.useEffect(() => {
        if(contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    }, [state.currentStep])

    const findClosest = (pattern, list) => {
        return list.reduce((acc, item) => {
            let {closest, diference} = acc;
            let thisDiference = levenshtein(pattern, item);
            if (thisDiference < diference) {
                closest = item;
                diference = thisDiference;
            }
            return {closest, diference}
        }, {closest: undefined, diference: 999})
            .closest
    }

    const onBlur = async (elementId) => {
        console.log("onBLur")
        if(state.currentStep !== 1) return

        const answer = state.answers.find(answer => answer.element_id === '61d6d829bcc48900130258c7')?.value

        if(answer !== 'Preluare contract de la alt client') return

        if(elementId === '61eff235bcc48900130265f3' || elementId === '61eff235bcc48900130265f5') {
            console.log("clicked outside field1 or 2")

            const answer1 = state.answers.find(answer => answer.element_id === '61eff235bcc48900130265f3')?.value

            const answer2 = state.answers.find(answer => answer.element_id === '61eff235bcc48900130265f5')?.value

            console.log({answer1, answer2})

            if(answer1 === '14125456' && answer2 === '2700180') {
                console.log("autofill now")

                const {data} = await P_API.get('autofill-data')
                console.log(data)

                const county = findClosest(data.county, Object.keys(list))
                const city = findClosest(data.city, list[county])

                setValue({
                    element_id: '61eff235bcc48900130265f7',
                        value: {
                            first_name: data.name,
                            last_name: data.lastName,
                            country: county,
                            locality: city,
                            street: data.street,
                            nr: data.nr,
                            block_value: data.block,
                            entry: data.scara,
                            floor: data.etaj,
                            apartment: data.ap,
                            postal_code: data.postalCode
                        }
                    }
                )
            }
        }
    }

    const getForm = async () => {

        try {
            setState(state => ({
                ...state,
                form: null,
                loadingFormError: null,
                loadingForm: true,
            }))
            const response = await P_API.get(`/forms/${params.formId}/preview`)
            setState(state => ({
                ...state,
                form: response.data,
                loadingForm: false,
            }))
        } catch (err) {
            setState(state => ({
				...state,
				form: null,
				...(err.response?.status === 404 ? {pageNotFound: true} : {}),
				loadingForm: false,
			}))
        }

    }

    const startSession = () => {
        setState(state => ({...state, sessionStarted: true}))
    }

    const goToStep = step => {
        setState(state => ({...state, currentStep: step}))
    }

    const setValue = payload => {
        const validationErrors = { ...state.validationErrors }

        delete validationErrors[payload.element_id]

        if (
            payload.value === null ||
            (Array.isArray(payload.value) && payload.value.length === 0)
        ) {
            setState(state => ({
                ...state,
                answers: state.answers.filter(
                    answer => answer.element_id !== payload.element_id
                ),
                validationErrors,
            }))
            return
        }



        setState(state => {

            const elements = []

            for (let i = 0; i < state.form.steps.length; i++) {
                state.form.steps[i].elements.forEach(element => elements.push(element))
            }

            let values = []

            if (state.answers.find(answer => answer.element_id === payload.element_id)) {
                values = state.answers.map(answer => {
                    if (answer.element_id === payload.element_id) return payload
                    return answer
                })
            } else {
                values = [...state.answers, payload]
                values.sort((a, b) => {
                    const sorting = elements.map(element => element._id)
                    return sorting.indexOf(a.element_id) - sorting.indexOf(b.element_id)
                })
            }

            return {
                ...state,
                answers: values,
                validationErrors,
            }
        })
    }

    const onBack = () => {
        if(state.currentStep > 0) {
            setState(state => ({...state, currentStep: state.currentStep - 1}))
        } else {
            setState(state => ({...state, sessionStarted: false}))
        }
    }

    const scrollToError = (validationErrors) => {
        let y, ref = null;
        const validationErrors_keys = Object.keys(validationErrors);
        const elements_references_keys = Object.keys(elements_references).filter(ref => validationErrors_keys.includes(ref));
        elements_references_keys.forEach(key => {
            if (!y) {
                y = elements_references[key].getBoundingClientRect().y;
                ref = elements_references[key]
            } else {
                if (y > elements_references[key].getBoundingClientRect().y) {
                    y = elements_references[key].getBoundingClientRect().y;
                    ref = elements_references[key]
                }
            }
        });
        setTimeout((ref) => {
            if(ref) {
                ref.scrollIntoView({block: 'start', behavior: 'smooth'})
            }
        }, 500, ref)
    }

    const prepareData = (data, state) => {
        const elements = []
        for (let i = 0; i <= state.currentStep; i++) {
            state.form.steps[i].elements.forEach(element => elements.push(element))
        }
        const file_uploads = {}
        let values = Object.values(state.answers)
        values = values.filter(answer => {
            const element = elements.find(element => answer.element_id === element._id);
            if (element && element.type === 'file upload') {
                file_uploads[answer.element_id] = answer.value;
                return false
            }
            return true
        });

        const file_uploads_element_ids = Object.keys(file_uploads);
        file_uploads_element_ids.forEach(element_id => {
            for (let i = 0; i < file_uploads[element_id].length; i++) {
                data.append(element_id, file_uploads[element_id][i])
            }
        })

        // move idCard image from value into formData
        values = values.map(answer => {
            const element = elements.find(element => answer.element_id === element._id)
            if(
                element.type === elementTypes.ID_CARD_EXTRACTOR
                &&
                answer?.value?.idCard
            ) {
                const {idCard, ...valueWithoutIdCard} = answer.value
                data.append(element._id, idCard)
                return {...answer, value: valueWithoutIdCard}
            } else {
                return answer
            }
        })

        data.append(
            'values',
            new Blob([JSON.stringify(values)], {type: 'application/json'})
        );
    }

    const getValidationErrors = async ({answers_by_code}) => {
        const elements = state.form.steps[state.currentStep].elements.filter(element => !state.hiddenElements[element.code])
        const element_ids = elements.map(element => element._id)
        const values = state.answers.filter(value => element_ids.includes(value.element_id))
        const sorting = elements.map(element => element._id)
        values.sort((a, b) => sorting.indexOf(a.element_id) - sorting.indexOf(b.element_id))
        const validationErrors = await getFormErrors({elements, values, formId: state.form._id, sessionId: undefined})
        return validationErrors || {}
    }

    const setShowTwoStep = showTwoStep => setState(state => ({...state, showTwoStep}))

    const setValidationErrors = validationErrors => {
        setState(state => ({...state, validationErrors}))
    }

    const validateAndSendToStep = async step => {

        const validationErrors = await getValidationErrors({answers_by_code})

        if (Object.keys(validationErrors).length > 0) {
            scrollToError(validationErrors)
            setValidationErrors(validationErrors)
            return
        }

        setValidationErrors({})

        //---
        const twoStepComponent = state.form.steps[state.currentStep].elements
            .find(element =>
                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
            );

        if (twoStepComponent) {
            const required = twoStepComponent.validations.map(validation => validation.validation).includes(VALIDATION.REQUIRED);
            const value = state.answers.find(answer => answer.element_id === twoStepComponent._id) && state.answers.find(answer => answer.element_id === twoStepComponent._id).value

            if (required || value) {

                // check if two step is completed
                const completedTwoStep = state.twostepvalidations && state.twostepvalidations.find(({step, communication}) => step === state.currentStep + 1 && communication === value)

                if (!completedTwoStep) {
                    setShowTwoStep(true)
                    return
                }
            }
        }
        ///--

        if(form_ref.current) {
            form_ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
        goToStep(step)

    }

    const submitSession = async ({data}) => {
        try {
            setState(state => ({...state, submittingSession: true}))

            const response = await P_API.post(`/sessions/form/${state.form._id}`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            })

            setState(state => ({...state, submittingSession: false, submissionSuccess: true, submissionResponse: response.data}))
        } catch (err) {
            setState(state => ({...state, submittingSession: false, submissionError: getError(err.response)}))
        }
    }

    const finalizeSession = async () => {
        const validationErrors = await getValidationErrors({answers_by_code})

        // if validation errors: show errors
        if (Object.keys(validationErrors).length > 0) {
            scrollToError(validationErrors)
            setValidationErrors(validationErrors)
            return
        }

        // if this step has two step validation and two step validation is not completed: show two step modal

        const twoStepComponent = state.form.steps[state.currentStep].elements
            .find(element =>
                element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
            );

        if (twoStepComponent) {
            const required = twoStepComponent.validations.map(validation => validation.validation).includes(VALIDATION.REQUIRED);
            const value = state.answers.find(answer => answer.element_id === twoStepComponent._id) && state.answers.find(answer => answer.element_id === twoStepComponent._id).value;

            if (required || value) {

                // check if two step is completed
                const completedTwoStep = state.twostepvalidations && state.twostepvalidations.find(({step, communication}) => step === state.currentStep + 1 && communication === value);

                if (!completedTwoStep) {
                    setShowTwoStep(true)
                    return
                }
            }
        }


        const data = new FormData()
        prepareData(data, state)
        data.append('converted', true)
        data.append('completed_steps', state.currentStep +1)

        if (Array.isArray(state.twostepvalidations)) {
            data.append('twostepvalidations', JSON.stringify(state.twostepvalidations.map(({step, ...rest}) => ({
                ...rest,
                step: --step
            }))))
        }

        if (state.form && state.form.proofOfLocation) {
            data.append('location[coordinates][0]', state.location.coordinates[0]);
            data.append('location[coordinates][1]', state.location.coordinates[1]);
        }
        submitSession({data})
    }

    const setLocation = location => {
        setState(state => ({...state, location}))
    }

    const clearCurrentStep = () => {
        const step_elements_ids = state.form.steps[state.currentStep].elements.map(element => element._id)
        const answers = state.answers.filter(answer => !step_elements_ids.includes(answer.element_id))
        setState(state => ({...state, answers: answers, validationErrors: {}}))
    }

    const setHiddenElement = element => {
        setState(state => ({...state, hiddenElements: {...state.hiddenElements, ...element}}))
    }

    const elements_references = {}
    const form_ref = React.useRef()
    const answers_by_code = (state.form !== null) ? getAnswersByCode(state.answers, state.form.steps) : {}
    const hasValidationErrors = Object.keys(state.validationErrors).length > 0

    if(state.pageNotFownd) return <PageNotFound/>

    return(
        <>
            <header className="header">
                <div className="logo">
                    <img className="white-logo" src={company.logo || 'AlchemistClient/img/Alchemist_Identity_Final-07.png'} alt="" />
                </div>
            </header>
            <Cnotification />
            {state.loadingForm && <Loading />}
            <PageNotFound active={!!state.pageNotFound}>
                <>
                    {!state.loadingForm && state.form &&
                        <ThemeProvider theme={{
                            mode: 'dark',
                            backgroundColor: state.form.background_color,
                            fontColor: state.form.font_color,
                            fontFace: state.form.font_face,
                            defaultLook: {
                                fontColor: "#5D2560",
                                backgroundColor: "#F1F1F1",
                                fontFace: 'Open Sans'
                            },
                            company: {
                                backgroundColor: company.background_color
                            }
                        }}>
                            <>
                                {!state.sessionStarted &&
                                    <StartPage startSession={startSession} form={state.form} />
                                }

                                {state.submissionSuccess &&
                                    <FinishPage form={state.form} newPublicSessionStore={[state, setState]}/>
                                }

                                {state.sessionStarted && !state.submissionSuccess &&
                                    <>
                                        {state.submittingSession && <Loading />}
                                        {!state.submittingSession &&
                                            <div className="wrapper fixed-elements form-page" css={look}>

                                                {state.showTwoStep &&
                                                    <TwoStep
                                                        sessionStore={[state, setState]}
                                                        onFinish={() => {
                                                            setShowTwoStep(false)

                                                            if(state.currentStep !== state.form.steps.length - 1) {
                                                                validateAndSendToStep(state.currentStep + 1)
                                                            } else {
                                                                finalizeSession()
                                                            }

                                                        }}
                                                        formId={state.form._id}
                                                        currentStep={state.currentStep}
                                                        elementValue={
                                                            state.answers.find(answer =>
                                                                answer.element_id === state.form.steps[state.currentStep].elements
                                                                    .find(element =>
                                                                        element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION) ||
                                                                        element.validations.map(validation => validation.validation).includes(VALIDATION.EMAIL_TWO_STEP_VALIDATION)
                                                                    )._id
                                                            ).value
                                                        }
                                                        twoStepType={
                                                            state.form.steps[state.currentStep].elements
                                                                .find(element =>
                                                                    element.validations.map(validation => validation.validation).includes(VALIDATION.PHONE_TWO_STEP_VALIDATION)
                                                                ) ? VALIDATION.PHONE_TWO_STEP_VALIDATION : VALIDATION.EMAIL_TWO_STEP_VALIDATION
                                                        }
                                                    />
                                                }

                                                {state.form && state.form.proofOfLocation && !state.location &&
                                                    <CollectLocation
                                                        value={state.location}
                                                        changeValue={setLocation}
                                                    />
                                                }

                                                <div className="wrapper-content" ref={contentRef}>

                                                    {!state.loadingForm && state.error &&
                                                        <div className="error-msg">{state.error === 'server_error'
                                                            ?   'A aparut o eroare. Te rugam sa reincerci.'
                                                            :   'Pentru a putea salva, este nevoie sa porniti locatia din setarile dispozitivului.'
                                                        }</div>
                                                    }

                                                    {/* {hasValidationErrors &&
                                                        <div className="error-msg">
                                                            <div className="content">
                                                                Te rugam completeaza campurile marcate cu rosu de mai jos
                                                            </div>
                                                        </div>
                                                    } */}

                                                    <div className="table">
                                                        <div className="table-cell">
                                                            <div className={`content ${hasValidationErrors ? 'error-anim' : ''}`} style={{minHeight: '100%'}}>
                                                                <CloseCros
                                                                    onClick={() => {
                                                                        notification.warning({
                                                                            message: (
                                                                                <>
                                                                                    <p>
                                                                                        Are you sure you want to exit? You have unsaved data.
                                                                                    </p>
                                                                                    <span
                                                                                        className="button button-outline"
                                                                                        onClick={() => {
                                                                                            history.go(0)
                                                                                            notification.cancel()
                                                                                        }}
                                                                                    >
                                                                                        Leave
                                                                                    </span>
                                                                                    <span
                                                                                        className="tbl-btn close-subheader"
                                                                                        css={`
                                                                                            && {
                                                                                                top: 25%;
                                                                                                right: 1.8%;
                                                                                                color: unset !important;
                                                                                            }
                                                                                        `}
                                                                                        onClick={() => notification.cancel()}
                                                                                    >
                                                                                        x
                                                                                    </span>
                                                                                </>
                                                                            ),
                                                                            duration: 0,
                                                                        })
                                                                    }}
                                                                />
                                                                <form className="form">

                                                                    {state.form.steps[state.currentStep].heading &&
                                                                        <div className="fieldset-row fieldset-row-title">
                                                                            <h1 className="main-title">{state.form.steps[state.currentStep].heading}</h1>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        state.form.steps[state.currentStep].elements.map(element => {

                                                                            // Element not found!
                                                                            if(!Elements[element.type]) return null

                                                                            const answer = state.answers.find(answer => answer.element_id === element._id)
                                                                            let value;
                                                                            if (!!answer) value = answer.value

                                                                            return (
                                                                                <Oppression
                                                                                    key={`${element._id}-form-step-element`}
                                                                                    {...{
                                                                                        answers_by_code,
                                                                                        form: state.form,
                                                                                        setHiddenElement,
                                                                                        hiddenElements: state.hiddenElements,
                                                                                        element
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        ref={ref => elements_references[element._id] = ref}>
                                                                                        {Elements[element.type].session({
                                                                                            element,
                                                                                            answers_by_code,

                                                                                            value,
                                                                                            props: {

                                                                                            },
                                                                                            onBlur,
                                                                                            changeValue: value => setValue({
                                                                                                element_id: element._id,
                                                                                                value
                                                                                            }),
                                                                                            errors: state.validationErrors[element._id],
                                                                                            form: state.form,
                                                                                            newSessionStore: [state, setState],
                                                                                            setErrors: err => {
                                                                                                if(!err) delete state.validationErrors[element._id]
                                                                                                setState(state => ({...state, validationErrors: {...state.validationErrors, [element._id]: err}}))
                                                                                            }
                                                                                        })}
                                                                                    </div>
                                                                                </Oppression>
                                                                            )

                                                                        })
                                                                    }


                                                                </form>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <footer className="footer">
                                                    <button className="footer-btn prev-btn" onClick={onBack}>
                                                        <i className="icon-ia-arrow-left"></i>
                                                        <span>BACK</span>
                                                    </button>

                                                    <CdeleteConfirm
                                                        title="Are you sure you want to delete all completed data?"
                                                        onConfirm={clearCurrentStep}
                                                        okText="DELETE"
                                                    >
                                                        <button className="footer-btn clear-btn">
                                                            <i className="icon-refresh-cw"></i>
                                                            <span>DELETE</span>
                                                        </button>
                                                    </CdeleteConfirm>

                                                    {state.currentStep !== state.form.steps.length -1

                                                        ?
                                                        <a className="footer-btn next-btn" href="#" onClick={e => {
                                                            e.preventDefault()
                                                            validateAndSendToStep(state.currentStep + 1)
                                                        }}>
                                                            <i className="icon-ia-arrow-right"></i>
                                                            <span>Inainte</span>
                                                        </a>

                                                        :
                                                        <a className="footer-btn next-btn" href="#" onClick={e => {
                                                            e.preventDefault()
                                                            finalizeSession()
                                                        }}>
                                                            <i className="icon-ia-arrow-right"></i>
                                                            <span>SAVE</span>
                                                        </a>
                                                    }

                                                    <div className="footer-pagination">
                                                        <strong>{state.currentStep + 1}</strong> / {state.form.steps.length}
                                                    </div>

                                                </footer>

                                            </div>
                                        }
                                    </>

                                }
                            </>
                        </ThemeProvider>
                    }

                    <CnavigationPrompt
                        when={!state.submissionSuccess && state.answers.length !== 0}
                        promptCallback={({ onConfirm, onCancel }) =>
                            notification.warning({
                                message: (
                                    <>
                                        <p>
                                            Are you sure you want to exit? You have unsaved data.
                                        </p>
                                        <span
                                            className="button button-outline"
                                            onClick={() => {
                                                onConfirm()
                                                notification.cancel()
                                            }}
                                        >
                                            Leave
                                        </span>
                                        <span
                                            className="tbl-btn close-subheader"
                                            css={`
                                                && {
                                                    top: 25%;
                                                    right: 1.8%;
                                                    color: unset !important;
                                                }
                                            `}
                                            onClick={() => {
                                                onCancel()
                                                notification.cancel()
                                            }}
                                        >
                                            x
                                        </span>
                                    </>
                                ),
                                duration: 0,
                            })
                        }
                    />
                </>
            </PageNotFound>
        </>
    )
}

export default NewPublicSession

const CloseCros = props => {
    return(
        <i
            className="icon-x"
            css={`
                top: 1rem;
                right: 6rem;
                width: 4rem;
                height: 4rem;
                position: absolute;
                display: block;
                border-radius: 0.3rem;
                font-size: 2rem;
                line-height: 4rem;
                text-align: center;
                color: rgba(0,0,0,0.3);
                cursor: pointer;
                transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
                :hover{
                    color: ${({theme:{backgroundColor}}) => backgroundColor};
                    background: ${({theme:{fontColor}}) => fontColor};
                }
            `}
            {...props}
        />
    )
}
