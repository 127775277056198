import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";
import { Country, City } from '../../components/CountryCity'
import {addPlusPrefix} from '../../utils/phoneValidator'

export default ({
    element,
    answers_by_code,
    value = {
    },
    changeValue,
    errors = []
}) => {

    const setCheckboxValue = (e) => {
        changeValue({ [e.target.name]: e.target.checked })
    };

    const setValue = (e) => {
        changeValue({ ...value, [e.target.name]: e.target.value })
    };

    const setPhoneNumber = phone => {
        changeValue({ ...value, phone_number: addPlusPrefix(phone) })
    }

    const setSmsNumber = phone => {
        changeValue({ ...value, sms_number: addPlusPrefix(phone) })
    }
    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (errors.includes(args[i])) {
                return true;
            }
        }

        return false;
    };

    return (
        <div className="fieldset-row">
            {element.text &&
                <h3>{synchronizeText(element.text, answers_by_code)}</h3>
            }
            <div className="mco-box">
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.with_post} name="with_post" id={`${element._id}_with_post`} onChange={setCheckboxValue} />
                            <label htmlFor={`${element._id}_with_post`}>By mail</label>
                        </div>
                    </div>
                    {value.with_post &&
                        <fieldset className="form-grid">
                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>Name</label>
                                    <input value={value.first_name} maxLength={20} name="first_name" onChange={setValue} />
                                </div>
                                <div className="form-row">
                                    <label>Surname</label>
                                    <input value={value.last_name} maxLength={20} name="last_name" onChange={setValue}/>
                                </div>
                            </div>

                            <div className="form-grid-row">
                                <div className="form-row">
                                    <label>County</label>
                                    <Country value={value.country} onChange={e => {
                                        changeValue({ ...value, [e.target.name]: e.target.value, locality: "" })
                                    }} name="country" placeholder="Choose"/>
                                </div>

                                <div className="form-row">
                                    <label>City</label>
                                    <City value={value.locality} selectedCountry={value.country} onChange={setValue} name="locality" placeholder="Choose"/>
                                </div>
                            </div>

                            <div className="form-grid-row street-grid">
                                <div className="form-row">
                                    <label>Street</label>
                                    <input value={value.street} maxLength={40} name="street" onChange={setValue} />
                                </div>

                                <div className="form-row">
                                    <label>No</label>
                                    <input value={value.nr} maxLength={40} name="nr" onChange={setValue} />
                                </div>
                            </div>

                            <div className="form-grid-row address-grid">
                                <div className="form-row">
                                    <label>Building</label>
                                    <input value={value.block_value} maxLength={40} name="block_value" onChange={setValue} />
                                </div>

                                <div className="form-row">
                                    <label>Entry</label>
                                    <input value={value.entry} maxLength={40} name="entry" onChange={setValue} />
                                </div>

                                <div className="form-row">
                                    <label>Floor</label>
                                    <input value={value.floor} maxLength={40} name="floor" onChange={setValue} />
                                </div>

                                <div className="form-row">
                                    <label>Apartment</label>
                                    <input value={value.apartment} maxLength={40} name="apartment" onChange={setValue} />
                                </div>
                            </div>

                            <div className="form-grid-row address-grid">
                                <div className={`form-row ${errorsContain('not_valid_postal_code') ? 'error' : ''}`}>
                                    <label>Postal code </label>
                                    <input value={value.postal_code} maxLength={40} name="postal_code" onChange={setValue} />
                                    {errorsContain('not_valid_postal_code') &&
                                        <span className="form-row-error-msg">Invalid postal code</span>
                                    }
                                </div>
                            </div>
                        </fieldset>
                    }
                </div>
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.with_email} name="with_email" id={`${element._id}_with_email`} onChange={setCheckboxValue} />
                            <label htmlFor={`${element._id}_with_email`}>By  email</label>
                        </div>

                        {value.with_email &&
                            <div className={`form-row ${errorsContain('not_valid_email', 'email_required') ? 'error' : ''}`}>
                                <input value={value.email} maxLength={255} name="email" onChange={setValue} placeholder={element.emailPlaceholder}/>
                                {errorsContain('not_valid_email') &&
                                    <span className="form-row-error-msg">Invalid email</span>
                                }
                                {errorsContain('email_required') &&
                                    <span className="form-row-error-msg">This field is required</span>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="mco-cell">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.with_phone} name="with_phone" id={`${element._id}_with_phone`} onChange={setCheckboxValue} />
                            <label htmlFor={`${element._id}_with_phone`}>By phone</label>
                        </div>

                        {value.with_phone &&
                            <div className={`form-row ${errorsContain('not_valid_phone_number', 'phone_number_required') ? 'error' : ''}`}>
                                <input value={value.phone_number} maxLength={40} name="phone_number" onChange={({target:{value}}) => setPhoneNumber(value)} placeholder={element.phoneNumberPlaceholderCall} />
                                {errorsContain('not_valid_phone_number') &&
                                    <span className="form-row-error-msg">Invalid phone number</span>
                                }
                                {errorsContain('phone_number_required') &&
                                    <span className="form-row-error-msg">This field is required</span>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="mco-cell last">
                    <div className="mco-cell-header">
                        <div className="form-row-radio">
                            <input type="radio" checked={value.with_sms} name="with_sms" id={`${element._id}_with_sms`} onChange={setCheckboxValue} />
                            <label htmlFor={`${element._id}_with_sms`}>By text message</label>
                        </div>

                        {value.with_sms &&
                            <div className={`form-row ${errorsContain('not_valid_sms_number', 'sms_number_required') ? 'error' : ''}`}>
                                <input value={value.sms_number} maxLength={40} name="sms_number" onChange={({target:{value}}) => setSmsNumber(value)} placeholder={element.phoneNumberPlaceholderSMS} />
                                {errorsContain('not_valid_sms_number') &&
                                    <span className="form-row-error-msg">Invalid phone number</span>
                                }
                                {errorsContain('sms_number_required') &&
                                    <span className="form-row-error-msg">This field is required</span>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}