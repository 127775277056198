import axios from 'axios'

const upload = async (file) => {
    try {
        const data = new FormData()
        data.append('file', file)
        return (await axios.post(
            process.env.REACT_APP_TEMP_FILE_SERVER || `${process.env.REACT_APP_API_BASE_URL}/temp-file-upload`,
            data
        ))?.data
    } catch(err) {
        throw err
    }
}

export default {
    upload
}