import React from 'react'
import {css} from 'styled-components'

const cssFixes = css`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    
    && {
        .content {
            width: 100%;
            max-width: 100%;
        }
    }
    .content {
        width: 70%;
        position: absolute;
        top: 7rem;
        bottom: 0;
        left: 0;
        background: #f8f8f8;
    }

    .content-404 {
        width: 72rem;
        height: 30rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .table {
        width: 100%;
        height: 100%;
        display: table;
    }

    .content-404-icon {
        width: 20rem;
        height: 16rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0,-50%);
        background: transparent url(../img/404-icon.svg) no-repeat 50% 50%;
        background-size: 100% auto;
    }

    .content-404 .table-cell {
        padding-left: 27rem;
    }

    .table-cell {
        width: 100%;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
    }

    .content-404 h6 {
        font-size: 5.6rem;
        line-height: 6rem;
        font-weight: 900;
        color: #5d2460;
        margin-bottom: 1.5rem;
    }

    .content-404 h2 {
        font-size: 2.8rem;
        line-height: 3.4rem;
        font-weight: 600;
        color: #151515;
        margin-bottom: 1rem;
        margin-top: -1rem;
    }
    
   
`

const PageNotFound = ({children, active=false}) => {

    if(active) {
        return (
            <div className="wrapper page-404" css={cssFixes}>
                <div className="content">
                    <div className="content-404">
                        <div className="table">
                            <div className="table-cell">
                                <h6>Eroare 404</h6>
                                <h2>Pagina nu a fost gasita....</h2>
                            </div>
                        </div>
                        <div className="content-404-icon"></div>
                    </div>
                </div>
            </div>
        )
    } else return children || null
    
}

export default PageNotFound