import API from "../../api";

export const getAgentSignatureInformation = async (callback) => {
    try {
        const response = await API.get('/users/agent-signature/information');

        callback(response.data);
    } catch (err) {
        // do nothing
    }
};