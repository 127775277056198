import React from 'react'
import { getAgentSignatureInformation } from './Session.AgentSignature.actions'
import LsignaturePad from '../../components/LsignaturePad'

export default ({
    element,
    value,
    changeValue
}) => {

    React.useEffect(() => {
        if (value === undefined) {
            getAgentSignatureInformation(info => {
                setValue(info)
            })
        }
    }, [value])

    const setValue = value => {
        if(value.signature === null){
            value.signature = "";
        }
        changeValue(value)
    }

    const onChange = signature => {
        setValue({ ...value, signature })
    }

    return (
        <div className="fieldset-row">
            <p>{element.text}</p>
            <h3 className="form-box-wrap-title">{value && value.name}</h3>
            <LsignaturePad value={value && value.signature || null} onChange={onChange}/>
        </div>
    )
}

