import React from "react"
import { useHistory } from "react-router-dom"

import { useAppContext } from "../../context"
import { css } from "styled-components"
import Cnotification from "../Cnotification"


const cssFixes = css`
	.menu-right a.logout:before {
		border-left: none;
	}
`

const Navbar = () => {
	const history = useHistory()

	const {
		company: [company],
		auth:[,setAuth]
	} = useAppContext()

	return (
		<>
			<header className="header" css={cssFixes}>
				<div className="logo">
					<img
						className="white-logo"
						src={
							company.logo || "AlchemistClient/img/Alchemist_Identity_Final-07.png"
						}
						alt=""
					/>
				</div>

				<nav className="menu">
					<a
						href="#"
						title=""
						onClick={e => {
							e.preventDefault()
							history.push("/")
						}}
						className={`${history.location.pathname === "/" ? "selected" : ""}`}
					>
						<strong>
							<span>FORMS</span>
						</strong>
					</a>
					<a
						href="#"
						title=""
						onClick={e => {
							e.preventDefault()
							history.push("/sessions")
						}}
						className={`${
							history.location.pathname === "/sessions" ? "selected" : ""
						}`}
					>
						<strong>
							<span>SESSIONS</span>
						</strong>
					</a>
					<a
						href="#"
						title=""
						onClick={e => {
							e.preventDefault()
							history.push("/profile")
						}}
						className={`${
							history.location.pathname === "/profile" ? "selected" : ""
						}`}
					>
						<strong>
							<span>
								MY<br />PROFILE
							</span>
						</strong>
					</a>
				</nav>

				<div className="menu-right">
					{/* <a className="notifications alert" href="#" onClick={e => e.preventDefault()}><div className="tooltip tooltip-bottom">Notifications</div></a> */}
					<a
						className="logout"
						href="#"
						onClick={e => {
							e.preventDefault()
							setAuth(null)
						}}
					>
						<div className="tooltip tooltip-bottom">Logout</div>
					</a>
				</div>
			</header>
			<Cnotification />
		</>
	)
}

export default Navbar
