import React from 'react'

import LsignaturePad from '../../components/LsignaturePad'
import Loading from '../../components/Loading'
import API from '../../api'

const EditSignature = ({setTab, profileStore, signatureTabRef}) => {
    const [profileState] = profileStore
    const [state, setState] = React.useState({
        loading: false,
        signature: profileState.profile.signature
    }, 'edit-signature')

    const setSignature = signature => setState(state => ({...state, signature}))

    const saveSignature = async () => {
        try {
            setState(state => ({...state, loading: true}))

            await API.put('users', {signature: state.signature})

            setState(state => ({...state, loading: false}))
            setTab(null)
            // message.success("Modificarile au fost salvate cu succes")
        } catch (err) {
            setState(state => ({...state, loading: false}))
            // message.warning("A aparut o eroare. Va rugam reincercati.")
        }
    }

    return (
        <div class="edit-signature" ref={signatureTabRef}>
            <div class="titleDesc">
                <h3>Signature</h3>
                {/* <p>Please note that when changing your password, we ask you to set your self a secure password that contains both uppercase and lowercase letters as well as numbers. This is for your own safety.</p> */}
            </div>
            {state.loading && <Loading />}
            {!state.loading &&             
                <div class="form" >
                    <div class="fieldset-row" css={`
                            width: 100%;
                            max-width: 52rem;
                    `}>
                        <div class="form-row">
                            {/* <label>Agent signature</label> */}

                            {/* <div class="signature-pad">
                                <div class="tbl-btn"><i class="icon-refresh-cw"></i><div class="tooltip tooltip-bottom">Clear</div></div>
                            </div> */}

                            <LsignaturePad value={state.signature} onChange={setSignature}/>

                        </div>
                    </div>
                    <div class="form-actions">
                        <button class="button with-icon-right close-box" onClick={saveSignature}><i class="icon-ia-checked"></i>SAVE</button>
                        <span class="button button-link close-box" onClick={() => {
                            setSignature(profileState.profile.signature)
                            setTab(null)
                        }}>CLOSE</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditSignature

