import React from 'react'
import {synchronizeText} from "../../utils/synchronizeText"
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker } from '@react-google-maps/api'

import {useAppContext} from '../../context'
import axios from 'axios'
import debounce from '../../utils/debounce'

const SessionMap =  ({
    element,
    value = '',
    changeValue,
    errors = [],
    answers_by_code
}) => {

    return (
        <div class="fieldset-row">
            {element.title && <h3 style={{width: '100%'}}>{synchronizeText(element.title, answers_by_code)}</h3>}
            {element.text &&
                <p>{synchronizeText(element.text, answers_by_code)}</p>
            }
            <div class="location-box">
                <div id="map">
                    <MyComponent changeValue={changeValue}/>
                </div>
            </div>
        </div>
    )
}
   
const center = {
  lat: 45.9237859,
  lng: 22.7775086
}

const MyComponent = ({changeValue}) => {

  const [map, setMap] = React.useState(null)

  const [state, setState] = React.useState({
    search: '',
    locations: [],
    viewDropdown: false,
    debounceState: null
  }, 'map-component')

  const debounceStore = [
    state.debounceState, 
    debounceState => setState(state => ({...state, debounceState}))
  ]
  
  const {
      company:[company]
  } = useAppContext()

  const [merkerRef, setMarkerRef] = React.useState(null)
  
  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const searchAdressWithNominatim = async location => {

    const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {params: {
      lat: location.lat,
      lon: location.lng,
      format: 'json'
    }})

    setState(state => ({...state, search: response.data.display_name}))

    searchPlace(response.data.display_name)
 

    changeValue({
      address: response.data.display_name,
      location:  {lat: response.data.lat, lng: response.data.lon}
    })

  }
  
  const changeSearch = search => {
    setState(state => ({...state, search}))
  }

  const searchPlace = async search => {
    changeSearch(search)
    debounce(async () => {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {params: {
        q: search,
        format: 'json'
      }})
      if(response.data && Array.isArray(response.data)) {
        setState(state => ({...state, locations: [...response.data]}))
      }
    }, 1000, debounceStore)
  }

  function animateMapZoomTo({map, currentZoom, targetZoom, callback=f=>f}) {
      currentZoom = currentZoom || map.getZoom();
      if (currentZoom != targetZoom) {
          window.google.maps.event.addListenerOnce(map, 'zoom_changed', function (event) {
              animateMapZoomTo({
                map, 
                targetZoom, 
                currentZoom: currentZoom + (targetZoom > currentZoom ? 1 : -1), 
                callback
              });
          });
          setTimeout(function(){ map.setZoom(currentZoom) }, 80);
      } else {
        callback()
      }
  }

  return (
    <LoadScript
      googleMapsApiKey={company.google_maps_api_key}
    >
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
        zoom={7}
        center={center}

        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapTypeControl: false
        }}
        onClick={e => {
              if(merkerRef) {
                  merkerRef.setPosition(e.latLng)
                  map.panTo(e.latLng)
                  searchAdressWithNominatim({lat: e.latLng.lat(), lng: e.latLng.lng()})
              }
        }}
      >
          <Marker
              onLoad={setMarkerRef}
              position={center}
              draggable
              onDragEnd={() => {
                  if(merkerRef) {
                      const newPosition = merkerRef.getPosition()
                      map.panTo(newPosition)

                      searchAdressWithNominatim({lat: newPosition.lat(), lng: newPosition.lng()})
                  }
              }}
              icon={'img/pin.png'}
              onClick={() => {
                if(merkerRef) {
                  const position = merkerRef.getPosition()
                  searchAdressWithNominatim({lat: position.lat(), lng: position.lng()})
              }
              }}
          />

          <div className={`search-location ${(state.viewDropdown && state.locations.length > 0) ? 'view-dropdown': ''}`}>

              <input 
                type="text" 
                name="" 
                placeholder="Search location" 
                onChange={({target:{value}}) => {
                  searchPlace(value)
                }}
                value={state.search}
                onFocus={() => {
                  setState(state => ({...state, viewDropdown: true}))
                }}
                onBlur={() => {
                  setState(state => ({...state, viewDropdown: false}))
                }}
              />

              <div class="dropdown">
                  <div class="scrollbar">
                      <ul>
                          {state.locations.map(location => (
                            <li 
                              value={location.place_id}
                              onClick={() => {
                                changeValue({
                                  address: location.display_name,
                                  location:  {lat: location.lat, lng: location.lon}
                                })

                                merkerRef.setPosition({lat: parseFloat(location.lat), lng: parseFloat(location.lon)})

                                map.panTo({lat: parseFloat(location.lat), lng: parseFloat(location.lon)})
                                window.google.maps.event.addListenerOnce(map, "idle", function() { 
                                  animateMapZoomTo({map,targetZoom: 13})
                                })

                                setState(state => ({...state, viewDropdown: false, search: location.display_name}))
                              }}
                              key={location.place_id}
                            >{location.display_name}</li>
                          ))}
                      </ul>
                  </div>
              </div>
          </div>

      </GoogleMap>
    </LoadScript>
  )
}

export default SessionMap