import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";
import {css} from 'styled-components'

const cssFix = css`
    textarea {
        resize: vertical;
    }
`

export default ({
    element,
    value = '',
    changeValue,
    errors = [],
    answers_by_code
}) => {

    const setValue = (value) => {
        changeValue(value)
    };

    return (
        <div className="fieldset-row" css={cssFix}>
            <div className={`form-row ${(errors.length !== 0) ? 'error' : ''}`}>
                {element.text &&
                <label>{synchronizeText(element.text, answers_by_code)}</label>
                }
                <textarea placeholder={element.placeholder} value={value} onChange={e => setValue(e.target.value)} />
                {renderErrors(errors)}
            </div>
        </div>
    );
};

const renderErrors = (errors) => {
    if (errors.length === 0)
        return null;

    return errors.map(error => {
        const error_array = error.split('|');
        switch(error_array[0]) {
            case 'required':
                return 'This field is required';
            case 'min length':
                return `Minimum length is ${error_array[1]}`;
            case 'max length':
                return `Maximum length is ${error_array[1]}`;
            default:
                return null;
        }
    }).map(error => (
        <span key={error} className="form-row-error-msg">{error}</span>
    ))
};