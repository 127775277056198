import React from 'react';
import {synchronizeText} from "../../utils/synchronizeText";
import { Country, City } from '../../components/CountryCity'

export default ({
    element,
    value = {
        first_name: '',
        last_name: '',
        country: '',
        locality: '',
        street: '',
        nr: '',
        block_value: '',
        entry: '',
        floor: '',
        apartment: '',
        postal_code: ''
    },
    changeValue,
    answers_by_code,
    errors = []
}) => {

    const setValue = (e) => {
        changeValue({ ...value, [e.target.name]: e.target.value })
    };

    const errorsContain = (...args) => {
        for (let i = 0; i < args.length; i++) {
            if (errors.includes(args[i])) {
                return true;
            }
        }

        return false;
    };
    return (
        <div className="fieldset-row">
            {element.text &&
                <h3>{synchronizeText(element.text, answers_by_code)}</h3>
            }

            <div className="form-grid">
                <div className="form-grid-row">
                    <div className="form-row">
                        <label>Name</label>
                        <input value={value.first_name} maxLength={20} name="first_name" onChange={setValue} />
                    </div>
                    <div className="form-row">
                        <label>Surname</label>
                        <input value={value.last_name} maxLength={20} name="last_name" onChange={setValue} />
                    </div>
                </div>

                <div className="form-grid-row">
                    <div className="form-row">
                        <label>County</label>
                        <Country value={value.country} onChange={e => { changeValue({ ...value, [e.target.name]: e.target.value, locality: "" }) }} name="country" placeholder="Choose"/>
                    </div>

                    <div className="form-row">
                        <label>City</label>
                        <City value={value.locality} selectedCountry={value.country} onChange={setValue} name="locality" placeholder="Choose"/>
                    </div>
                </div>

                <div className="form-grid-row street-grid">
                    <div className="form-row">
                        <label>Street</label>
                        <input value={value.street} maxLength={40} name="street" onChange={setValue} />
                    </div>
                    <div className="form-row">
                        <label>No</label>
                        <input value={value.nr} maxLength={40} name="nr" onChange={setValue} />
                    </div>
                </div>

                <div className="form-grid-row address-grid">
                    <div className="form-row">
                        <label>Building</label>
                        <input value={value.block_value} maxLength={40} name="block_value" onChange={setValue} />
                    </div>
                    <div className="form-row">
                        <label>Entry</label>
                        <input value={value.entry} maxLength={40} name="entry" onChange={setValue} />
                    </div>
                    <div className="form-row">
                        <label>Floor</label>
                        <input value={value.floor} maxLength={40} name="floor" onChange={setValue} />
                    </div>
                    <div className="form-row">
                        <label>Ap</label>
                        <input value={value.apartment} maxLength={40} name="apartment" onChange={setValue} />
                    </div>
                </div>

                <div className="form-grid-row address-grid">
                    <div className={`form-row ${errorsContain('not_valid_postal_code') ? 'error' : ''}`}>
                        <label>Postal code </label>
                        <input value={value.postal_code} maxLength={40} name="postal_code" onChange={setValue} />
                        {errorsContain('not_valid_postal_code') &&
                            <span className="form-row-error-msg">Invalid postal code</span>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}