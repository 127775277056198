import React from 'react'
import styled from 'styled-components'

import FileSvg from '../../images/file.svg'
import {synchronizeText} from "../../utils/synchronizeText"
import FullscreenPopUp from '../../components/FullscreenPopUp'
import Loading from '../../components/Loading'
import API from "../../api"
import {elements} from '../../constants/elements'

export default ({ 
    element, 
    answers_by_code,  
    newSessionStore
}) => {

    const [state, setState] = React.useState({ previews: {} })

    
    const [newSessionState] = newSessionStore
    const documents = newSessionState.form.documents
    const form_id = newSessionState.form._id

    const setPreview = (id, data) => setState(state => ({ previews: { ...state.previews, [id]: { ...state.previews[id], ...data} } }))

    const previewDocument = documentId => {

        const elements_with_code = []
        for (let i = 0; i <= newSessionState.currentStep; i++) {
            newSessionState.form.steps[i].elements.forEach(element => {
                if (element.code && element.type !== elements.FILE_UPLOAD) {
                    elements_with_code.push(element)
                }
            })
        }
        const elements_with_code_ids = elements_with_code.map(elm => elm._id)
        const answers_of_elements_with_code = newSessionState.answers.filter(
            answer => elements_with_code_ids.includes(answer.element_id)
        )

        setPreview(documentId, { open: true, loading: true  })

        const data = new FormData();
        data.append(
            'values',
            new Blob([JSON.stringify(answers_of_elements_with_code)], {type: 'application/json'})
        )

        API.post(`/forms/documents/form/${form_id}/id/${documentId}`, data, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
        
        .then(({ data }) => {
            setPreview(documentId, { loading: false, url: data.preview_url })
        })

    }

    const closePreview = documentId => {
        setPreview(documentId, { open: false, url: null })
    }

    React.useEffect(() => {
        const script = document.createElement("script")
        script.async = true
        script.src = `${process.env.REACT_APP_DOCUMENT_SERVER_URL}/web-apps/apps/api/documents/api.js`
        const element = document.head.appendChild(script)
        return () => element.remove()
    }, [])

    React.useEffect(() => {
        setState({ previews: documents.reduce((acc, val) => ({ ...acc, [val._id]: {} }), {}) })
    }, [])

    
    return (
        <div className="fieldset-row">
            {element.title && <h3>{synchronizeText(element.title, answers_by_code)}</h3>}
            {element.text && <p>{synchronizeText(element.text, answers_by_code)}</p>}

            <div className="form-grid files-list" style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
                {documents.map(document => (
                    <React.Fragment key={document._id}>
                        <div className="file" onClick={() => {
                            previewDocument(document._id)
                        }}>
                            <img src={FileSvg} alt="" style={{ cursor: "zoom-in" }}/>
                            <strong>{document.filename.replace(/\.[^/.]+$/, "")}</strong>
                        </div>
                    </React.Fragment>
                ))}

                {Object.keys(state.previews).map(key => (
                    <FullscreenPopUp visible={state.previews[key].open} closeFn={() => closePreview(key)} key={key}>
                        <>
                        {state.previews[key].loading && <Loading />}
                        {state.previews[key].url &&
                            <IframeWrapper>
                                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${state.previews[key].url}`} frameBorder="no" />
                            </IframeWrapper>
                        }
                        </>
                    </FullscreenPopUp>
                ))}
            </div>
        </div>
    )

}

const IframeWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    > iframe {
        width: 100%;
        height: 100vh;
        border: 0;
    }
`